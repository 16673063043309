<div class="relative w-40 rounded-lg bg-white" #rulePopup>
  <div class="flex h-10 items-center px-3">
    <mat-slide-toggle
      color="primary"
      [hideIcon]="true"
      [disableRipple]="true"
      class="large mr-3"
      [checked]="ruleEnable()"
      (change)="handleSlideChange($event)"
    ></mat-slide-toggle>
    <span class="text-tertiary-900 text-base leading-4">标尺及辅助线</span>
  </div>
  <ace-rule-popup-row-group>
    @if (hasGuides()) {
      <ace-rule-popup-row [aceRulePopupRowText]="guideLockedStr() + '辅助线'" aceRulePopupRowIcon="editorup:Lock (锁定)" (aceRulePopupRowClick)="handleLock()">
      </ace-rule-popup-row>

      <ace-rule-popup-row aceRulePopupRowText="清除辅助线" aceRulePopupRowIcon="editorup:Format (格式/清除)" (aceRulePopupRowClick)="handleClear()">
      </ace-rule-popup-row>
    }

    <ace-rule-popup-row
      aceRulePopupRowText="辅助网格"
      aceRulePopupRowIcon="editorup:Pound-sign"
      [(aceRulePopupRowIgnore)]="showPreset"
      [aceRulePopupRowArrow]="true"
      (aceRulePopupRowChange)="handlePreset($event)"
    >
    </ace-rule-popup-row>
  </ace-rule-popup-row-group>
</div>

<!--辅助线网格的预设菜单-->
<ng-template #presetTemplate>
  <div class="pl-1">
    <div class="w-40 rounded-lg bg-white p-1">
      <ace-rule-popup-row
        aceRulePopupRowIcon="editorup:Pound-sign (2列)"
        aceRulePopupRowText="2列"
        (aceRulePopupRowClick)="handleCreate(0, 2)"
        (aceRulePopupRowActiveChange)="handleDryCreate($event, 0, 2)"
      >
      </ace-rule-popup-row>

      <ace-rule-popup-row
        aceRulePopupRowIcon="editorup:Pound-sign (3列)"
        (aceRulePopupRowClick)="handleCreate(0, 3)"
        (aceRulePopupRowActiveChange)="handleDryCreate($event, 0, 3)"
        aceRulePopupRowText="3列"
      >
      </ace-rule-popup-row>

      <ace-rule-popup-row
        aceRulePopupRowIcon="editorup:Pound-sign (5列)"
        (aceRulePopupRowClick)="handleCreate(0, 5)"
        (aceRulePopupRowActiveChange)="handleDryCreate($event, 0, 5)"
        aceRulePopupRowText="5列"
      >
      </ace-rule-popup-row>

      <ace-rule-popup-row
        aceRulePopupRowIcon="editorup:Pound-sign (3X3)"
        (aceRulePopupRowClick)="handleCreate(3, 3)"
        (aceRulePopupRowActiveChange)="handleDryCreate($event, 3, 3)"
        aceRulePopupRowText="3x3 网格"
      >
      </ace-rule-popup-row>
    </div>
  </div>
</ng-template>
