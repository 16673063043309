import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core'

import { ElementContainerComponent } from '#modules/workspace/components/element-container/element-container.component'
import { PageBackgroundComponent } from '#modules/workspace/components/page-background/page-background.component'
import { PageElementTreeNode } from '#modules/workspace/models/element-node'
import { PageListNode } from '#modules/workspace/types/page'

@Component({
  selector: 'ace-page-view',
  standalone: true,
  imports: [CommonModule, ElementContainerComponent, PageBackgroundComponent],
  templateUrl: './page-view.component.html',
  styleUrl: './page-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageViewComponent implements AfterViewInit {
  page = input.required<PageListNode>()
  pageScale = input<number>(1)

  elements = computed(() => this.page().elementTreeNodes())
  pageSize = computed(() => this.page().size)
  background = computed(() => this.page().background)
  rendered = output<boolean>()

  pageRootElements = computed<Array<PageElementTreeNode>>(() => {
    return this.elements().filter(node => node.visible)
  })
  ngAfterViewInit() {
    this.rendered.emit(true)
  }
}
