import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core'

import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'

import { TextInputComponent } from '../text-input.component'

@Component({
  selector: 'ace-text-input-container',
  standalone: true,
  imports: [CommonModule, TextInputComponent],
  templateUrl: './text-input-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputContainerComponent {
  textStore = inject(TextStore)
  uiStore = inject(StageUiStore)

  page = this.uiStore.onStagePage

  parent = this.textStore.parent

  text = this.textStore.element

  position = computed(() => this.parent()?.position || { x: 0, y: 0 })

  left = computed(() => this.position().x)
  top = computed(() => this.position().y)

  size = computed(() => this.parent()?.size || this.page()?.size || { width: 0, height: 0 })

  rotation = computed(() => this.parent()?.rotation || 0)

  scale = computed(() => this.parent()?.scale || 1)
}
