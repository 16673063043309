import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'

import { IResourceData } from '@libs/payload'

@Component({
  selector: 'ace-data-record-row',
  standalone: true,
  imports: [CommonModule, MatButton, MatIcon, MatRipple],
  templateUrl: './data-record-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataRecordRowComponent {
  /**
   * 输入的数据
   */
  aceData = input.required<IResourceData>()
  aceClick = output<IResourceData>()

  /**
   * 当前组件点击事件
   */
  handleClick() {
    this.aceClick.emit(this.aceData())
  }
}
