import { CommonModule, NgOptimizedImage } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, inject, OnDestroy } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'
import { MatTooltip } from '@angular/material/tooltip'
import { ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'

import { TranslocoPipe } from '@ngneat/transloco'
import { Subject } from 'rxjs'

import { FullscreenComponent } from '@libs/ng-shared/components/fullscreen'
import { LoadingBarComponent } from '@libs/ng-shared/components/loading-bar'

import { NotificationComponent } from '#modules/home/components/notification/notification.component'
import { UserComponent } from '#modules/home/components/user/user.component'

@Component({
  selector: 'ace-home',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    LoadingBarComponent,
    MatIconButton,
    FullscreenComponent,
    MatIcon,
    MatTooltip,
    RouterLink,
    RouterLinkActive,
    UserComponent,
    NotificationComponent,
    TranslocoPipe,
    NgOptimizedImage
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnDestroy {
  unsubscribeAll = new Subject<null>()

  menus = [
    { name: '模版中心', icon: 'heroicons_outline:shopping-bag', link: '/home/template', active: false },
    { name: '项目中心', icon: 'heroicons_outline:computer-desktop', link: '/home/project', active: false },
    { name: '我的收藏', icon: 'heroicons_outline:star', link: '/home/collect', active: false },
    { name: '回收站', icon: 'heroicons_outline:archive-box-x-mark', link: '/home/recycle', active: false }
  ]

  ngOnDestroy(): void {
    this.unsubscribeAll.next(null)
    this.unsubscribeAll.complete()
  }
}
