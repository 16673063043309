import { CommonModule, DOCUMENT } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, ElementRef, inject, input, Renderer2, viewChild } from '@angular/core'

import { HexToRGBA } from 'packages/charts/src/lib/helperComponents'

import { IColor } from '@editorup/charts'

import { ICharSetting, ITextSetting } from '#modules/workspace/types/element'
import { isGradientColor, parseGradientToString } from '#shared/utils/color'

@Component({
  selector: 'ace-text-input-char',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './text-input-char.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputCharComponent implements AfterViewInit {
  el = inject(ElementRef)
  renderer = inject(Renderer2)
  document = inject(DOCUMENT)

  char = viewChild<ElementRef<HTMLElement>>('char')
  parentP: HTMLElement | null = null

  charSetting = input.required<ICharSetting>()
  textSetting = input.required<ITextSetting>()
  horizontalMode = input.required<boolean>()
  hasStroke = input.required<boolean>()
  shadowDom = input.required<boolean>()

  isGradientColor = computed(() => {
    return isGradientColor(this.charSetting().color)
  })

  constructor() {
    effect(() => {
      if (this.shadowDom()) return

      if (this.charSetting().text === '') {
        queueMicrotask(() => {
          const char = this.char()?.nativeElement as HTMLSpanElement
          // console.log(this.charSetting(), char.parentElement)
          let lost = false
          if (!char.parentElement) {
            // remove all children
            this.el.nativeElement.innerHTML = ''
            this.renderer.appendChild(this.el.nativeElement, char)
            lost = true
          } else if (!this.el.nativeElement.parentElement) {
            const parentP = this.parentP as HTMLElement
            parentP.innerHTML = ''
            this.renderer.appendChild(parentP, this.el.nativeElement)
            lost = true
          }

          if (lost) {
            char.innerHTML = '&#xFEFF;'

            const newRange = document.createRange()
            const selection = this.document.getSelection()
            selection?.removeAllRanges()
            selection?.addRange(newRange)
            newRange.setStart(char.childNodes[0] as Node, 0)
            newRange.setEnd(char.childNodes[0] as Node, 0)
          }
        })
      }
    })
  }

  // @HostBinding('class') get class() {
  //   return this.shadowDom() ? 'pointer-events-none select-none text-transparent' : this.isGradientColor() ? 'bg-clip-text' : ''
  // }

  // @HostBinding('style') get style() {
  //   if (this.shadowDom()) {
  //     return {
  //       fontSize: `${this.charSetting().fontSize}px`,
  //       fontWeight: this.charSetting().fontWeight,
  //       fontStyle: this.charSetting().fontStyle,
  //       fontFamily: this.charSetting().fontFamily,
  //       textDecoration: this.charSetting().textDecoration,
  //       '-webkit-text-stroke': this.hasStroke() ? `${this.charSetting().textStrokeWidth}px ${this.parseColor(this.charSetting().textStrokeColor)}` : 'none',
  //       textShadow: this.textSetting().textShadow
  //         ? this.parseColor(this.textSetting().textShadowColor) +
  //           ` ${this.parseShadow('x')}px ${this.parseShadow('y')}px ${this.textSetting().textShadowBlur}px`
  //         : 'unset'
  //     }
  //   } else {
  //     return {
  //       fontSize: `${this.charSetting().fontSize}px`,
  //       fontWeight: this.charSetting().fontWeight,
  //       fontStyle: this.charSetting().fontStyle,
  //       fontFamily: this.charSetting().fontFamily,
  //       textDecoration: this.charSetting().textDecoration,
  //       color: (this.editing() && !this.interact() && !this.hasStroke()) || this.isGradientColor() ? 'transparent' : this.parseColor(this.charSetting().color),
  //       backgroundImage: this.isGradientColor() ? this.parseColor(this.charSetting().color) : 'none',
  //       textShadow:
  //         !this.hasStroke() && this.textSetting().textShadow && !this.interact()
  //           ? this.parseColor(this.textSetting().textShadowColor) +
  //             ` ${this.parseShadow('x')}px ${this.parseShadow('y')}px ${this.textSetting().textShadowBlur}px`
  //           : 'unset'
  //     }
  //   }
  // }

  ngAfterViewInit(): void {
    this.parentP = this.el.nativeElement.parentElement
  }

  // parseColor = HexToRGBA
  parseColor(color: IColor) {
    if (isGradientColor(color)) {
      return parseGradientToString(color.color)
    } else {
      return HexToRGBA(color)
    }
  }

  parseShadow(prop: 'x' | 'y' | 'blur') {
    const firstFontSize = this.textSetting().paragraphs[0].chars[0].fontSize
    const fontSize = this.charSetting().fontSize

    const angle = this.textSetting().textShadowAngle // 阴影角度（以度为单位）
    const distance = ((this.textSetting().textShadowOffset / 100) * firstFontSize) / fontSize // 偏移距离

    // 根据角度计算dx和dy
    if (prop === 'x') return distance * Math.cos((angle * Math.PI) / 180)
    else if (prop === 'y') return distance * Math.sin((angle * Math.PI) / 180)
    else return ((this.textSetting().textShadowBlur / 100) * firstFontSize) / fontSize
  }
}
