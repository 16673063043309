<div class="flex gap-4">
  <ace-meta-bar-button #chartButton cdkOverlayOrigin #chartRef="cdkOverlayOrigin" [active]="active === 'chart'" icon="chart" (click)="clickChartButton()" />

  <ace-meta-bar-button [active]="active === 'text'" icon="text" (click)="addText()" />

  <ace-meta-bar-button cdkOverlayOrigin #shapeRef="cdkOverlayOrigin" [active]="active === 'graphic'" icon="graphic" (click)="clickShapeButton()" />

  <ace-meta-bar-button [active]="active === 'image'" icon="image" (click)="clickImageButton()" />

  <ace-meta-bar-button [active]="active === 'widget'" icon="widget" />
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="active === 'chart' ? chartRef : shapeRef"
  [cdkConnectedOverlayOpen]="modalOpen"
  [cdkConnectedOverlayPanelClass]="['light-shadow']"
  (overlayOutsideClick)="closeChartDialog($event)"
  [cdkConnectedOverlayPositions]="[
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetY: 12
    }
  ]"
>
  @if (active === 'chart') {
    <ace-meta-chart-list [selectChart]="addChart.bind(this)" [closeDialog]="closeChartDialog.bind(this)"></ace-meta-chart-list>
  } @else if (active === 'graphic') {
    <ace-meta-shape-list (onSelect)="addShape($event)"></ace-meta-shape-list>
  }
</ng-template>
