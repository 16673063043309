import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, inject, Renderer2, ViewChild } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { RouterOutlet } from '@angular/router'

import { TranslocoPipe } from '@ngneat/transloco'

import { AppStore } from '#core/store/app.store'

@Component({
  selector: 'ace-auth',
  styles: [':host { width: 100% }'],
  standalone: true,
  imports: [CommonModule, RouterOutlet, TranslocoPipe, MatIconButton, MatIconModule],
  templateUrl: './auth.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthComponent implements AfterViewInit {
  @ViewChild('video', { static: true }) videoElement!: ElementRef

  appStore = inject(AppStore)
  renderer = inject(Renderer2)

  ngAfterViewInit(): void {
    const video: HTMLVideoElement = this.videoElement.nativeElement
    video.muted = true
    video.autoplay = true
    video.loop = true

    this.renderer.listen(video, 'canplay', () => {
      video.play().catch(error => {
        console.error('Video playback error:', error)
      })
    })
  }

  updateTheme(): void {
    if (this.appStore.styles().mode === 'light') {
      this.appStore.switchMode('dark')
    } else {
      this.appStore.switchMode('light')
    }
  }

  updateLang(): void {
    if (this.appStore.language() === 'zh') {
      this.appStore.switchLang('en')
    } else {
      this.appStore.switchLang('zh')
    }
  }
}
