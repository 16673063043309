import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { TemplatePortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild,
  ViewContainerRef
} from '@angular/core'
import { MatIcon } from '@angular/material/icon'
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle'

import { HotToastService } from '@ngxpert/hot-toast'

import { EuRulePopupCreate } from '#modules/workspace/components/canvas/index'
import { RulePopupRowGroupComponent } from '#modules/workspace/components/canvas/rule-popup-row-group.component'
import { RulePopupRowComponent } from '#modules/workspace/components/canvas/rule-popup-row.component'
import { Guide } from '#modules/workspace/store'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'

@Component({
  selector: 'ace-rule-popup',
  standalone: true,
  imports: [CommonModule, MatSlideToggle, MatIcon, RulePopupRowComponent, RulePopupRowGroupComponent],
  templateUrl: './rule-popup.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulePopupComponent implements OnInit {
  stageUiStore = inject(StageUiStore)
  /**
   * 是否锁定辅助线
   */
  // aceRulePopupLocked = model(false)
  guideLocked = this.stageUiStore.guideLocked
  guideLockedStr = computed(() => (!this.guideLocked() ? '锁定' : '解锁'))
  /**
   * 是否显示辅助线预设菜单
   * 双向绑定
   */
  // aceRulePopupChecked = model(false)
  ruleEnable = this.stageUiStore.ruleEnable
  /**
   * 辅助线的创建事件
   */
  aceRulePopupCreate = output<EuRulePopupCreate>()

  /**
   * 清除辅助线
   */
  aceRulePopupClear = output()

  presetContainer = inject(ViewContainerRef)
  /**
   * 子菜单模版
   */
  presetTemplate = viewChild.required('presetTemplate', { read: TemplateRef })
  /**
   * 当前组件
   */
  rulePopup = viewChild.required<ElementRef<HTMLDivElement>>('rulePopup')
  overlay = inject(Overlay)
  presetTemplatePortal!: TemplatePortal<any>
  overlayRef!: OverlayRef
  /**
   * 是否显示预设
   */
  showPreset = signal(false)

  cacheGuides = signal<Guide[]>([])

  hasGuides = computed(() => this.stageUiStore.guideLines().length > 0 || this.cacheGuides().length > 0)

  hotToastService = inject(HotToastService)

  ngOnInit() {
    // 初始化辅助线预设菜单
    this.overlayRef = this.overlay.create({
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.rulePopup().nativeElement)
        // 在右侧底部显示
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'bottom'
          }
        ])
    })
    // 监听点击外部事件
    this.overlayRef.outsidePointerEvents().subscribe(() => {
      this.overlayRef.detach()
    })
    // 监听显示隐藏事件
    this.overlayRef.attachments().subscribe(() => {
      this.showPreset.set(true)
    })
    // 监听显示隐藏事件
    this.overlayRef.detachments().subscribe(() => {
      this.showPreset.set(false)
    })
    // 初始化预设模板
    this.presetTemplatePortal = new TemplatePortal(this.presetTemplate(), this.presetContainer)
  }

  /**
   * 显示辅助线预设菜单
   * @param state
   */
  handlePreset(state: boolean) {
    if (state) {
      this.overlayRef.attach(this.presetTemplatePortal)
    } else {
      this.overlayRef.detach()
    }
  }

  /**
   * 刻度尺开启状态切换
   * @param $event
   */
  handleSlideChange($event: MatSlideToggleChange) {
    // this.aceRulePopupChecked.set($event.checked)
    this.stageUiStore.setRuleEnable($event.checked)
  }

  /**
   * 创建辅助线
   * @param rowNum
   * @param columnNum
   */
  handleCreate(rowNum: number, columnNum: number) {
    // 发送创建事件
    this.aceRulePopupCreate.emit({ dry: false, row: rowNum, column: columnNum })
    // 关闭子集弹窗
    this.overlayRef.detach()
  }

  /**
   * 预创建辅助线，仅显示不会实际修改数据
   * @param $event
   * @param rowNum
   * @param columnNum
   */
  handleDryCreate($event: boolean, rowNum: number, columnNum: number) {
    // 判断当前菜单是否被hover，hover时预览辅助线 非hover时清除预览辅助线
    if ($event) {
      // 缓存当前的辅助线
      if (this.cacheGuides().length === 0 && this.stageUiStore.guideLines().length > 0) {
        this.cacheGuides.set(this.stageUiStore.guideLines())
      }
      this.aceRulePopupCreate.emit({ dry: true, column: columnNum, row: rowNum })
    } else {
      // 清除预创建的辅助线
      this.aceRulePopupCreate.emit({ dry: true, column: 0, row: 0 })
      // 还原缓存的辅助线
      if (this.cacheGuides().length > 0) {
        this.stageUiStore.setGuides(this.cacheGuides())
        this.cacheGuides.set([])
      }
    }
  }

  /**
   * 锁定辅助线
   */
  handleLock() {
    const lock = !this.guideLocked()
    this.stageUiStore.setGuideLocked(lock)
    if (lock) {
      this.hotToastService.info(`辅助线已全部锁定`)
    } else {
      this.hotToastService.info(`辅助线已全部解锁`)
    }
  }

  /**
   * 清除辅助线
   */
  handleClear() {
    this.aceRulePopupClear.emit()
  }
}
