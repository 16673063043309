<div
  #text
  class="pointer-events-auto w-max origin-top-left whitespace-break-spaces break-words"
  [style.writing-mode]="setting().direction"
  [style.scale]="scale()"
>
  <div
    #textRef
    class="editing-text"
    contenteditable="plaintext-only"
    (input)="onInput($event)"
    (focus)="onFocus($event)"
    (blur)="onBlur($event)"
    (dragstart)="onDragStart($event)"
  >
    @for (paragraph of setting().paragraphs; let i = $index; track i) {
      <ace-text-input-paragraph
        [textSetting]="setting()"
        [paraSetting]="paragraph"
        [horizontalMode]="horizontalMode()"
        [size]="size()"
      ></ace-text-input-paragraph>
    }
  </div>
</div>

<!--
  当 div contenteditable=true 时，使用方向键向上跨 p 移动光标，会跳过 p 中以 \n 结尾的行
  在同一个 p 中，或者使用 <br> 换行，或者自动换行，都不会有这个问题
-->
