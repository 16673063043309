import { CommonModule, DOCUMENT } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  OnDestroy,
  signal,
  viewChild
} from '@angular/core'

import _ from 'lodash'

import { ElementService } from '#modules/workspace/services/element.service'
import { TextService } from '#modules/workspace/services/text.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { ITextSetting } from '#modules/workspace/types/element'

import { TextInputCharComponent } from './text-input-char/text-input-char.component'
import { TextInputParagraphComponent } from './text-input-paragraph/text-input-paragraph.component'

@Component({
  selector: 'ace-text-input',
  standalone: true,
  imports: [CommonModule, TextInputCharComponent, TextInputParagraphComponent],
  templateUrl: './text-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputComponent implements OnDestroy, AfterViewInit {
  @HostBinding('class') class = 'block'

  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)
  textService = inject(TextService)
  elementService = inject(ElementService)

  textRef = viewChild.required<ElementRef<HTMLElement>>('textRef')

  id = this.textStore.id

  position = this.textStore.position
  left = computed(() => {
    // 如果组元素的子元素正在编辑，组元素的位置应该根据子元素的位置计算
    // const childInteraction = this.childrenInteraction()
    // if (!_.isEmpty(childInteraction)) {
    //   return this.boundingChildrenRect().x
    // }
    return this.position().x
  })
  top = computed(() => {
    // 如果组元素的子元素正在编辑，组元素的位置应该根据子元素的位置计算
    // const childInteraction = this.childrenInteraction()
    // if (!_.isEmpty(childInteraction)) {
    //   return this.boundingChildrenRect().y
    // }
    return this.position().y
  })

  rotation = this.textStore.rotation

  scale = this.textStore.scale

  size = this.textStore.size

  setting = this.textStore.shadowSetting

  horizontalMode = computed(() => this.setting()?.direction === 'horizontal-tb')

  range = signal<Range | null>(null)

  selectedParagraphs = this.textStore.selectedParagraphs

  selectedChars = this.textStore.selectedChars

  focused = false

  cdr = inject(ChangeDetectorRef)
  document = inject(DOCUMENT)

  constructor() {
    effect(
      () => {
        const setting = this.textStore.setting() as ITextSetting
        if (!this.focused) {
          const span = this.textRef()?.nativeElement.querySelector('p.top-p span')
          // includes('\n') will always return true if do have line break
          // if (span && span.innerHTML.includes('\n')) {
          if (span && span.childNodes.length > 1) {
            span.innerHTML = (span as HTMLElement).innerText
          }

          this.textStore.updateShadowSetting(setting)
          // this.textStore.updateShadowSetting(this.textStore.setting() as ITextSetting)
        }
      },
      { allowSignalWrites: true }
    )

    // effect(() => {
    // console.log('====================================================', this.position(), this.size(), this.rotation(), this.elementScale())
    //   console.log('====================================================', this.setting())
    // })
  }

  @HostBinding('style.transform') get transform() {
    return `translate(${this.left()}px, ${this.top()}px)  rotate(${this.rotation()}deg)`
  }

  @HostBinding('style.width.px') get width() {
    return this.size().width * this.scale()
  }
  @HostBinding('style.height.px') get height() {
    return this.size().height * this.scale()
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown($event: MouseEvent) {
    $event.stopPropagation()
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp($event: MouseEvent) {
    $event.stopPropagation()
  }

  // TODO: this is a hack, to resolve the composition insert issue on stroked text after select all by shortcut
  @HostListener('window:keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
    if ((e.ctrlKey || e.metaKey) && e.key === 'a') {
      this.selectAll()
      e.preventDefault()
    }
  }

  @HostListener('document:selectionchange', ['$event'])
  onSelectionChange() {
    const selection = this.document.getSelection()

    if (selection && !this.textRef().nativeElement.contains(selection.anchorNode)) return

    if (!selection) {
      this.range.set(null)
      this.textStore.updateRange(null)
      return
    }

    // if (!this._editing() || !selection || (selection && !this.textElementRef().nativeElement.contains(selection.anchorNode))) {
    //   this.range.set(null)
    //   this.textStore.updateRange(null)
    //   return
    // }

    // Usually selection.rangeCount is 1
    const range = selection.getRangeAt(0)
    // console.log(range)
    if (range.startContainer.nodeType !== 3) {
      // select whole text when enter editing mode, the startContainer is text node
      // console.error('range.startContainer.nodeType: ', range.startContainer.nodeType)
      // this.range.set(null)
      // this.textStore.updateRange(null)
    } else if (this.range() === range) {
      // console.log('range not change')
    } else {
      this.range.set(range)

      this.textStore.updateRange(range)
    }
  }

  ngAfterViewInit(): void {
    this.textService.resizeText('focus')

    if (this.setting().defaultText && this.setting()?.paragraphs.length === 1 && this.setting()?.paragraphs[0].chars.length === 1) {
      this.selectAll()
    } else {
      const selection = window.getSelection() as Selection
      let range = document.createRange()
      const { x, y } = this.textStore.editPosition()
      // @ts-expect-error document caretPositionFromPoint
      if (document.caretPositionFromPoint) {
        // @ts-expect-error document caretPositionFromPoint
        const { offsetNode, offset } = document.caretPositionFromPoint(x, y)
        range.setStart(offsetNode, offset)
        // range.collapse()
        range.setEnd(offsetNode, offset)
      } else if (document.caretRangeFromPoint) {
        // @ts-expect-error document caretRangeFromPoint
        if (this.document.caretRangeFromPoint(x, y)) range = document.caretRangeFromPoint(x, y)
      }
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  onFocus(e: FocusEvent) {
    this.focused = true
  }

  onBlur(e: FocusEvent) {
    // console.log('onBlur')
    this.focused = false

    const selection = this.document.getSelection()
    selection?.removeAllRanges()

    const selectedIdsSet = this.uiStore.selectedIdsSet()
    if (selectedIdsSet.size === 1) {
      // console.log('keep focus')
      // focus back on the target
      // ;(e.target as HTMLElement).focus()
    } else {
      // console.log('inactive')
      this.inactive()
    }
  }

  selectAll() {
    const selection = window.getSelection() as Selection
    const range = document.createRange()
    // no setSelectionRange function on div element
    // ;(this.textRef()?.nativeElement as HTMLInputElement)?.setSelectionRange(0, length)
    const el = this.textRef()?.nativeElement
    if (!el) return
    const charEl = el?.querySelector('p.top-p ace-text-input-char > span')?.childNodes[0] as Node
    // startContainer is div
    // range.selectNodeContents(el)
    range.setStart(charEl, 0)
    range.setEnd(charEl, charEl.textContent?.length || 9999)
    selection.removeAllRanges()
    selection.addRange(range)
  }

  inactive() {
    this.range.set(null)

    this.textStore.updateRange(null)
    this.textStore.updateEditing(false)

    // id is undefined
    // if (this.setting().paragraphs.length === 1 && this.setting().paragraphs[0].chars.length === 1 && this.setting().paragraphs[0].chars[0].text.trim() === '') {
    //   this.elementService.deleteElements(this.id() as string)
    // }
  }

  onInput(event: Event) {
    const range = this.range() as Range
    if (!range) return

    const e = event as InputEvent

    // console.log('onInput', e, (range.startContainer as Text).wholeText || '', range.startOffset, range.endOffset)

    let content
    if (range.startContainer.nodeType === 3) {
      content = (range.startContainer as Text).wholeText
    } else {
      content = range.startContainer.textContent
    }

    // bug on mac default input method, content is undefined but e.data is correct
    if (!content && e.data) content = e.data

    let setting = _.cloneDeep(this.uiStore.interacting.shadowData.setting() as ITextSetting)
    if (_.isEmpty(setting)) setting = _.cloneDeep(this.setting())

    const pIndex = this.selectedParagraphs()[0]
    const cIndex = this.selectedChars().get(pIndex)?.[0] || 0

    // remove last '&#xFEFF;'
    const text = content ? content.replace(/&#xFEFF;$/, '') : ''

    // There will be an extra newline character at the end
    setting.paragraphs[pIndex].chars[cIndex].text = text

    if (setting.defaultText) setting.defaultText = false

    if (!content && !e.data) {
      this.textStore.updateShadowSetting(setting)
    }

    if (this.setting().paragraphs[0].chars[0].textStroke) {
      const shadowSpan = this.document.querySelector('p.shadow-p span') as HTMLElement
      // shadowP.innerHTML = text
      // shadowP.innerText = text
      const textEl = shadowSpan.childNodes[0]
      textEl.textContent = text
    }

    this.uiStore.setSettingElement(this.id() as string, setting)

    this.textService.resizeText('input')

    // this.uiStore.resetInteractingElement()
  }

  getLineBreaks(element: Element) {
    const range = document.createRange()
    const textNodes = Array.from(element.childNodes) as Text[]
    const lines: number[][] = []
    let prevBottom = -1

    textNodes.forEach((node, n) => {
      lines.push([])
      for (let i = 0; i < node.length; i++) {
        range.setStart(node, i)
        range.setEnd(node, i + 1)
        const rect = range.getBoundingClientRect()

        if (rect.bottom > prevBottom) {
          prevBottom = rect.bottom
          if (i !== 0) lines[n].push(i)
        }
      }
    })
    return lines
  }

  restoreRange() {
    const oldRange = this.range() as Range
    // console.log('after selection changed', oldRange.startContainer.textContent || '', oldRange.startOffset, oldRange.endOffset)

    const startContainer = oldRange.startContainer
    const endContainer = oldRange.endContainer
    const startOffset = oldRange.startOffset
    const endOffset = oldRange.endOffset
    // let startOffset = 0
    // let endOffset = 0
    setTimeout(() => {
      // console.log('after update data', this.range()?.startContainer.textContent || '', this.range()?.startOffset, this.range()?.endOffset)

      const newRange = document.createRange()
      const selection = this.document.getSelection()
      selection?.removeAllRanges()
      selection?.addRange(newRange)
      newRange.setStart(startContainer, startOffset)
      newRange.setEnd(endContainer, endOffset)
      this.range.set(newRange)

      // console.log('after set range', this.range()?.startContainer.textContent || '', this.range()?.startOffset, this.range()?.endOffset)
    }, 0)
  }

  onDragStart(e: DragEvent) {
    e.preventDefault()
    e.stopPropagation()
  }

  ngOnDestroy(): void {
    this.inactive()
  }
}
