<div class="relative flex h-full flex-col overflow-hidden rounded-xl">
  <div #listContainer class="relative flex-1 p-2" aceScrollbar cdkScrollable>
    <div cdkDropList class="layer-list flex flex-col gap-2" (cdkDropListDropped)="drop($event)">
      @for (layer of layers(); track layer.id) {
        <div
          class="layer-item w-50 bg-tertiary-300 dark:bg-tertiary-900 relative flex h-12 cursor-pointer items-center justify-center rounded-lg text-sm leading-3 hover:shadow"
          cdkDrag
          [class.selected]="isLayerSelected(layer.id)"
          [class.not-dragging]="!dragging"
          [class.example-custom-placeholder]="dragging && isLayerSelected(layer.id)"
          (click)="clickLayer($event, $index, layer.id, layer)"
          (contextmenu)="rightClickLayer($event, $index, layer)"
          [cdkContextMenuTriggerFor]="context_menu"
          [cdkContextMenuDisabled]="contextMenuDisabled($index, layer)"
          (cdkDragStarted)="dragStarted($event, $index, layer)"
          (cdkDragMoved)="dragMoved($event)"
          (cdkDragEnded)="dragEnded($event)"
          (cdkDragDropped)="dropped($event)"
          [cdkDragDisabled]="layer.locked || !layer.visible"
        >
          <div *cdkDragPlaceholder></div>
          @if (!dragging || !isLayerSelected(layer.id)) {
            <div class="relative flex h-full flex-1 items-center justify-between gap-2 overflow-hidden rounded-lg p-2">
              <div class="icon-left w-5">
                @if (!layer.locked && layer.visible) {
                  <mat-icon class="icon-size-5 text-tertiary-700 dark:text-tertiary-50" [svgIcon]="'custom:drag-indicator'"></mat-icon>
                }
              </div>

              <div class="flex h-8 flex-1 items-center justify-center overflow-hidden">
                @if (startLoadElements) {
                  <ace-element-container
                    class="pointer-events-none select-none"
                    #elementComponent
                    [data]="layer"
                    [standalone]="true"
                    [preview]="true"
                    [previewScale]="layerPreviewScale(layer)"
                    (rendered)="onElementRendered(layer.id)"
                  ></ace-element-container>
                }
                @if (!renderedLayers.includes(layer.id)) {
                  <ngx-skeleton-loader [theme]="{ width: '128px', height: '32px', borderRadius: '8px', marginBottom: '0' }" />
                }
              </div>

              <div class="icon-right w-5">
                @if (layer.locked) {
                  <mat-icon class="icon-size-5 text-tertiary-700 dark:text-tertiary-50" [svgIcon]="'custom:page-lock'"></mat-icon>
                } @else if (layer.category === 'group') {
                  <mat-icon class="icon-size-5 text-tertiary-700 dark:text-tertiary-50" [svgIcon]="'custom:layer-group'"></mat-icon>
                }
              </div>
            </div>
          }

          @if (!layer.visible) {
            <div class="absolute left-0 top-0 flex h-full w-full cursor-auto items-center justify-center rounded-lg text-white backdrop-blur-sm">
              <mat-icon
                class="icon-size-8 text-tertiary-700 dark:text-tertiary-50 cursor-pointer"
                [svgIcon]="'custom:page-hidden'"
                (click)="showLayer(layer)"
              ></mat-icon>
            </div>
          }
        </div>
      }
    </div>

    <div
      class="layer-item w-50 bg-tertiary-50 dark:bg-tertiary-900 relative mt-2 flex h-12 cursor-pointer items-center justify-center rounded-lg text-sm leading-3 hover:shadow"
      [class.selected]="uiStore.selectedTarget() === 'background'"
      (click)="clickBackground()"
      (contextmenu)="rightClickBackground()"
      [cdkContextMenuTriggerFor]="page_context_menu"
    >
      <div class="relative flex h-full flex-1 items-center justify-between gap-2 overflow-hidden rounded-lg p-2">
        <div class="icon-left w-5"></div>

        <div class="flex h-8 flex-1 items-center justify-center" [style.background]="(uiStore.onStagePage()?.background)!.color ?? '#ffffff'">
          @if ((uiStore.onStagePage()?.background)!.image) {
            <img class="max-h-full max-w-full object-cover" crossorigin="anonymous" [src]="(uiStore.onStagePage()?.background)!.image!" draggable="false" />
          }
        </div>

        <div class="icon-right w-5">
          <mat-icon class="icon-size-5 text-tertiary-700 dark:text-tertiary-50" [svgIcon]="'custom:layer-background'"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #context_menu>
  <ace-context-menu [menuItems]="elContextMenu()"></ace-context-menu>
</ng-template>

<ng-template #page_context_menu>
  <ace-context-menu [menuItems]="pageContextMenu()"></ace-context-menu>
</ng-template>
