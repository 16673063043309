import { FocusKeyManager } from '@angular/cdk/a11y'
import { CdkContextMenuTrigger, CdkMenu, CdkMenuItem, CdkMenuModule } from '@angular/cdk/menu'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, inject, input, output, viewChild, viewChildren } from '@angular/core'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogTitle } from '@angular/material/dialog'
import { MatFormField, MatPrefix, MatSuffix } from '@angular/material/form-field'
import { MatIcon } from '@angular/material/icon'
import { MatInput } from '@angular/material/input'
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { ConfirmationDialogComponent } from '@libs/ng-shared/services/confirmation/dialog/dialog.component'
import { IUpdateNameReq } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { AppStore } from '#core/store/app.store'
import { FileUploadService } from '#modules/workspace/components/upload/upload.service'
import { UploadData, UploadImage } from '#modules/workspace/components/upload/upload.type'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'

@Component({
  selector: 'ace-upload-item',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatProgressBar,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogTitle,
    MatPrefix,
    MatInput,
    MatFormField,
    MatIconButton,
    MatSuffix,
    CdkMenuModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],
  templateUrl: './uploadItem.component.html',
  styleUrl: './uploadItem.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadItemComponent {
  type = input('image' || 'data')
  item = input<UploadData | UploadImage>({} as UploadData)
  retryUpload = output<UploadData | UploadImage>()
  clickItem = output<UploadData | UploadImage>() //右键或是点击更多的对象，需要在遮罩上挖洞的对象
  multiSelect = input(false)

  itemChecked = output<UploadData | UploadImage>()
  deleteItem = output<string>()
  updateTitleEvent = output<IUpdateNameReq>()
  openDataTable = output<UploadData | UploadImage>()

  moreObj: UploadData | UploadImage | null = null //点击更多的对象
  editing = false //是否重命名
  // newName = '' //重命名之后的名称

  contextMenuTrigger = viewChildren(CdkContextMenuTrigger)
  cdkMenu = viewChild(CdkMenu)
  keyManager = new FocusKeyManager<CdkMenuItem>([])

  titleInput = viewChild<ElementRef<HTMLInputElement>>('titleInput')

  cdr = inject(ChangeDetectorRef)
  closeMore = output<boolean>()
  dialog = inject(MatDialog)
  apiService = inject(ApiService)
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  appStore = inject(AppStore)
  workspaceService = inject(WorkspaceService)
  uploadService = inject(FileUploadService)

  //hover元素显示复选框和更多按钮
  showControls() {
    //正在预览数据则不可操作文件
    if (this.item().isEdit) {
      return
    } else {
      this.item().hoverCheckbox = true
      this.item().hoverMore = true
    }
  }

  //隐藏复选框和更多按钮
  hideControls() {
    this.item().hoverMore = this.moreObj === this.item()
    this.item().hoverCheckbox = this.item().selected || this.multiSelect()
  }

  //点击复选框
  checkboxChecked() {
    // e.preventDefault()
    this.item().selected = !this.item().selected
    this.item().isEdit = false
    this.itemChecked.emit(this.item())
  }

  //插入图片
  insertImage() {
    if (this.multiSelect()) {
      this.checkboxChecked()
    } else {
      if (this.item().status === 'done') {
        const { id: imageId } = this.item() as UploadImage
        // 图片可能是刚刚上传并在前端进行缓存的图片，保险起见获取一次图片详情并从snapshot中进行截取
        this.apiService.getImageDetail(imageId as string).subscribe(image => {
          this.workspaceService.addImage(image)
        })
      }
    }
  }
  //点击更多中的删除
  openDelete() {
    this.moreObj = null
    // const item = this.item as UploadData | UploadImage
    if (this.item()) {
      this.item().hoverMore = false //更多按钮消失
      this.deleteItem.emit(this.item().id as string)
    }
  }

  download() {
    this.moreObj = null
    let link = ''
    switch (this.type()) {
      case 'image':
        this.apiService.getImageDetail(this.item().id as string).subscribe({
          next: data => {
            console.log('downloadData', data)
            link = data.link as string
            this.triggerDownload(this.item().preview ?? link, this.item().name)
          },
          error: () => {
            // console.log('err', err)
            this.errDialog()
          }
        })
        break
      case 'data':
        this.apiService.getDataDetail(this.item().id as string).subscribe({
          next: data => {
            const raw = JSON.parse(data.raw as string)
            const rawNoNull = raw.map((row: string[]) => row.filter(Boolean))
            const newData = rawNoNull.map((row: string[]) => row.map(item => `"${item}"`).join(',')).join('\n')
            const blob = new Blob(['\ufeff' + newData], { type: 'text/csv;charset=utf-8;' })
            link = window.URL.createObjectURL(blob)
            this.triggerDownload(link, this.item().name)
            window.URL.revokeObjectURL(link)
          },
          error: () => {
            // console.log('err', err)
            this.errDialog()
          }
        })
        break
    }
    this.closeMoreMenu()
  }

  errDialog() {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: '下载失败'
      }
    })
  }

  // 下载方式
  triggerDownload(url: string, fileName: string) {
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  editTitle() {
    this.titleInput()?.nativeElement.focus()
  }

  focusTitle() {
    // console.log('focusTitle')
    this.editing = true
    setTimeout(() => {
      this.titleInput()?.nativeElement.setSelectionRange(0, this.item().name.length)
    })
  }

  // inputTitle(e: Event) {
  //   // this.item().name = (e.target as HTMLInputElement).value
  // }

  updateTitle(e: Event) {
    e.stopPropagation()
    const value = (e.target as HTMLInputElement).value
    this.editing = false
    const update: IUpdateNameReq = { id: this.item().id as string, name: value }
    if (value !== this.item().name) {
      this.updateTitleEvent.emit(update)
    }
  }

  enterTitle(e: KeyboardEvent) {
    // console.log('enterTitle')
    e.stopPropagation()
    if (e.key === 'Enter' || e.key === 'Escape') {
      e.preventDefault()
      this.keyManager.setNextItemActive()
    }
  }

  leftClick(e: MouseEvent) {
    e.preventDefault()
    this.item().hoverMore = true
    this.moreObj = this.item()
    this.clickItem.emit(this.item())

    if (this.cdkMenu()) {
      this.keyManager = new FocusKeyManager<CdkMenuItem>((this.cdkMenu() as CdkMenu).items)
    }
  }

  rightClick(e: MouseEvent) {
    e.preventDefault()
    this.moreObj = this.item()
    this.clickItem.emit(this.item())
    if (this.cdkMenu()) {
      this.keyManager = new FocusKeyManager<CdkMenuItem>((this.cdkMenu() as CdkMenu).items)
    }
  }

  closeMoreMenu() {
    this.contextMenuTrigger().forEach(c => c.close())
    this.item().hoverMore = false
    this.moreObj = null
    this.closeMore.emit(true)
  }

  openTable(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    if (this.item().status !== 'done' || this.item().selected || this.item().isActive || this.multiSelect()) {
      return
    }
    // 打开数据面板的同时，将checkbox和more隐藏
    this.item().isActive = true
    this.changeItemMode(true, this.item())
    this.openDataTable.emit(this.item())
    // console.log('openTable', this.item())
  }

  changeItemMode(mode: boolean, item: UploadData | UploadImage) {
    item.isEdit = mode
    item.hoverCheckbox = !mode
    item.hoverMore = !mode
  }

  errorRetryUpload(item: UploadData | UploadImage) {
    const componentId = this.uploadService.generateQueueId()
    this.uploadService.retryUpload(item, componentId)
  }
}
