import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  Injector,
  model,
  OnDestroy,
  OnInit,
  signal,
  viewChild
} from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { MatButton } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog'
import { MatIcon } from '@angular/material/icon'
import { MatProgressBar } from '@angular/material/progress-bar'
import { MatTab, MatTabGroup, MatTabLabel } from '@angular/material/tabs'

import _ from 'lodash'
import { fromEvent, merge, Subject, Subscription } from 'rxjs'
import { debounceTime, filter, take, takeUntil, takeWhile } from 'rxjs/operators'

import { AlertType } from '@libs/ng-shared/components/alert'
import { AlertComponent } from '@libs/ng-shared/components/alert/alert.component'
import { ScrollListComponent } from '@libs/ng-shared/components/scroll-list/scroll-list.component'
import { SearchbarComponent } from '@libs/ng-shared/components/searchbar/searchbar.component'
// import { TimeAgoPipe } from '#shared/pipes'
import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'
import { ConfirmationDialogComponent } from '@libs/ng-shared/services/confirmation/dialog/dialog.component'
import { IPaginatedListRes, IUpdateNameReq } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { UploadData, UploadImage } from '#modules/workspace/components/upload/upload.type'
import { UploadItemComponent } from '#modules/workspace/components/upload/uploadItem/uploadItem.component'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { DataGridComponent } from '#shared/components'

import { SidebarComponent } from '../sidebar'
import { DataViewComponent } from './data-view/data-view.component'
import { EmptyListComponent } from './emptyList/emptyList.component'
import { SearchNoResultComponent } from './searchNoResult/searchNoResult.component'
import { FileUploadService } from './upload.service'

@Component({
  selector: 'ace-upload',
  standalone: true,
  templateUrl: './upload.component.html',
  styleUrl: './upload.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatTabGroup,
    MatTab,
    MatIcon,
    MatProgressBar,
    MatDialogContent,
    MatDialogActions,
    MatButton,
    MatDialogTitle,
    MatDialogClose,
    MatTabLabel,
    UploadItemComponent,
    FormsModule,
    EmptyListComponent,
    SearchNoResultComponent,
    ConfirmationDialogComponent,
    SearchbarComponent,
    OverlayModule,
    ScrollbarDirective,
    DataGridComponent,
    MatCheckboxModule,
    AlertComponent,
    ScrollListComponent
  ]
})
export class UploadComponent implements OnInit, AfterViewInit, OnDestroy {
  // 当前选中面板
  tab = signal(0)
  type = computed(() => (this.tab() === 0 ? 'data' : 'image'))
  currentList = computed(() => (this.tab() === 0 ? this.uploadService.dataList() : this.uploadService.imageList()))

  dataContainer = viewChild.required<ScrollListComponent>('dataContainer')
  imgContainer = viewChild.required<ScrollListComponent>('imgContainer')
  dataSubscription = new Subscription()
  imgSubscription = new Subscription()

  moreMask = signal(false)

  overlayRef!: OverlayRef //监听全屏拖拽的overlay
  isDragOver = signal(false)

  selectedObj = signal<UploadData[] | UploadImage[]>([]) //选中列表
  multiSelect = computed(() => this.selectedObj().length > 0)

  imgSearchValue = model('') //图片搜索关键字
  dataSearchValue = model('') //数据搜索关键字
  imgSearchValue$ = toObservable(this.imgSearchValue)
  dataSearchValue$ = toObservable(this.dataSearchValue)

  isAllChecked = signal(false)

  table: DataViewComponent | undefined = undefined

  showAlert = signal(false)
  alert: { type: AlertType; message: string } = {
    type: 'success',
    message: ''
  }

  uploadService = inject(FileUploadService)

  private apiService = inject(ApiService)

  //拖拽文件
  private dragEventsSubscription: Subscription = new Subscription()

  //数据表格
  private hostRef = inject(ElementRef)
  private _dataViewOverlayRef!: OverlayRef
  private _dataViewPortal: ComponentPortal<DataViewComponent> | undefined
  private cdr = inject(ChangeDetectorRef)
  private dialog = inject(MatDialog)
  private overlay = inject(Overlay)
  private sidebar = inject(SidebarComponent)
  private injector = inject(Injector)
  private workspaceService = inject(WorkspaceService)
  private destroy$ = new Subject<void>()

  ngOnInit(): void {
    this.uploadService.activeTab$.subscribe(tab => {
      this.tab.set(tab)
    })
    // 创建overlay，监听拖拽事件
    this.listenDragEvent()
    //初始化数据面板的overlay
    this._dataViewOverlayRef = this.overlay.create({
      hasBackdrop: false,
      panelClass: ['overflow-hidden', 'w-full', 'h-full']
    })
  }

  ngAfterViewInit(): void {
    this.dataSubscription.add(
      this.dataSearchValue$.pipe(debounceTime(100)).subscribe(() => {
        this.uploadService.dataList.set([])
        this.dataContainer().reset()
        this.closeMultiDelete() //搜索后关闭多选
      })
    )
    this.imgSubscription.add(
      this.imgSearchValue$.pipe(debounceTime(100)).subscribe(() => {
        this.uploadService.imageList.set([])
        this.imgContainer().reset()
        this.closeMultiDelete() //搜索后关闭多选
      })
    )
  }

  ngOnDestroy() {
    this.overlayRef.dispose()
    this.destroy$.next()
    this.destroy$.complete()
    this.dragEventsSubscription.unsubscribe()
    //关闭数据面板
    if (document.getElementById('uploadDataPanel')) {
      this.closeDataPanel()
    }
    this.dataSubscription.unsubscribe()
    this.imgSubscription.unsubscribe()
  }

  uploadListLoader = (type: 'image' | 'data') => {
    const list = type === 'image' ? this.uploadService.imageList : this.uploadService.dataList
    const keyword = type === 'image' ? this.imgSearchValue() : this.dataSearchValue()
    //最后一个状态不为error的图片id
    const id = _.findLast(list(), item => item.status !== 'error')?.id as string
    return type === 'image' ? this.apiService.getImages({ keyword: keyword, id: id }) : this.apiService.getUploadDataList({ keyword: keyword, id: id })
  }

  uploadDataListLoader = () => {
    return this.uploadListLoader('data')
  }

  uploadImageListLoader = () => {
    return this.uploadListLoader('image')
  }

  handleListChange<T extends UploadData | UploadImage>($event: IPaginatedListRes<unknown>, type: string) {
    const list = type === 'image' ? this.uploadService.imageList : this.uploadService.dataList
    list.update(pre => {
      return [
        ...pre,
        ...($event.list as T[]).map(item => ({
          ...item,
          hoverCheckbox: this.multiSelect(),
          status: 'done',
          timeAgo: this.formatTimeAgo(new Date(item.createdAt as Date).getTime()),
          isEdit: false
        }))
      ]
    })
    // 更新全选状态
    this.isAllChecked.set(this.selectedObj().length === list().length)
  }

  handleImageListChange($event: IPaginatedListRes<unknown>) {
    this.handleListChange($event, 'image')
  }

  handleDataListChange($event: IPaginatedListRes<unknown>) {
    this.handleListChange($event, 'data')
  }

  //打开删除弹窗
  openDeleteDialog(item: UploadData | UploadData[] | UploadImage | UploadImage[], length: number) {
    const message = this.type() === 'image' ? '张图片' : '个文件'
    this.dialog
      .open(ConfirmationDialogComponent, {
        data: {
          width: '242px',
          height: '76px',
          title: `将${length}${message}删除？`,
          actions: {
            confirm: {
              show: true,
              label: '确认'
            },
            cancel: {
              show: true,
              label: '取消'
            }
          }
        }
      })
      .beforeClosed()
      .subscribe(e => {
        this.moreMask.set(false)
        if (e === 'confirmed') {
          //上传失败的没有id,需要用fid删除
          const ids: string[] = []
          const fids: string[] = []
          const itemList = Array.isArray(item) ? item : [item]
          itemList.forEach(i => {
            if (i.id) {
              ids.push(i.id as string)
            } else {
              fids.push(i.fid as string)
            }
          })
          // 调用 delete 方法，分别处理有 id 和没有 id 的情况
          if (ids.length > 0) {
            this.delete(ids)
          }
          if (fids.length > 0) {
            const list = this.type() === 'image' ? this.uploadService.imageList : this.uploadService.dataList
            list.update(prevList => prevList.filter(i => !fids.includes(i.fid as string)))
          }
          this.closeMultiDelete()
        }
      })
  }

  //关闭底部批量删除
  closeMultiDelete() {
    this.selectedObj.set([])
    this.uploadService.imageList.update(prevList => prevList.map(i => ({ ...i, selected: false, hoverCheckbox: false })))
    this.uploadService.dataList.update(prevList => prevList.map(i => ({ ...i, selected: false, hoverCheckbox: false })))
  }

  //点击图片/数据切换面板,无需重新更新列表
  toggleTab(e: number) {
    this.tab.set(e)
    this.closeMultiDelete() //关闭底部批量删除
    //关闭数据面板
    if (document.getElementById('uploadDataPanel')) {
      this.closeDataPanel()
    }
  }

  toggleAllSelection() {
    this.isAllChecked.set(!this.isAllChecked())
    // this.isIndeterminate.set(false)
    this.selectedObj.set([]) //先清空，再添加
    const list = this.type() === 'image' ? this.uploadService.imageList : this.uploadService.dataList
    list.update(prevList => {
      if (this.isAllChecked()) {
        // 全选，将所有元素添加到 selectedObj
        this.selectedObj.set(prevList)
      } else {
        // 取消全选，清空 selectedObj 并关闭弹窗
        this.closeMultiDelete()
      }
      // 更新 list 中每个元素的 selected 属性
      return prevList.map(i => ({ ...i, selected: this.isAllChecked(), hoverCheckbox: this.isAllChecked() }))
    })
  }

  clickData(item: UploadData) {
    this.uploadService.dataList.update(prevList =>
      prevList.map(i => ({
        ...i,
        isActive: i.id === item.id ? true : i.isActive, // 如果 i===item，则 isActive 为 true，否则保持不变
        isEdit: true // 所有元素的 isEdit 都为 true
      }))
    )
    if (item.id !== this.uploadService.file().id) {
      this.uploadService.dataList.update(prevList => prevList.map(i => (i.id === this.uploadService.file().id ? { ...i, isActive: false } : i)))
      const originFile = this.uploadService.file().id as string //避免在保存上份数据时，service里的文件id已换成了新的
      if (!document.getElementById('uploadDataPanel')) {
        this.openDataTable()
      } else {
        //切换显示的数据时保存上份数据
        this.updateFileRaw(originFile)
      }
      this.uploadService.setFile(item)
    }
    this.cdr.detectChanges()
  }

  updateName(update: IUpdateNameReq) {
    const list = this.type() === 'image' ? this.uploadService.imageList : this.uploadService.dataList
    if (update.id) {
      const updateMethod = this.type() === 'image' ? this.apiService.updateImageName(update) : this.apiService.updateDataName(update)
      updateMethod.subscribe({
        next: () => {
          // 使用 update 方法更新 list
          list.update(prevList => prevList.map(i => (i.id === update.id ? { ...i, name: update.name } : i)))
          this.openAlert('重命名成功')
        },
        error: () => {
          this.openAlert('重命名失败', 'error')
        }
      })
    }
  }

  openAlert(message: string, type?: AlertType) {
    this.alert.message = message
    if (type) {
      this.alert.type = type
    }
    this.showAlert.set(true)
    setTimeout(() => {
      this.showAlert.set(false)
    }, 2000)
  }

  //单个文件点击checkbox
  itemChecked(item: UploadData | UploadImage) {
    const list = this.type() === 'image' ? this.uploadService.imageList : this.uploadService.dataList

    if (item.selected) {
      this.selectedObj.update(prevList => {
        return [...prevList, item]
      })
      list.update(prevList =>
        prevList.map(i =>
          i.id === item.id
            ? { ...i, isEdit: !i.selected, hoverCheckbox: true }
            : {
                ...i,
                hoverCheckbox: true
              }
        )
      )
    } else {
      this.selectedObj.update(prevList => {
        const newList = prevList.filter(i => i.id !== item.id)
        if (newList.length === 0) {
          this.closeMultiDelete()
          list.update(prev =>
            prev.map(i => ({
              ...i,
              hoverCheckbox: false,
              isEdit: false,
              selected: false
            }))
          )
        }
        return newList
      })
    }
    this.isAllChecked.set(this.selectedObj().length === list().length)
  }

  //选择本地文件
  selectFile(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    const selectedFiles = inputElement ? inputElement.files : []
    if (selectedFiles && selectedFiles.length > 0) {
      const files = Array.from(selectedFiles)
      inputElement.value = '' //清空浏览器缓存，避免两次上传同一个文件会失败
      const componentId = this.uploadService.generateQueueId()
      this.uploadService.addFilesToQueue(files, componentId)
    }
  }

  closeMore() {
    this.moreMask.set(false)
    //恢复拖拽事件监听
    this.listenDragEvent()
  }

  //右键点击元素或是点击更多按钮，需要出现蒙版和高亮当前元素
  clickMoreItem(item: UploadData | UploadImage) {
    //正在预览数据时，点击数据不可出现蒙版
    if (item.isActive) {
      this.moreMask.set(false)
      return
    }
    this.moreMask.set(true)
    setTimeout(() => {
      // 确保蒙版渲染完成后再获取元素
      const id = item.id ?? item.fid
      const ele = document.getElementById(id as string) as HTMLElement
      const { top, left, width, height } = ele.getBoundingClientRect()
      const eleMask = document.getElementById('moreMask') as HTMLElement
      const maskRect = eleMask.getBoundingClientRect()
      const scrollContainer = this.type() === 'image' ? document.getElementById('imageList') : document.getElementById('dataList') // 滚动容器的 ID
      const visibleDimensions = this.getVisibleDimensions(ele, scrollContainer as HTMLElement) // 获取可视范围的尺寸

      const containerRect = (scrollContainer as HTMLElement).getBoundingClientRect()
      const isTop = top < containerRect.top
      const offsetY = height > visibleDimensions.visibleHeight && isTop ? height - visibleDimensions.visibleHeight : 0

      const highlightEle = document.getElementById('highlightEle') as HTMLElement
      if (highlightEle) {
        highlightEle.style.top = `${top - maskRect.top + offsetY}px`
        highlightEle.style.left = `${left - maskRect.left}px`
        highlightEle.style.width = `${width}px`
        highlightEle.style.height = `${visibleDimensions.visibleHeight}px`
      }
    })
    //取消拖动上传功能，避免拖动上传后列表数量变化，导致蒙版的位置不准确
    this.dragEventsSubscription.unsubscribe()
  }

  private getVisibleDimensions(element: HTMLElement, scrollContainer: HTMLElement) {
    const elementRect = element.getBoundingClientRect()
    const containerRect = scrollContainer.getBoundingClientRect()

    const visibleWidth = Math.max(0, Math.min(elementRect.right, containerRect.right) - Math.max(elementRect.left, containerRect.left))

    const visibleHeight = Math.max(0, Math.min(elementRect.bottom, containerRect.bottom) - Math.max(elementRect.top, containerRect.top))

    return { visibleWidth, visibleHeight }
  }

  // 创建overlay，监听拖拽事件
  private listenDragEvent() {
    this.overlayRef = this.overlay.create({
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
      height: '100%',
      width: '100%'
    })
    // 监听拖拽事件
    const dragEnter$ = fromEvent(document, 'dragenter')
    const dragOver$ = fromEvent(document, 'dragover')
    const dragLeave$ = fromEvent(document, 'dragleave')
    const drop$ = fromEvent(document, 'drop')

    this.dragEventsSubscription = merge(dragEnter$, dragOver$, dragLeave$, drop$)
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: Event) => {
        const dragEvent = event as DragEvent
        switch (dragEvent.type) {
          case 'dragenter':
            this.onDragEnter(dragEvent)
            break
          case 'dragover':
            this.onDragOver(dragEvent)
            break
          case 'dragleave':
            this.onDragLeave(dragEvent)
            break
          case 'drop':
            this.onDrop(dragEvent)
            break
        }
      })
  }

  // 上传时间转换
  private formatTimeAgo(timestamp: number): string {
    const currentTime = new Date().getTime()
    const timeDifference = currentTime - timestamp

    const minutes = Math.floor(timeDifference / (1000 * 60))
    const hours = Math.floor(timeDifference / (1000 * 60 * 60))
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30))
    const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365))

    if (years > 0) {
      return years + (years === 1 ? '年前' : '年前')
    } else if (months > 0) {
      return months + (months === 1 ? '个月前' : '个月前')
    } else if (days > 0) {
      return days + (days === 1 ? '天前' : '天前')
    } else if (hours > 0) {
      return hours + (hours === 1 ? '个小时前' : '个小时前')
    } else if (minutes > 0) {
      return minutes + (minutes === 1 ? '分钟前' : '分钟前')
    } else {
      return '刚刚'
    }
  }

  private onDragEnter(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragOver.set(true)
  }

  //拖动过程中
  private onDragOver(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy'
    }
  }

  // 拖动离开
  private onDragLeave(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    if (event.relatedTarget !== null && (event?.currentTarget as any)?.contains(event.relatedTarget) === true) {
      return
    }
    this.isDragOver.set(false)
  }

  // 拖动结束
  private async onDrop(event: DragEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.isDragOver.set(false)
    if (event.dataTransfer) {
      const files = Array.from(event.dataTransfer.files)
      const queueId = this.uploadService.generateQueueId()
      this.uploadService.addFilesToQueue(files, queueId)
      this.uploadService.uploadSuccess$
        .pipe(
          filter(data => data.componentId === queueId),
          filter(data => data.type === 'image'),
          takeWhile(() => !this.uploadService.getUploadQueue(queueId).uploadComplete())
        )
        .subscribe((data: { fileId: string; componentId: string; type: string }) => {
          if (data.fileId) {
            this.apiService.getImageDetail(data.fileId).subscribe(image => {
              this.workspaceService.addImage(image)
            })
          }
        })
    }
  }
  //删除文件
  private delete(item: string | string[]) {
    const delItem = Array.isArray(item) ? item : [item]
    const list = this.type() === 'image' ? this.uploadService.imageList : this.uploadService.dataList
    const del = this.type() === 'image' ? this.apiService.deleteImages(delItem) : this.apiService.deleteDatas(delItem)
    del.subscribe({
      next: () => {
        list.update(prevList => prevList.filter(i => !delItem.includes(i.id as string)))
        this.openAlert('删除成功')
      },
      error: () => {
        this.openAlert('删除失败', 'error')
      }
    })
  }

  private openDataTable() {
    if (document.getElementById('uploadDataPanel')) {
      // 如果 portal 已经存在，直接替换数据就可以
      this.table = this.createDataPanel(this._dataViewPortal as ComponentPortal<DataViewComponent>).instance
    } else {
      // 异步加载组件
      import('./data-view/data-view.component').then(({ DataViewComponent: component }) => {
        this._dataViewPortal = new ComponentPortal(component, null, this.injector)
        // 组件加载完成后，创建面板
        this.table = this.createDataPanel(this._dataViewPortal).instance
      })
    }
  }

  /**
   * 创建数据面板
   * @param portal
   * @private
   */
  private createDataPanel(portal: ComponentPortal<DataViewComponent>) {
    // 获取当前激活的元素的位置信息
    const { left, top, width, height } = (document.activeElement as HTMLElement).getBoundingClientRect()

    // 获取overlay的panel
    const panelEl = this._dataViewOverlayRef.hostElement
    // 当前组件的宿主元素
    const hostEl = this.hostRef.nativeElement

    const { width: hostWidth, left: hostLeft, top: hostTop } = hostEl.getBoundingClientRect()
    const panelLeft = hostWidth + hostLeft + 8
    // 最终样式
    const endStyle = {
      width: `calc(100vw - ${panelLeft}px - 4px)`,
      height: `calc(100vh - ${hostTop + 4}px`,
      top: `${hostTop}px`,
      left: `${panelLeft}px`,
      right: `4px`,
      opacity: '1',
      filter: 'none'
    }
    // 初始样式
    const startStyle = {
      position: 'fixed',
      width: '1px',
      height: '1px',
      left: `${left + width / 2}px`,
      top: `${top + height / 2}px`,
      opacity: 0,
      filter: 'blur(10px)',
      transition: 'all 200ms ease-out'
    }

    const ref = this._dataViewOverlayRef.attach(portal)

    ref.changeDetectorRef.detectChanges() // 手动触发变更检测

    // 初始化 后续会通过动画过渡到endStyle
    Object.assign(panelEl.style, startStyle)

    this.sidebar.showMask.set(true)

    // 监听数据面板关闭事件
    ref.instance.aceDataViewClose.subscribe(() => {
      fromEvent(panelEl, 'transitionend')
        .pipe(take(1))
        .subscribe(() => {
          // 关闭组件
          this.closeDataPanel()
        })

      // 触发消失动画
      Object.assign(panelEl.style, startStyle)
    })

    // 手动触发变更检测 OnPush会导致组件不会立即渲染
    ref.changeDetectorRef.detectChanges()

    requestAnimationFrame(() => {
      // 触发显示动画
      Object.assign(panelEl.style, endStyle)
    })
    return ref
  }

  private closeDataPanel() {
    const newData = this.getHandsonTableData()
    if (document.getElementById('uploadDataPanel')) {
      //在更新数据库之后再修改状态会不生效
      this.uploadService.dataList.update(prevList => prevList.map(i => ({ ...i, isActive: false, isEdit: false, selected: false })))
      // 更新数据
      if (this.uploadService.file().raw !== newData) {
        // console.log('更新数据', newData)
        this.updateFileRaw(this.uploadService.file().id as string).finally(() => {
          this.uploadService.setFile({} as UploadData) // 重置预览的数据
        })
      } else {
        this.uploadService.setFile({} as UploadData) // 重置预览的数据
      }
      this._dataViewOverlayRef.detach()
      this.sidebar.showMask.set(false)
      this.cdr.detectChanges()
    }
  }

  //获取handsonTable的数据
  private getHandsonTableData(): string {
    let data = []
    if (this.table) {
      data = this.table.getData()
    }
    const newData = JSON.stringify(data)
    return newData
  }

  //更新文件的raw数据
  private async updateFileRaw(id: string) {
    const newData = this.getHandsonTableData()
    this.apiService.updateDataContent({ id: id, raw: newData }).subscribe({
      next: () => {
        this.uploadService.dataList.update(prevList => prevList.map(i => (i.id === id ? { ...i, raw: newData } : i)))
      },
      error: () => {
        this.openAlert('数据保存失败', 'error')
      }
    })
  }
}
