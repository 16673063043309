<div class="flex h-full w-full flex-col overflow-hidden bg-white p-3">
  <!--  header-->
  <div class="flex w-full flex-shrink-0 items-center justify-between overflow-hidden bg-white p-4 pt-0">
    @if (aceData(); as data) {
      <!--    left-->
      <div class="mr-4 flex w-0 flex-grow flex-col items-start overflow-hidden">
        <div class="relative mb-2 flex w-full items-center pl-7">
          <button mat-icon-button (click)="aceDataVisualBack.emit()" class="absolute left-0 top-1/2 mr-2 -translate-x-1/3 -translate-y-1/2">
            <mat-icon svgIcon="editorup:Left (左)" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>
          </button>
          <span class="text-tertiary-900 overflow-hidden text-ellipsis text-nowrap text-lg font-normal leading-5">{{ data.title }}</span>
        </div>
        <div class="flex w-full items-center overflow-hidden pl-7 text-sm text-gray-200">
          <span class="text-tertiary-900 overflow-hidden text-ellipsis text-nowrap text-base font-light">{{ data.description }}</span>
          <div class="bg-tertiary-100 mx-4 h-3.5 w-[1px] flex-shrink-0"></div>
          <span class="text-tertiary-600 flex-shrink-0 text-sm font-light">上架时间：{{ data.publishTime | date: 'YYYY/MM/dd' }}</span>
        </div>
        <!--        right-->
      </div>
    }
    <div class="flex flex-shrink-0 items-center justify-end">
      <button
        mat-button
        class="bg-primary disabled:bg-tertiary-700 mr-4 h-8 min-h-8 flex-shrink-0 rounded-lg"
        [disabled]="!generateActive()"
        (click)="handleGenerateChart()"
      >
        <div class="flex items-center">
          <mat-icon svgIcon="editorup:ai" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
          <span class="text-base text-white">生成图表</span>
        </div>
      </button>
      <!--      <button mat-button class="mr-10 h-8 min-h-8 rounded-lg">-->
      <!--        <div class="flex items-center text-gray-300">-->
      <!--          <mat-icon svgIcon="editorup:Table-file (表格S)" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>-->
      <!--          <span class="text-base text-white">插入画布</span>-->
      <!--        </div>-->
      <!--      </button>-->
      <!--      <button mat-icon-button class="flex-shrink-0" (click)="handleDownload()">-->
      <!--        <mat-icon svgIcon="editorup:Download (下载s)" class="icon-size-5"></mat-icon>-->
      <!--      </button>-->
      <ace-menu text="下载数据" icon="editorup:download" [options]="downloadOptions"></ace-menu>
    </div>
  </div>

  <!--  table-->
  <div class="mb-5 mt-0 flex h-0 w-full flex-grow items-center overflow-hidden border-b border-t border-solid border-b-gray-50 border-t-gray-50 pb-5 pt-2">
    <div class="h-full flex-grow overflow-hidden rounded-lg bg-white">
      <ace-data-grid [aceData]="tableData()" (aceSelection)="handleSelection($event)"></ace-data-grid>
    </div>
  </div>

  <!--  similar data-->
  <div class="h-51 w-full flex-shrink-0">
    <!--    header-->
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <mat-icon svgIcon="editorup:listpoint (节点)" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
        <span class="text-tertiary-900 text-lg font-normal">更多相似数据</span>
      </div>
      <button mat-button class="group">
        <div class="flex items-center" (click)="getSimilarData()">
          <mat-icon svgIcon="editorup:Refresh-one (刷新)" class="icon-size-5 mr-2 transition-all duration-1000 group-active:rotate-[360deg]"></mat-icon>
          <span class="text-tertiary-900 text-base font-light">换一换</span>
        </div>
      </button>
    </div>
    <div>
      @for (data of similarDataList(); track data.id) {
        <ace-data-record-row [aceData]="data" (aceClick)="handleDataClick(data)"></ace-data-record-row>
      }
    </div>
  </div>
</div>
