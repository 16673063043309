<div class="relative h-full w-full select-none" [style.cursor]="cursor()" (contextmenu)="$event.preventDefault()">
  <div
    (wheel)="handleWheel($event)"
    class="custom-scrollbar relative h-full w-full scale-100 overflow-scroll"
    [class]="{ 'pointer-events-none': isDragging() }"
    #scrollContainerRef
  >
    <!--guide-->
    @if (outerGuide(); as guide) {
      <!--    层级需要高于rule-->
      <div class="bg-primary absolute z-30" [class]="guide.type === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]'" [style]="getOuterGuideStyle(guide)">
        @if (showOutLabel()) {
          <!--        旋转文字，让文字从下往上读-->
          <div
            class="bg-primary absolute left-0 top-0 rounded px-1 py-0.5 leading-3"
            [class]="guide.type === 'horizontal' ? 'top-1 origin-top-right -translate-x-full -rotate-90' : 'left-1'"
          >
            <span class="text-xs text-white">{{ outerLabel() }}</span>
          </div>
        }
      </div>
    }

    <div
      class="absolute min-h-full min-w-full"
      [class]="ruleEnable() ? 'grid-areas-[corner_horizontal,vertical_canvas] grid grid-cols-[24px_1fr] grid-rows-[24px_1fr]' : 'flex'"
    >
      <!--      此处放置一个相同大小的空div 目的是为了在wheel滚轮事件中获取此div的layerX和layerY-->
      <div class="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-40" #scaleContainerRef></div>

      @if (ruleEnable()) {
        <!--  corner-->
        <div
          class="grid-in-[corner] border-tertiary-400 dark:border-tertiary-600 bg-tertiary-100 sticky left-0 top-0 z-20 border-b border-r dark:bg-neutral-950"
        ></div>

        <!--  top-rule-->
        <div
          class="border-tertiary-400 dark:border-tertiary-600 grid-in-[horizontal] bg-tertiary-100 pointer-events-auto sticky top-0 z-10 flex overflow-hidden border-b border-solid dark:bg-neutral-950"
        >
          <button class="h-full w-full" (mousedown)="handleCreateGuide(GuideType.horizontal)">
            <span class="relative flex h-full w-full flex-1 items-start" [style.transform]="topRuleTransform()">
              <!--                            选中元素的阴影-->
              @if (currentRealPosition(); as position) {
                <div
                  class="bg-primary-600 absolute h-full bg-opacity-10"
                  [style.left]="position.startX + 'px'"
                  [style.width]="position.endX - position.startX + 'px'"
                ></div>
              }
              @for (horizontal of horizontalScales(); track horizontal.label) {
                <span class="relative flex h-full flex-shrink-0 items-end" [style.width]="unitRuleWidth()">
                  <span class="text-tertiary-400 dark:text-tertiary-600 absolute left-1 top-0.5 -translate-y-0.5 text-xs font-bold">{{
                    horizontal.label
                  }}</span>
                  <span class="border-tertiary-400 dark:border-tertiary-600 flex h-full w-full flex-shrink-0 items-end justify-evenly border-l">
                    @for (child of horizontal.children; track child.label) {
                      <span class="border-tertiary-400 dark:border-tertiary-600 border-l" [class]="child.isMiddle ? 'h-1.5' : 'h-1'"> </span>
                    }
                  </span>
                </span>
              }
            </span>
          </button>
        </div>

        <!--  vertical-rule-->
        <div
          class="grid-in-[vertical] bg-tertiary-100 border-tertiary-400 dark:border-tertiary-600 sticky left-0 z-10 overflow-hidden border-r border-solid dark:bg-neutral-950"
        >
          <button class="relative left-0 top-0 flex h-full w-full" (mousedown)="handleCreateGuide(GuideType.vertical)">
            <span class="relative flex h-full w-full flex-1 flex-col items-end" [style.transform]="leftRuleTransform()">
              @if (currentRealPosition(); as position) {
                <div
                  class="bg-primary-600 absolute w-full bg-opacity-10"
                  [style.top]="position.startY + 'px'"
                  [style.height]="position.endY - position.startY + 'px'"
                ></div>
              }
              @for (vertical of verticalScales(); track vertical.label) {
                <span class="relative flex w-full flex-shrink-0 flex-col items-end" [style.height]="unitRuleWidth()">
                  <span class="text-tertiary-400 dark:text-tertiary-600 absolute left-1/2 top-2 -translate-x-1/2 -rotate-90 text-end text-xs font-bold">{{
                    vertical.label
                  }}</span>
                  <span class="border-tertiary-400 dark:border-tertiary-600 flex h-full w-full flex-shrink-0 flex-col items-end justify-evenly border-t">
                    @for (child of vertical.children; track child.label) {
                      <span class="border-tertiary-400 dark:border-tertiary-600 border-t" [class]="child.isMiddle ? 'w-1.5' : 'w-1'"> </span>
                    }
                  </span>
                </span>
              }
            </span>
          </button>
        </div>
      }

      <!--  canvas-->
      <div class="relative flex flex-1" [class]="{ 'grid-in-[canvas]': ruleEnable() }" (mousedown)="handleMarqueeSelect($event)">
        <!--    mask-->
        <!--        @if (showMask()) {-->
        <!--          <div class="bg-primary-100 absolute inset-0 z-20"></div>-->
        <!--        }-->
        <div class="relative flex h-full w-full scale-100 items-center justify-center p-0">
          <div [style.padding]="pageContainerPadding()" [style]="pageOuterStyle()" #pageContainerRef>
            <div class="w-full">
              <div class="relative">
                <div class="relative bg-white" [style.width.px]="transformWidth()" [style.height.px]="transformHeight()">
                  <!-- guide -->
                  @if (ruleEnable()) {
                    <div
                      [class]="guideLocked() ? 'pointer-events-none' : 'pointer-events-auto'"
                      class="absolute bottom-0 left-0 right-0 top-0"
                      #guideContainerRef
                    >
                      @for (guide of guideViewList(); track guide.id; let index = $index) {
                        <button
                          class="absolute z-20 group-[.locked]:pointer-events-none group-[.locked]:cursor-none"
                          [class]="guide.type === 'horizontal' ? 'left-0 w-full -translate-y-1/2 py-1' : 'top-0 h-full -translate-x-1/2 px-1'"
                          [style]="getGuideStyle(guide)"
                          (mousedown)="handleReplaceGuide($event, index)"
                          (mouseover)="handleViewGuide(index)"
                          (mouseleave)="handleHideGuide(index)"
                        >
                          <div
                            #guideLineRef
                            class="bg-[#8b3dff]"
                            [hidden]="guide.hidden"
                            [class]="guide.type === 'horizontal' ? 'h-[1px] w-full' : 'h-full w-[1px]'"
                          ></div>
                        </button>
                      }
                    </div>
                  }

                  <!-- image crop-->
                  @if (cropImageId(); as id) {
                    <div class="absolute inset-0 z-20">
                      <ace-image-cropper [id]="id"></ace-image-cropper>
                    </div>
                  }

                  <!-- page -->
                  @if (uiStore.onStagePage()) {
                    <div class="absolute inset-0 z-10">
                      <ace-page
                        [pageScale]="uiStore.zoom()"
                        [page]="uiStore.onStagePage()!"
                        [marqueeSelectArea]="marqueeSelectArea()"
                        [scrollLeft]="currentScrollLeft()"
                        [scrollTop]="currentScrollTop()"
                      ></ace-page>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  框选矩形-->
        <div
          class="border-primary-500 bg-primary-100 pointer-events-none absolute border opacity-50"
          [ngStyle]="{ display: marqueeState.show() ? 'block' : 'none' }"
          [style.left.px]="marqueeState.rect()?.x || 0"
          [style.top.px]="marqueeState.rect()?.y || 0"
          [style.width.px]="marqueeState.rect()?.width || 0"
          [style.height.px]="marqueeState.rect()?.height || 0"
        ></div>
      </div>
    </div>
  </div>
  <!--  显示比率-->
  <div class="absolute bottom-1 right-1 z-20 flex h-10 items-center rounded-xl bg-white p-1" #toolContainerRef>
    <div class="group">
      <button
        mat-button
        class="mr-1 h-8 min-h-8 w-8 min-w-8 rounded-lg border-none p-0 outline-none"
        [class]="ruleButtonActive() ? 'bg-primary' : 'group-hover:bg-primary-600 group-hover:bg-opacity-10'"
        (click)="handleRuleTool()"
      >
        <mat-icon
          [class]="ruleButtonActive() ? 'text-white' : 'group-hover:text-tertiary-700 text-tertiary'"
          class="icon-size-5"
          svgIcon="editorup:标尺"
        ></mat-icon>
      </button>
    </div>

    <div class="group">
      <button
        (click)="zoomToRadio(initialRadio())"
        mat-button
        class="mr-1 h-8 min-h-8 w-8 min-w-8 rounded-lg border-none p-0 outline-none"
        [class]="enterFullScreen() ? 'bg-primary' : 'group-hover:bg-primary-600 group-hover:bg-opacity-10'"
      >
        <mat-icon
          [class]="enterFullScreen() ? 'text-white' : 'group-hover:text-tertiary-700 text-tertiary'"
          class="icon-size-5"
          svgIcon="editorup:截图"
        ></mat-icon>
      </button>
    </div>
    <mat-slider [disableRipple]="true" color="primary" class="mx-1 w-24 p-0" [max]="49" [min]="0" [step]="0.1" [discrete]="false" [showTickMarks]="true">
      <input
        matSliderThumb
        [(ngModel)]="currentStep"
        (valueChange)="handleSliderChange($event)"
        (dragStart)="isCenterFocus.set(true)"
        (dragEnd)="isCenterFocus.set(false)"
      />
    </mat-slider>
    <div class="h-7 w-20">
      <ace-dropdown [hiddenIcon]="true" (aceChange)="handleDropDownChange($event)">
        <div class="h-full w-full flex-shrink-0 flex-grow" aceDropdownTrigger>
          <ace-number-input
            [value]="uiStore.zoom() * 100"
            (valueChange)="handleRadioInputChange($event)"
            [aceMinValue]="10"
            [aceMaxValue]="500"
            aceSuffixText="%"
          ></ace-number-input>
        </div>
        @for (option of zoomOptions; track $index) {
          <ace-dropdown-item #dropItemRef>
            <div class="flex h-full w-full flex-shrink-0 flex-grow items-center justify-center">
              <span class="text-base font-light">{{ option }}</span>
            </div>
          </ace-dropdown-item>
        }
      </ace-dropdown>
    </div>
  </div>
  <!--  @if (uiStore.isRotatingElement()) {-->
  <!--    <div-->
  <!--      class="bg-tertiary-950 absolute rounded-[4px]"-->
  <!--      [style.transform]="-->
  <!--        'translate(' +-->
  <!--        ((uiStore.interacting.rotating.position()?.x || 0) + uiStore.pageOffset.x() - currentScrollLeft()) +-->
  <!--        'px, ' +-->
  <!--        ((uiStore.interacting.rotating.position()?.y || 0) + uiStore.pageOffset.y() - currentScrollTop()) +-->
  <!--        'px)'-->
  <!--      "-->
  <!--    >-->
  <!--      {{ uiStore.interacting.rotating.rotation() }}°-->
  <!--    </div>-->
  <!--  }-->
</div>

<ng-template #guideLockedToastRef let-toastRef>
  <div class="flex items-center">
    <span class="mr-2">已添加并锁定辅助线</span>
    <button mat-flat-button color="primary" (click)="handleToastUnLock(toastRef)">解锁</button>
  </div>
</ng-template>
