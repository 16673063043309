import { CommonModule, NgOptimizedImage } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, model, signal } from '@angular/core'

import { MasonryComponent } from '@libs/ng-shared/components/masonry'
import { MasonryItemComponent } from '@libs/ng-shared/components/masonry/masonry-item.component'
import { ScrollListComponent } from '@libs/ng-shared/components/scroll-list'
import { SearchbarComponent } from '@libs/ng-shared/components/searchbar/searchbar.component'
import { IResourceTag } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { ScrollRowComponent } from '#shared/components/scroll-row/scroll-row.component'
import { TemplateMasonryComponent } from '#shared/components/template-masonry/template-masonry.component'

@Component({
  selector: 'ace-template',
  standalone: true,
  imports: [
    CommonModule,
    SearchbarComponent,
    MasonryComponent,
    ScrollRowComponent,
    MasonryItemComponent,
    NgOptimizedImage,
    ScrollListComponent,
    TemplateMasonryComponent
  ],
  templateUrl: './template.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateComponent {
  apiService = inject(ApiService)

  keywords = model<string>('')
  tagList = signal<IResourceTag[]>([])
  currentSelect = signal<IResourceTag | undefined>(undefined)

  constructor() {
    // 获取标签列表
    this.apiService.getTemplateTageList().subscribe(res => {
      this.tagList.set(res || [])
    })
  }

  handleTagSelect(tag: IResourceTag) {
    this.currentSelect.set(tag)
    this.keywords.set(tag.name)
  }
}
