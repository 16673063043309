import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ace-element-rotate-handler',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './element-rotate-handler.component.html',
  styleUrl: './element-rotate-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementRotateHandlerComponent {
  rotateStart = output<MouseEvent>()
  onRotateStart($event: MouseEvent) {
    $event.stopPropagation()
    this.rotateStart.emit($event)
  }
}
