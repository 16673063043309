<div
  class="group-container"
  [style.width.px]="size().width * scale()"
  [style.height.px]="size().height * scale()"
  [style.transform]="'translate(' + left() + 'px, ' + top() + 'px) rotate(' + rotation() + 'deg)'"
>
  <div class="origin-top-left" [style.scale]="scale()">
    <ace-text-input></ace-text-input>
  </div>
</div>
