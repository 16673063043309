<div class="w-90 dark:bg-tertiary-950 flex flex-col items-end justify-start overflow-hidden rounded-lg bg-neutral-50 shadow">
  <div class="flex w-full items-center justify-between border-b border-neutral-50 border-opacity-5 px-3 py-2">
    <span class="text-tertiary-700 dark:text-neutral-50"> 下载 </span>
    <mat-icon
      class="icon-size-5 text-tertiary dark:text-tertiary-300 hover:text-tertiary-700 cursor-pointer dark:hover:text-neutral-50"
      [svgIcon]="'custom:close-small'"
      (click)="closeModal()"
    ></mat-icon>
  </div>

  <div class="flex w-full flex-col gap-4 px-3 py-4">
    <!-- <ace-select [value]="filetype" [options]="filetypes"></ace-select> -->

    <div
      class="border-tertiary-400 dark:border-tertiary-600 bg-tertiary-100 flex h-[140px] w-[336px] items-center justify-center rounded-lg border dark:bg-neutral-950"
    >
      @if (loading) {
        <mat-spinner diameter="32"></mat-spinner>
      } @else {
        <img class="max-h-full max-w-full object-scale-down" [src]="previewSrc" alt="" />
      }
    </div>

    <button mat-button class="bg-primary h-10 w-full rounded-lg font-['MiSans'] font-normal leading-none text-neutral-50" (click)="download()"> 下载 </button>
  </div>
</div>
