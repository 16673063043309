<div class="h-full w-full">
  @if (preview() && elementViewCmp) {
    <ng-container
      [ngComponentOutlet]="elementViewCmp"
      [ngComponentOutletInputs]="{
        scale: scale(),
        previewScale: previewScale(),
        setting: setting(),
        size: size(),
        category: data().category,
        children: children()
      }"
    />
  } @else {
    @if (isGroup()) {
      <ng-container #groupElement />
    } @else if (!isGroup()) {
      <ng-container #atomElement />
    }
  }
</div>
