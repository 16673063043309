@if (aceData(); as aceData) {
  <div
    class="hover:bg-primary-600 group flex w-full flex-shrink-0 cursor-pointer items-center rounded-lg px-3 py-2 hover:bg-opacity-10"
    mat-ripple
    (click)="handleClick()"
  >
    <!--          file-type-->
    <mat-icon svgIcon="editorup:excel" class="icon-size-5 mr-2"></mat-icon>
    <!--          content-->
    <div class="flex h-10 w-0 flex-grow flex-col items-start justify-between overflow-hidden text-ellipsis text-nowrap">
      <span class="text-tertiary-900 w-full overflow-hidden text-ellipsis text-nowrap text-base font-normal">{{ aceData.title }}</span>
      <span class="text-tertiary-600 w-full overflow-hidden text-ellipsis text-nowrap text-sm font-light">{{ aceData.description }}</span>
    </div>
    <!--      download button-->
    <button mat-button class="group mr-3 h-5 w-5 flex-shrink-0">
      <mat-icon class="icon-size-5 hidden group-hover:block" svgIcon="editorup:Download (下载)"></mat-icon>
    </button>
    <!--          upload date-->
    <span class="text-tertiary-600 flex-shrink-0 text-sm font-light">{{ aceData.publishTime | date: 'YYYY/MM/dd' }}</span>
  </div>
}
