import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialog } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'

import { HotToastService } from '@ngxpert/hot-toast'
import { filter, take } from 'rxjs'

import { IChart } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { MetaShapeListComponent } from '#modules/workspace/components/meta-shape-list'
import { FileUploadService } from '#modules/workspace/components/upload/upload.service'
import { ElementService } from '#modules/workspace/services/element.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { ILineSetting, IShapeSetting } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'

import { MetaChartListComponent } from '../meta-chart-list/meta-chart-list.component'
import { MetaBarButtonComponent } from './meta-bar-button.component'

@Component({
  selector: 'ace-meta-bar',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, OverlayModule, MetaChartListComponent, MetaBarButtonComponent, MetaShapeListComponent],
  templateUrl: './meta-bar.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaBarComponent implements OnInit {
  projectService = inject(ProjectService)
  workspaceService = inject(WorkspaceService)
  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)
  apiService = inject(ApiService)
  toastService = inject(HotToastService)
  uploadService = inject(FileUploadService)
  elementService = inject(ElementService)

  modalOpen = false

  // chartButton = viewChild.required<string, ElementRef<HTMLElement>>('chartButton', {
  //   read: ElementRef<HTMLElement>
  // })

  active: 'chart' | 'text' | 'graphic' | 'image' | 'widget' | '' = ''

  dialog = inject(MatDialog)

  clickChartButton() {
    this.modalOpen = !this.modalOpen
    this.active = this.modalOpen ? 'chart' : ''
  }

  ngOnInit(): void {
    return
  }

  addChart(chart: IChart) {
    console.log(chart)
    this.workspaceService.addChart(chart)
  }

  closeChartDialog(e?: MouseEvent) {
    e?.stopPropagation()

    this.modalOpen = false
    this.active = ''
  }

  addText() {
    this.uiStore.resetSelection('element')
    // wait fot another text to inactive
    setTimeout(() => {
      const element = this.elementService.createTextElement()
      if (element) {
        this.uiStore.resetSelection('element', element.id)
        this.textStore.updateAutoEditing(true)
      }
    })
  }

  addShape(path: string) {
    if (path) {
      const defaultSize = { width: 200, height: 200 }
      const shapeElement: IShapeSetting = {
        version: Date.now(),
        path,
        fill: {
          color: '#EEEEEE',
          opacity: 1
        },
        blur: 0,
        shadow: {
          show: false,
          behind: true,
          blur: 4,
          type: 'outer',
          color: {
            color: '#000000',
            opacity: 0.25
          },
          angle: 90,
          offset: 4
        },
        stroke: {
          radius: 0,
          style: 'solid',
          color: {
            color: '#000000',
            opacity: 1
          },
          width: 0
        }
      }
      const shape = this.elementService.createShapeElement(
        {
          x: this.uiStore.pageSize().width / 2 - defaultSize.width / 2,
          y: this.uiStore.pageSize().height / 2 - defaultSize.height / 2
        },
        defaultSize,
        shapeElement
      )
      if (shape) {
        this.uiStore.resetSelection('element', shape.id)
      }
    } else {
      // 线
      const defaultSize = { width: 200, height: 4 }
      const lineSetting: ILineSetting = {
        version: Date.now(),
        type: 'line',
        category: 'straight',
        points: [
          [0, 0],
          [0, defaultSize.width]
        ],
        stroke: {
          style: 'solid',
          radius: 0,
          color: {
            color: '#000000',
            opacity: 1
          },
          width: defaultSize.height,
          cap: 'square'
        }
      }
      const line = this.elementService.createLineElement(
        {
          x: this.uiStore.pageSize().width / 2 - defaultSize.width / 2,
          y: this.uiStore.pageSize().height / 2 - defaultSize.height / 2
        },
        defaultSize,
        lineSetting
      )
      if (line) {
        this.uiStore.resetSelection('element', line.id)
      }
    }
    this.closeChartDialog()
  }

  clickShapeButton() {
    this.modalOpen = !this.modalOpen
    this.active = 'graphic'
  }

  /**
   * 打开已上传并切换到图片进行图片上传
   */
  clickImageButton() {
    // 选择图片并上传
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'image/*'
    input.click()
    input.onchange = () => {
      const file = input.files?.[0]
      if (file) {
        const componentId = this.uploadService.generateQueueId()
        this.uploadService.addFilesToQueue(file, componentId)
        this.uploadService.uploadSuccess$
          .pipe(
            filter(data => data.componentId === componentId),
            take(1)
          )
          .subscribe((data: { fileId: string; componentId: string; type: string }) => {
            if (data.fileId) {
              this.apiService.getImageDetail(data.fileId).subscribe(image => {
                this.workspaceService.addImage(image)
              })
            }
          })
      }
    }
  }
}
