import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, Input, output, untracked } from '@angular/core'

import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { RESIZE_CURSOR_SVG } from '#modules/workspace/types/constants'
import { Direction2 } from '#modules/workspace/types/element'

@Component({
  selector: 'ace-element-edge-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './element-edge-handler.component.html',
  styleUrl: './element-edge-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementEdgeHandlerComponent {
  @Input() position!: { x: number; y: number }
  @Input() hidden = false
  @Input() dashed = false
  @Input() disabled = false
  direction = input.required<Direction2>()

  resizeStart = output<Direction2>()
  uiStore = inject(StageUiStore)

  cursor = computed(() => {
    const elements = this.uiStore.selectedElements()
    let startRotation = 0
    if (elements.length === 1) {
      startRotation = elements[0].rotation
    } else if (elements.length > 1) {
      startRotation = untracked(this.uiStore.virtualElement)?.rotation || 0
    }
    switch (this.direction()) {
      case 'n':
      case 's':
        startRotation += 90
        break
      case 'e':
      case 'w':
        startRotation += 0
        break
    }
    const scaleSvg = RESIZE_CURSOR_SVG.replace('$rotation', startRotation.toString())
    return `url("data:image/svg+xml,${scaleSvg}") 16 16, pointer`
  })

  @HostBinding('style.transform') get translate() {
    return `translate(${this.direction() === 'w' ? this.position.x - 1 : this.position.x}px,
     ${this.direction() === 'n' ? this.position.y - 1 : this.position.y}px)`
  }

  @HostBinding('style.width') get width() {
    if (this.horizontal) {
      return '100%'
    } else {
      return 'fit-content'
    }
  }

  @HostBinding('style.height') get height() {
    if (this.vertical) {
      return '100%'
    } else {
      return 'fit-content'
    }
  }

  get horizontal() {
    return this.direction() === 'n' || this.direction() === 's'
  }

  get vertical() {
    return this.direction() === 'w' || this.direction() === 'e'
  }

  onMouseDown($event: MouseEvent) {
    $event.stopPropagation()
    this.resizeStart.emit(this.direction())
  }
}
