import { inject } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Routes } from '@angular/router'

// import { ReplaceTitleResolver } from '@libs/ng-core/resolvers/replace-title.resolver'
import { TranslateTitleResolver } from '@libs/ng-core/resolvers/translate-title.resolver'

import { AuthGuard } from '#core/guards/auth.guard'
import { NoAuthGuard } from '#core/guards/no-auth.guard'
import { projectDataResolver, projectListResolver } from '#core/resolvers'
import { ReplaceTitleResolver } from '#core/resolvers/replace-title.resolver'
import { AuthComponent } from '#modules/auth/auth.component'
import { HomeComponent } from '#modules/home/home.component'
import { WorkspaceContainerComponent } from '#modules/workspace/workspace-container.component'
import { WorkspaceComponent } from '#modules/workspace/workspace.component'
import { ProjectCollectionService } from '#shared/services/rxdb/project-collection.service'

// import { appInitDataResolver } from './app.resolvers'

export const appRoutes: Routes = [
  // Redirect empty path to '/home/project'
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home/project'
  },

  // Redirect signed-in user to the '/dashboards/project'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  // signed-in-redirect will be in magic link
  { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home/project' },

  // Auth routes for guests
  {
    path: 'auth',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: AuthComponent,
    loadChildren: () => import('./modules/auth/auth.routes')
  },

  // Home routes
  {
    path: 'home',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: HomeComponent,
    loadChildren: () => import('./modules/home/home.routes')
  },

  // Workspace routes
  {
    path: 'workspace',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: WorkspaceContainerComponent,
    providers: [ProjectCollectionService],
    resolve: {
      projectList: projectListResolver
    },
    children: [
      {
        path: ':projectId',
        component: WorkspaceComponent,
        resolve: {
          project: projectDataResolver
        },
        title: ReplaceTitleResolver
      }
    ]
  },
  // {
  //   path: 'workspace/:projectId',
  //   canActivate: [AuthGuard, canActiveProjectFn],
  //   providers: [{ provide: APP_DB, useFactory: getAppDBFactory, deps: [ActivatedRoute] }, ProjectCollectionService, ProjectListService],
  //   resolve: {
  //     // initialData: appInitDataResolver,
  //     appDB: appDbResolver,
  //     project: projectDataResolver,
  //     projectDB: projectDbResolver
  //   },
  //   component: WorkspaceComponent,
  //   title: ReplaceTitleResolver
  // },

  // 404 & 500
  {
    path: '404-not-found',
    loadComponent: () => import('./modules/exception/pages/error-404/error-404.component').then(m => m.Error404Component)
  },

  // Catch all
  { path: '**', redirectTo: '404-not-found' }
]
