import { CommonModule, NgOptimizedImage } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, output } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { MatProgressSpinner } from '@angular/material/progress-spinner'

import { toJpeg, toPng } from 'html-to-image'

import { SelectComponent } from '../setting-widget/select'

@Component({
  selector: 'ace-download-modal',
  standalone: true,
  imports: [CommonModule, MatIconModule, NgOptimizedImage, MatProgressSpinner, SelectComponent],
  templateUrl: './download-modal.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DownloadModalComponent implements OnInit {
  cdr = inject(ChangeDetectorRef)

  handleClose = output<void>()

  previewSrc = ''

  loading = true

  filetype = 'PNG'
  filetypes = ['JPG/JPEG', 'PNG', 'SVG']

  ngOnInit(): void {
    const canvas = document.getElementsByTagName('ACE-CANVAS')[0] as HTMLElement
    // const page = canvas.getElementsByTagName('ACE-PAGE')[0] as HTMLElement
    const page = canvas.getElementsByClassName('elements-container')[0] as HTMLElement

    toPng(page as HTMLElement, {
      // TODO: fonts and backdrop-filter are not supported
      // https://github.com/bubkoo/html-to-image/issues/362#issuecomment-1486047110
      skipFonts: true
    })
      .then(dataUrl => {
        this.loading = false
        this.previewSrc = dataUrl
        this.cdr.detectChanges()
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error)
      })
  }

  closeModal() {
    this.handleClose.emit()
  }

  download() {
    const link = document.createElement('a')
    link.download = 'my-image-name.jpeg'
    link.href = this.previewSrc
    link.click()
  }
}
