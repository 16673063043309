import { Component, inject } from '@angular/core'
import { ActivatedRoute, RouterOutlet } from '@angular/router'

import { APP_DB, getAppDBFactory } from '#shared/services/rxdb/db.base'
import { ProjectCollectionService } from '#shared/services/rxdb/project-collection.service'

@Component({
  selector: 'ace-workspace-container',
  template: ` <router-outlet></router-outlet> `,
  styles: [],
  imports: [RouterOutlet],
  standalone: true
  // providers: [ProjectCollectionService]
})
export class WorkspaceContainerComponent {
  // db = inject(APP_DB)

  constructor() {}
}
