import { inject, Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { RouterStateSnapshot, TitleStrategy } from '@angular/router'

import { TranslocoService } from '@ngneat/transloco'

@Injectable()
export class AutoTranslateTitleStrategy extends TitleStrategy {
  translocoService = inject(TranslocoService)
  title = inject(Title)

  override updateTitle(snapshot: RouterStateSnapshot): void {
    if (snapshot.url) {
      const titleKey = snapshot.url.substring(snapshot.url.lastIndexOf('/') + 1)
      if (titleKey) {
        this.translocoService.selectTranslate(titleKey).subscribe(value => {
          this.title.setTitle(`${value} | EditorUp`)
        })
      } else {
        this.title.setTitle('EditorUp')
      }
    }
  }
}
