<div class="relative">
  @if (hasStroke()) {
    <p
      class="-z-1 shadow-p pointer-events-none absolute select-none text-transparent"
      [style.font-size.px]="fontSize()"
      [style.textAlign]="paraSetting().textAlign"
      [style.lineHeight]="paraSetting().lineHeight"
      [style.minWidth.px]="!horizontalMode() ? lineHeight : 'unset'"
      [style.minHeight.px]="horizontalMode() ? lineHeight : 'unset'"
      [style.width.px]="size().width"
      [style.height.px]="size().height"
      [attr.contenteditable]="false"
    >
      @for (char of paraSetting().chars; let index = $index; track index) {
        <ace-text-input-char
          [charSetting]="char"
          [textSetting]="textSetting()"
          [horizontalMode]="horizontalMode()"
          [hasStroke]="hasStroke()"
          [shadowDom]="true"
          [attr.contenteditable]="false"
        ></ace-text-input-char>
      }
    </p>
  }

  <p
    class="top-p"
    [style.font-size.px]="fontSize()"
    [style.textAlign]="paraSetting().textAlign"
    [style.lineHeight]="paraSetting().lineHeight"
    [style.minWidth.px]="!horizontalMode() ? lineHeight : 'unset'"
    [style.minHeight.px]="horizontalMode() ? lineHeight : 'unset'"
    [style]="{
      width: interactWidth(),
      height: interactHeight()
    }"
  >
    @for (char of paraSetting().chars; let index = $index; track index) {
      <ace-text-input-char
        [charSetting]="char"
        [textSetting]="textSetting()"
        [horizontalMode]="horizontalMode()"
        [hasStroke]="hasStroke()"
        [shadowDom]="false"
      ></ace-text-input-char>
    }
  </p>
</div>
