import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

import { of } from 'rxjs'

import { ApiService } from '../services/api.service'

export const ReplaceTitleResolver: ResolveFn<string> = (route, state) => {
  const apiService = inject(ApiService)
  let title = 'EditorUp'

  if (state.url) {
    // @TODO: we need use :id for entering different project
    const projectId = route.root.firstChild?.params['id'] || ''
    if (projectId) {
      apiService.getProjectById(projectId).subscribe(res => {
        title = res.title
        return of(title)
      })
    }
  }

  return of(title)
}
