import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

@Component({
  selector: 'ace-meta-bar-button',
  standalone: true,
  imports: [CommonModule, MatIconModule],
  template: `
    <button
      mat-button
      class="group flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-lg p-0 text-2xl"
      [class]="
        active() ? 'bg-primary-600 bg-opacity-100' : 'hover:bg-primary-600 bg-opacity-0 hover:bg-opacity-10 dark:hover:bg-neutral-50 dark:hover:bg-opacity-5'
      "
    >
      <mat-icon
        class="icon-size-5"
        [class]="
          active()
            ? 'text-neutral-50 dark:text-neutral-50'
            : 'text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50'
        "
        [svgIcon]="'custom:meta-' + icon()"
      ></mat-icon>
    </button>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaBarButtonComponent {
  active = input.required<boolean>()
  icon = input.required<string>()
}
