<ng-template let-title="title" let-icon="icon" #header>
  <!--      header-->
  <div class="mb-2 flex h-10 items-center text-lg">
    <mat-icon [svgIcon]="icon" color="primary" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
    <span class="text-tertiary-900 text-lg">{{ title }}</span>
  </div>
</ng-template>

<div class="flex h-full flex-shrink-0 flex-grow basis-auto flex-col rounded-xl bg-white p-3">
  @if (isTopicMode()) {
    <div class="mb-2 flex h-10 items-center text-lg">
      <mat-icon (click)="handleTopicBack()" svgIcon="editorup:返回" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
      <mat-icon svgIcon="editorup:Pound-sign (井号)" color="primary" class="mr-2 h-5 min-h-5 w-5 min-w-5"></mat-icon>
      <span>{{ currentTopic() }}</span>
    </div>
  }
  @if (isSearchMode()) {
    @if (tagList() && tagList().length > 0) {
      <div class="my-2 flex w-full items-center">
        <!--        pin list-->
        @if (pinnedList() && pinnedList()!.length > 0) {
          <div class="max-w-1/2 relative flex flex-shrink-0 items-start overflow-hidden pb-3" aceScrollbar [aceScrollbarOptions]="{ suppressScrollY: true }">
            @for (tag of pinnedList(); track tag) {
              <button
                [class.mr-0]="$last"
                [class]="tag.id === currentTag()?.id ? 'border border-solid border-gray-300' : 'border-none'"
                class="mr-2 flex-shrink-0 bg-indigo-500 text-white"
                mat-button
                (click)="handleSelectTag(tag)"
              >
                <div class="flex items-center">
                  <span class="mr-2">{{ tag.label }}</span>
                  @if (tag.selectedCount > 0) {
                    <span class="mx-2">{{ tag.selectedCount }}</span>
                  }
                  <mat-icon svgIcon="editorup:Plus (加)" class="h-4 min-h-4 w-4 min-w-4 rotate-45" (click)="clearTagSelect(tag)"></mat-icon>
                </div>
              </button>
            }
          </div>
          <div class="mx-2 mb-3 h-5 w-0.5 flex-shrink-0 rounded bg-gray-300"></div>
        }

        <div class="relative w-full overflow-hidden">
          <ace-scroll-row>
            @for (tag of tagList(); track tag.id) {
              <button
                class="text-tertiary-900 flex-shrink-0 border border-solid text-base font-normal"
                *ngIf="!tag.pinned"
                [class]="tag.id === currentTag()?.id ? ' bg-primary-500 text-white' : 'border-primary-600 border-opacity-10'"
                mat-button
                (click)="handleSelectTag(tag)"
              >
                <span>{{ tag.label }}</span>
              </button>
            }
          </ace-scroll-row>
          <!--          @if (showScrollHelper()) {-->
          <!--            &lt;!&ndash;          左右翻页&ndash;&gt;-->
          <!--            <div-->
          <!--              (click)="handleScrollTag('left')"-->
          <!--              class="absolute left-0 z-10 flex h-full w-10 items-center justify-start bg-gradient-to-r from-white from-65% to-white to-0% pb-3"-->
          <!--            >-->
          <!--              <button mat-icon-button>-->
          <!--                <mat-icon svgIcon="editorup:Left (左)" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>-->
          <!--              </button>-->
          <!--            </div>-->
          <!--            <div-->
          <!--              (click)="handleScrollTag('right')"-->
          <!--              class="absolute right-0 z-10 flex h-full w-10 items-center justify-end bg-gradient-to-l from-white from-65% to-transparent pb-3"-->
          <!--            >-->
          <!--              <button mat-icon-button>-->
          <!--                <mat-icon svgIcon="editorup:Right (右)" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>-->
          <!--              </button>-->
          <!--            </div>-->
          <!--          }-->
          <!-- all -->
          <!--          <div class="flex w-full flex-grow items-start gap-2 overflow-hidden pb-3" aceScrollbar [aceScrollbarOptions]="{ suppressScrollY: true }">-->

          <!--          </div>-->
        </div>
      </div>
      <div class="my-2 flex w-full flex-wrap">
        @for (tag of tagChildList(); track tag.id) {
          <button
            class="mb-2 mr-2 h-8 min-h-8 flex-shrink-0 border border-solid text-white transition-[border]"
            [class]="!tag.selected ? 'border-primary-200' : 'border-primary'"
            mat-button
            (click)="handleSelectChild(tag, !tag.selected)"
          >
            <div class="flex items-center">
              <span class="text-tertiary-900 mr-2 text-base font-normal">{{ tag.label }}</span>
              <mat-icon svgIcon="editorup:Plus (加)" [class]="{ 'rotate-45': tag.selected }" class="h-4 min-h-4 w-4 min-w-4 transition-transform"></mat-icon>
            </div>
          </button>
        }
      </div>
    }
    <ng-container *ngTemplateOutlet="header; context: { icon: 'editorup:listpoint (节点)', title: '共筛选出“' + searchDataCount() + '”条结果' }"></ng-container>
  }
  @if (isInitMode()) {
    <!--        focus topic-->
    <!--热门搜索    -->
    <div class="mb-6">
      <!--      header-->
      <ng-container *ngTemplateOutlet="header; context: { title: '热门搜索', icon: 'editorup:Fire (火热)' }"></ng-container>
      <!--      hot search list-->
      <div class="flex flex-wrap">
        @for (hot of hotList(); track $index) {
          <button mat-button class="h-14 min-h-14 overflow-hidden rounded-xl p-0" (click)="handleHotSelect(hot)">
            <div class="w-50 flex h-14 items-center px-3 py-2">
              <!--            index-->
              <div
                [class]="$index <= 2 ? 'bg-[#F87771]' : 'bg-tertiary-400'"
                class="mr-2 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded text-sm text-white"
                >{{ $index + 1 }}
              </div>
              <!--            content-->
              <div class="flex flex-grow flex-col items-start overflow-hidden">
                <span class="text-tertiary-900 mb-1 w-full overflow-hidden text-ellipsis text-nowrap text-left text-lg font-light">{{ hot.name }}</span>
                <span class="text-tertiary-600 w-full overflow-hidden text-ellipsis text-nowrap text-left text-sm font-light">{{ hot.description }}</span>
              </div>
            </div>
          </button>
        }
      </div>
    </div>

    <!--    专题推荐-->
    <div class="mb-6">
      <!--      header-->
      <ng-container *ngTemplateOutlet="header; context: { title: '专题推荐', icon: 'editorup:Pound-sign (井号)' }"></ng-container>
      <!--      topic list-->
      <div class="h-45 flex overflow-hidden">
        <!--        card template-->
        <ng-template let-topic="topic" let-width="width" let-height="height" #topicCard>
          <div
            (click)="handleTopicSelect(topic)"
            mat-ripple
            class="relative mr-2 flex-shrink-0 cursor-pointer items-center rounded-xl bg-cover bg-center"
            [class]="[width, height]"
            [style.background-image]="'url(' + topic.image + ')'"
          >
            <div
              class="absolute bottom-0 left-0 right-0 flex h-[58px] items-center justify-center rounded-xl bg-[linear-gradient(180deg,rgba(0,0,0,0.00)_0%,_#1A1A21_100%)] backdrop-blur-[2px]"
            >
              <span class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-lg font-light text-white">{{ '# ' + topic.name }}</span>
            </div>
          </div>
        </ng-template>

        @if (firstTopic(); as topic) {
          <ng-container *ngTemplateOutlet="topicCard; context: { topic: topic, width: 'w-80', height: 'h-full' }"></ng-container>
        }
        <div class="flex flex-grow flex-col flex-wrap content-start items-start justify-between">
          @for (topic of otherTopics(); track topic.id) {
            <ng-container *ngTemplateOutlet="topicCard; context: { topic: topic, width: 'w-60', height: 'h-[86px]' }"></ng-container>
          }
        </div>
      </div>
    </div>
    <!--  最新数据-->
    <div class="mb-6 flex flex-1 flex-col">
      <!--      header-->
      <ng-container *ngTemplateOutlet="header; context: { title: '最新数据', icon: 'editorup:Rocket (火箭)' }"></ng-container>
      <!--      new data list-->

      <div class="flex h-0 flex-grow flex-col overflow-hidden">
        <ace-scroll-list [loader]="newDataLoader" [size]="58" (valueChange)="handleNewDataChange($event)" #newDataScrollListRef>
          @for (data of newDataList(); track data.id) {
            <ace-data-record-row [aceData]="data" (aceClick)="goRecordDetail(data)"></ace-data-record-row>
          }
        </ace-scroll-list>
      </div>
    </div>
  } @else {
    <div class="flex h-0 flex-grow flex-col overflow-hidden">
      <ace-scroll-list #searchScrollListRef [loader]="searchDataLoader" [size]="58" (valueChange)="handleSearchDataChange($event)">
        @for (data of searchDataList(); track data.id) {
          <ace-data-record-row [aceData]="data" (aceClick)="goRecordDetail(data)"></ace-data-record-row>
        }
      </ace-scroll-list>
    </div>
  }
</div>
