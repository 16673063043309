<div class="dark:bg-tertiary-950 relative flex h-full w-[216px] flex-col flex-wrap overflow-hidden rounded-xl bg-neutral-50 p-2" id="uploadComponent">
  <!-- 点击更多的蒙版 -->
  @if (moreMask()) {
    <div id="moreMask" class="more-mask rounded-lg">
      <div id="highlightEle" class="highlight-ele rounded-lg"> </div>
    </div>
  }

  <!-- 拖拽提示 -->
  @if (isDragOver()) {
    <div
      class="absolute bottom-0 left-0 flex h-full w-full items-center justify-center rounded-lg bg-black bg-opacity-70 backdrop-blur-[30px]"
      [class.z-50]="isDragOver()"
    >
      <ace-empty-list [dragging]="true"></ace-empty-list>
    </div>
  }
  <!-- alert -->
  @if (showAlert()) {
    <ace-alert class="alert-container" [appearance]="'outline'" [showIcon]="true" [type]="alert.type">
      {{ alert.message }}
    </ace-alert>
  }

  <mat-tab-group
    class="inline-flex h-full w-[200px] flex-col items-start justify-start"
    [selectedIndex]="tab()"
    (selectedIndexChange)="toggleTab($event)"
    [disablePagination]="true"
    [animationDuration]="0"
  >
    <mat-tab label="数据">
      <div class="flex h-full w-[200px] flex-col gap-3 overflow-hidden">
        <ace-searchbar [(keywords)]="dataSearchValue" class="mt-3 w-full"></ace-searchbar>
        <!-- 上传按钮 -->
        <button
          mat-button
          class="bg-primary flex h-10 w-full flex-col items-center justify-center rounded-lg px-3 py-2.5 text-base leading-none text-neutral-50"
          (click)="dataFileInput.click()"
        >
          <input #dataFileInput type="file" id="dataFileInput" [accept]="'.xls,.xlsx,.csv'" multiple style="display: none" (change)="selectFile($event)" />
          <p class="h-[20px] pt-1">上传数据</p>
        </button>
        <!-- 数据列表 -->
        <ace-scroll-list
          [size]="64"
          [dataCount]="this.uploadService.dataList().length"
          [loader]="uploadDataListLoader"
          (valueChange)="handleDataListChange($event)"
          id="dataList"
          #dataContainer
        >
          <div class="grid-col-1 grid w-full flex-1 gap-2">
            @for (item of this.uploadService.dataList(); track item.id) {
              <ace-upload-item
                [type]="'data'"
                [item]="item"
                (deleteItem)="openDeleteDialog(item, 1)"
                (clickItem)="clickMoreItem(item)"
                (itemChecked)="itemChecked(item)"
                (updateTitleEvent)="updateName($event)"
                (openDataTable)="clickData(item)"
                [multiSelect]="multiSelect()"
                id="{{ item.id || item.fid }}"
                (closeMore)="closeMore()"
              ></ace-upload-item>
            }
          </div>
          <div no-result class="item-center flex w-full justify-center">
            @if (dataSearchValue() !== '') {
              <ace-search-no-result></ace-search-no-result>
            } @else {
              <ace-empty-list class="w-full"></ace-empty-list>
            }
          </div>
        </ace-scroll-list>
        <!-- } -->
      </div>
    </mat-tab>
    <mat-tab label="图片">
      <div class="flex h-full w-[200px] flex-col gap-3 overflow-hidden">
        <ace-searchbar [(keywords)]="imgSearchValue" class="mt-3 w-full"></ace-searchbar>
        <!-- 上传按钮 -->
        <button
          mat-button
          class="bg-primary flex h-10 w-full flex-col items-center justify-center rounded-lg px-3 py-2.5 text-base leading-none text-neutral-50"
          (click)="imgFileInput.click()"
        >
          <input
            #imgFileInput
            type="file"
            id="imageFileInput"
            [accept]="'.png,.jpg,.jpeg,.gif,.webp'"
            multiple
            style="display: none"
            (change)="selectFile($event)"
          />
          <p class="h-[20px] pt-1">上传图片</p>
        </button>
        <!-- 图片列表 -->
        <ace-scroll-list
          [size]="48"
          [dataCount]="this.uploadService.imageList().length"
          [loader]="uploadImageListLoader"
          (valueChange)="handleImageListChange($event)"
          id="imageList"
          #imgContainer
        >
          <div class="grid w-full flex-1 grid-cols-2 gap-2">
            @for (item of this.uploadService.imageList(); track item.id) {
              <ace-upload-item
                [type]="'image'"
                [item]="item"
                [class.selected]="item.selected"
                (clickItem)="clickMoreItem(item)"
                (deleteItem)="openDeleteDialog(item, 1)"
                (itemChecked)="itemChecked(item)"
                [multiSelect]="multiSelect()"
                (updateTitleEvent)="updateName($event)"
                id="{{ item.id || item.fid }}"
                (closeMore)="closeMore()"
              ></ace-upload-item>
            }
          </div>
          <div no-result class="item-center flex w-full justify-center">
            @if (imgSearchValue() !== '') {
              <ace-search-no-result></ace-search-no-result>
            } @else {
              <ace-empty-list class="w-full"></ace-empty-list>
            }
          </div>
        </ace-scroll-list>
        <!-- } -->
      </div>
    </mat-tab>
  </mat-tab-group>

  <!-- 批量删除 -->
  @if (this.selectedObj().length > 0) {
    <div
      class="border-tertiary-100 dark:border-tertiary-800 dark:bg-tertiary-950 absolute bottom-0 left-0 z-10 flex h-12 w-full border-t bg-neutral-50 px-4 py-3 shadow"
    >
      <div class="inline-flex w-full items-center justify-between">
        <div class="flex items-center justify-start gap-2">
          <div class="flex items-center justify-start">
            <mat-checkbox
              [checked]="isAllChecked()"
              class="mr-1"
              [indeterminate]="this.selectedObj().length < this.currentList().length"
              (change)="toggleAllSelection()"
              [color]="'primary'"
            ></mat-checkbox>
            <div class="text-tertiary-900 text-base leading-none dark:text-neutral-50">
              @if (isAllChecked()) {
                全选
              } @else {
                已选
              }
              {{ this.selectedObj().length }}
            </div>
          </div>
          <button
            mat-button
            class="group flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-lg p-0"
            (click)="openDeleteDialog(this.selectedObj(), this.selectedObj().length)"
            [class]="
              'active:bg-primary-600 hover:bg-primary-600 bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-100 dark:hover:bg-neutral-50 dark:hover:bg-opacity-5'
            "
          >
            <mat-icon
              class="icon-size-5"
              [color]="
                'text-tertiary group-hover:text-tertiary-700 dark:text-tertiary-300 dark:group-hover:text-neutral-50 group-active:text-neutral-50 dark:group-active:text-neutral-50'
              "
              [svgIcon]="'editorup:Delete (删除)'"
            ></mat-icon>
          </button>
        </div>
        <button
          mat-button
          class="group flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-lg p-0"
          (click)="closeMultiDelete()"
          [class]="
            'active:bg-primary-600 hover:bg-primary-600 bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-100 dark:hover:bg-neutral-50 dark:hover:bg-opacity-5'
          "
        >
          <mat-icon
            class="icon-size-3"
            [color]="
              'text-tertiary group-hover:text-tertiary-700 dark:text-tertiary-300 dark:group-hover:text-neutral-50 group-active:text-neutral-50 dark:group-active:text-neutral-50'
            "
            [svgIcon]="'editorup:Close (关闭)'"
          ></mat-icon>
        </button>
      </div>
    </div>
  }
</div>
