<div class="max-w-screen flex h-full max-h-screen w-full flex-col overflow-hidden font-sans" #dataPanelRef>
  <div class="relative z-20 flex h-full w-[calc(100%_-_248px)] flex-col">
    <div class="relative mb-2 flex w-full items-center rounded-xl bg-white px-4 py-3">
      <!--    搜索框-->
      <div class="bg-primary-200 relative flex h-10 flex-grow items-center overflow-hidden rounded-lg p-[1px]" #inputContainerRef>
        @if (inputFocus()) {
          <div class="colorful-bg absolute w-full" [style.height]="inputWidth() + 'px'"></div>
        }
        <div class="relative z-10 flex h-full flex-grow items-center rounded-[7px] bg-white">
          <!--      ai icon-->
          <mat-icon svgIcon="editorup:ai" class="icon-size-5 absolute left-2 top-1/2 mr-2 -translate-y-1/2"></mat-icon>
          <input
            #inputRef
            type="text"
            (focus)="inputFocus.set(true)"
            (blur)="inputFocus.set(false)"
            [placeholder]="searchPlaceholder()"
            [(ngModel)]="keyword"
            [matAutocomplete]="auto"
            class="text-tertiary-900 h-full flex-grow border-none bg-transparent pl-9 text-base leading-4 outline-none placeholder:text-slate-400"
          />

          <mat-autocomplete #auto="matAutocomplete" class="border-primary-100 light-shadow mt-2 rounded-lg border">
            @for (street of filteredStreets(); track street) {
              <mat-option
                (onSelectionChange)="handleSuggestSelect($event)"
                [value]="getPlainValue(street)"
                class="hover:bg-primary-600 mx-2 rounded-lg px-3 hover:bg-opacity-10"
              >
                <span class="text-tertiary-900 overflow-hidden text-ellipsis text-nowrap text-lg font-normal" [innerHTML]="street"></span>
              </mat-option>
            }
          </mat-autocomplete>
        </div>
      </div>
      <button
        class="border-primary-200 hover:bg-primary-600 hover:border-primary mx-4 h-10 rounded border border-solid bg-transparent text-base outline-none hover:bg-opacity-15"
        mat-button
        (click)="handleAISearch()"
        >AI 搜索
      </button>
      <button mat-icon-button (click)="close()">
        <mat-icon class="h-4 min-h-4 w-4 min-w-4" svgIcon="editorup:Close (关闭)"></mat-icon>
      </button>
    </div>

    <div class="flex w-full flex-shrink-0 flex-grow overflow-hidden rounded-xl bg-white">
      <div class="relative h-full w-full flex-shrink-0" #explore>
        <ace-data-explore (aceForward)="handleDataDetail($event)" [(keyword)]="keyword"></ace-data-explore>
      </div>
      <div class="relative h-full w-full flex-shrink-0" #visual>
        <ace-data-visual [aceData]="data()" (aceDataVisualBack)="handleBack()" (aceDataVisualGenerate)="handleGenerate($event)"></ace-data-visual>
      </div>
    </div>
  </div>

  @if (showRecommend()) {
    <div @slide class="absolute right-0 top-0 z-10 h-full w-60 rounded-lg bg-white">
      <ace-chart-recommend [data]="recommendData()" (onSelected)="close()"></ace-chart-recommend>
    </div>
  }
</div>
