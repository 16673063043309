import { FocusKeyManager } from '@angular/cdk/a11y'
import { CdkDragDrop, CdkDragEnd, CdkDragMove, CdkDragStart, DragDropModule } from '@angular/cdk/drag-drop'
import { CdkContextMenuTrigger, CdkMenu, CdkMenuItem, CdkMenuModule } from '@angular/cdk/menu'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { DOCUMENT, NgForOf } from '@angular/common'
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  TrackByFunction,
  viewChild,
  viewChildren
} from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'
import { MatButtonModule } from '@angular/material/button'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatIconModule } from '@angular/material/icon'

import invert from 'invert-color'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { debounceTime, distinctUntilChanged } from 'rxjs'

import { generateUid } from '@libs/algorithm'
import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'

import { PageViewComponent } from '#modules/workspace/components/page-view/page-view.component'
import { ClipboardService } from '#modules/workspace/services/clipboard.service'
import { PageService } from '#modules/workspace/services/page.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { ISize } from '#modules/workspace/types/element'
import { IPage, PageListNode } from '#modules/workspace/types/page'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'

import { PageComponent } from '../page/page.component'

interface NameColor {
  color: string
  background: string
  textShadow: string
}

@Component({
  selector: 'ace-page-list',
  standalone: true,
  imports: [
    DragDropModule,
    MatIconModule,
    CdkMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    ScrollbarDirective,
    ScrollingModule,
    PageComponent,
    NgForOf,
    NgxSkeletonLoaderModule,
    PageViewComponent
  ],
  templateUrl: './page-list.component.html',
  styleUrls: ['./page-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageListComponent implements AfterViewInit {
  dragging = false
  listContainer = viewChild.required<ElementRef<HTMLElement>>('listContainer')

  scrollDirection: 'top' | 'bottom' | '' = ''
  scrollSpeed = 10
  scrollInterval: NodeJS.Timeout | null = null

  paginationInput = viewChild<ElementRef<HTMLInputElement>>('paginationInput')
  pageJumping = false

  contextMenuTrigger = viewChildren(CdkContextMenuTrigger)
  cdkMenu = viewChild(CdkMenu)
  keyManager = new FocusKeyManager<CdkMenuItem>([])
  contextMenuIndex = -1

  nameInput = viewChild<ElementRef<HTMLInputElement>>('nameInput')
  pageName = ''
  pageNameEditing = false
  nameInputPlaceholder = ''

  cdr = inject(ChangeDetectorRef)
  document = inject(DOCUMENT)

  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  pageService = inject(PageService)
  clipboardService = inject(ClipboardService)
  workspaceService = inject(WorkspaceService)

  pages = this.pageService.pages
  selectedIds = this.pageService.selectedIds
  selectedPages = this.pageService.selectedPages

  startLoadPages = false
  renderedPages: string[] = []

  bgColors = computed(() => {
    return this.pages().map(i => i.background.color ?? '')
  })

  nameColors: NameColor[] = []

  // private _injector = inject(Injector)
  // private _pageLinkedList = this.pageService._pageLinkedList

  constructor() {
    toObservable(this.bgColors)
      .pipe(distinctUntilChanged(), debounceTime(0))
      .subscribe(colors => {
        this.nameColors = this.invertColors(colors)
        // this.cdr.detectChanges()
      })

    afterNextRender(() => {
      // queueMicrotask(() => {
      setTimeout(() => {
        this.startLoadPages = true
        this.cdr.detectChanges()
      })
    })
  }

  pageTrackBy: TrackByFunction<PageListNode> = (index: number, page: PageListNode) => {
    return page.id + page.updatedAt + page.elementsLatestUpdatedAt()
  }

  ngAfterViewInit(): void {
    this.uiStore.resetSelection('page')
    this.pageService.selectPages([this.uiStore.onStagePage()?.id as string])
  }

  clickPage(event: MouseEvent, index: number, page: PageListNode) {
    if (!event.ctrlKey && !event.metaKey && !event.shiftKey) {
      if (this.uiStore.selectedTarget() === 'page' && this.uiStore.selectedIdsSet().has(page.id) && this.uiStore.selectedIdsSet().size === 1) return

      this.uiStore.resetSelection('page', page.id)
      this.pageService.selectPages([page.id])
    } else if (event.ctrlKey || event.metaKey) {
      if (this.selectedIds().includes(page.id)) {
        this.pageService.selectPages(
          this.selectedPages()
            .filter(i => i.id !== page.id)
            .map(i => i.id)
        )
      } else {
        this.pageService.selectPages([...this.selectedPages().map(i => i.id), page.id])
      }
    } else if (event.shiftKey) {
      // get the closest selected page above index and below index
      let start = -1
      let end = -1
      for (let i = index; i >= 0; i--) {
        if (this.selectedIds().includes(this.pages()[i].id)) {
          start = i
          break
        }
      }
      for (let i = index; i < this.pages().length; i++) {
        if (this.selectedIds().includes(this.pages()[i].id)) {
          end = i
          break
        }
      }
      if (start === -1 && end === -1) {
        this.pageService.selectPages([page.id])
      } else {
        const ids: string[] = []
        if (!this.selectedIds().includes(page.id)) {
          if (start !== -1 && end === -1) end = index
          if (start === -1 && end !== -1) start = index

          this.pages().forEach((i, j) => {
            if (j >= start && j <= end) {
              ids.push(i.id)
            }
          })
          this.pageService.selectPages([...this.selectedIds(), ...ids])
        } else {
          return
          // get the farthest continuous selected page below index
          for (let i = index + 1; i < this.pages().length; i++) {
            if (this.selectedIds().includes(this.pages()[i].id)) {
              ids.push(this.pages()[i].id)
            } else {
              break
            }
          }
          this.pageService.selectPages(this.selectedIds().filter(i => !ids.includes(i)))
        }
      }
    }
  }

  rightClickPage(event: MouseEvent, index: number, page: PageListNode) {
    event.stopPropagation()

    if (!this.selectedIds().includes(page.id)) {
      this.clearSelections()
      this.pageService.selectPages([page.id])
      this.uiStore.resetSelection('page', page.id)
    }

    this.contextMenuIndex = index

    this.getPageName()

    if (this.cdkMenu()) {
      this.keyManager = new FocusKeyManager<CdkMenuItem>((this.cdkMenu() as CdkMenu).items)
    }
  }

  contextMenuDisabled(index: number, page: IPage) {
    if (this.selectedIds().includes(page.id)) {
      return this.selectedPages().some(i => i.locked)
    } else {
      return page.locked
    }
  }

  drop(event: CdkDragDrop<PageListNode[], PageListNode[], string>) {
    if (!event.isPointerOverContainer) return

    const selectedBeforeTarget = []
    for (let i = event.currentIndex - 1; i >= 0; i--) {
      const target = this.pages()[i]
      if (this.selectedPages().includes(target)) {
        selectedBeforeTarget.push(target)
      }
    }
    let lastSelectedIndex = 0
    this.selectedIds().forEach(id => {
      const index = this.pages().findIndex(i => i.id === id)
      if (index > lastSelectedIndex) {
        lastSelectedIndex = index
      }
    })

    const position =
      lastSelectedIndex < event.currentIndex ? event.currentIndex + 1 - selectedBeforeTarget.length : event.currentIndex - selectedBeforeTarget.length

    const deletedIDs = this.selectedPages().map(i => i.id)
    this.pageService.changePageOrder(deletedIDs, position >= 0 ? position : 0)
  }

  dragStarted(event: CdkDragStart<string>, index: number, page: PageListNode) {
    this.closeContextMenu()

    this.dragging = true
    event.source.data = page.id
    if (!this.selectedIds().includes(page.id)) {
      this.clearSelections()
      this.pageService.selectPages([page.id])

      this.uiStore.resetSelection('page', page.id)
    }
  }

  dragMoved(event: CdkDragMove<IPage[]>) {
    const positionY = event.pointerPosition.y
    const listContainer = this.listContainer().nativeElement
    const top = listContainer.getBoundingClientRect().top
    const bottom = top + listContainer.offsetHeight

    if (positionY - top <= 100) {
      this.scrollSpeed = Math.max(positionY - top, 0) / 5 - 20
      this.startScroll('top')
    } else if (positionY - bottom >= -100) {
      this.scrollSpeed = Math.min(positionY - bottom, 0) / 5 + 20
      this.startScroll('bottom')
    }
  }

  stopScroll() {
    if (this.scrollInterval) clearInterval(this.scrollInterval)
    this.scrollDirection = ''
    this.scrollInterval = null
  }

  startScroll(direction: 'top' | 'bottom' | '') {
    if (direction === '') return
    if (direction !== this.scrollDirection) {
      this.stopScroll()
      this.scrollDirection = direction
      this.scrollInterval = setInterval(() => {
        const listContainer = this.listContainer().nativeElement
        listContainer.scrollBy(0, this.scrollSpeed)
      }, 10)
    }
  }

  dragEnded(event: CdkDragEnd<PageListNode[]>) {
    // console.log(event);
  }

  dropped(event: CdkDragDrop<PageListNode[], PageListNode[], PageListNode>) {
    this.dragging = false
    this.stopScroll()
  }

  isPageContextMenuOpen(index: number) {
    return this.contextMenuIndex === index
  }

  contextMenuClosed() {
    this.contextMenuIndex = -1
  }

  closeContextMenu() {
    this.contextMenuIndex = -1
    this.contextMenuTrigger().forEach(i => i.close())
  }

  isPageSelected(id: string) {
    return this.selectedIds().includes(id)
  }

  selectAll() {
    this.pageService.selectAll()
  }

  clearSelections() {
    this.pageService.clearSelections()
  }

  deleteSelections() {
    if (this.selectedPages().some(i => i.locked)) return
    const selectedPages = this.selectedPages()
    const lastPage = this.uiStore.onStagePage() as PageListNode
    this.pageService.deletePages(selectedPages.map(i => i.id))

    const firstIndex = this.pages().indexOf(this.selectedPages()[0])
    const lastIndex = this.pages().indexOf(this.selectedPages()[this.selectedPages().length - 1])
    const firstDeletedPageNode = this.pages()[firstIndex - 1]
    const lastDeletePageNode = this.pages()[lastIndex + 1]
    const newSelectPage = lastDeletePageNode ?? firstDeletedPageNode

    setTimeout(() => {
      if (newSelectPage) {
        this.pageService.selectPages([newSelectPage.id])
        this.uiStore.resetSelection('page', newSelectPage.id)
      } else {
        if (this.pages().length === 0) {
          this.addPage(0, {
            ...lastPage.size
          })
        }
      }
    })

    this.contextMenuClosed()
  }

  copySelections() {
    this.pageService.copyPages()
  }

  paste() {
    this.workspaceService.paste()
  }

  duplicateSelections() {
    this.pageService.duplicatePages()
  }

  canDelete() {
    return !this.selectedPages().some(i => i.locked)
  }

  canLock() {
    return !this.selectedPages().some(i => i.locked)
  }

  canHide() {
    return this.selectedPages().some(i => i.visible)
  }

  hideSelections() {
    this.pageService.hidePages(...this.selectedPages().map(page => page.id))
    // this.selectedPages().forEach(i => {
    //   i.updatePage({
    //     visible: false
    //   })
    //   // i.visible = false
    // })
    // this.updatePages()
  }

  showSelections() {
    this.pageService.showPages(...this.selectedPages().map(page => page.id))
    // this.selectedPages().forEach(i => {
    //   i.updatePage({
    //     visible: true
    //   })
    //   // i.visible = true
    // })
    // this.updatePages()
  }

  showPage(index: number) {
    const page = this.pages()[index]
    if (page) {
      this.pageService.showPages(page.id)
      // page.value.updatePage({
      //   visible: true
      // })
      // page.value.visible = true
      // this.projectService.updatePage(page.value)
    }
  }

  lockSelections() {
    this.pageService.lockPages(...this.selectedPages().map(page => page.id))
    // this.selectedPages().forEach(i => {
    //   i.updatePage({
    //     locked: true
    //   })
    // })
    // this.updatePages()
  }

  unlockSelections() {
    this.pageService.unlockPages(...this.selectedPages().map(page => page.id))
    // this.selectedPages().forEach(i => {
    //   i.updatePage({
    //     locked: false
    //   })
    //   // i.locked = false
    // })
    // this.updatePages()
  }

  addPage(index?: number, defaultSize?: ISize) {
    index = index ?? this.pages().length
    const newPage = this.pageService.addPage(
      {
        id: generateUid(),
        projectId: this.uiStore.currentProject.id(),
        name: '',
        locked: false,
        visible: true,
        background: {
          locked: false,
          color: '#FFFFFF'
        },
        size: defaultSize || this.uiStore.pageSize(),
        theme: this.uiStore.theme(),
        updatedAt: Date.now(),
        orders: [],
        elements: []
      },
      index
    )
    if (newPage) {
      this.pageService.selectPages([newPage.id])
      this.uiStore.resetSelection('page', newPage.id)
    }
  }

  insertPage() {
    const index = this.pages().indexOf(this.selectedPages()[this.selectedPages().length - 1])
    this.addPage(index + 1)
  }

  currentPageNum() {
    if (this.selectedPages().length === 1) return this.pages().indexOf(this.selectedPages()[0]) + 1
    else if (this.selectedPages().length === 0) return this.pages().indexOf(this.uiStore.onStagePage() as PageListNode) + 1
    else return '-'
  }

  openPageJumping() {
    this.pageJumping = true
    setTimeout(() => {
      this.paginationInput()?.nativeElement.focus()
    })
  }

  jumpPage(e: Event) {
    if (!this.pageJumping) return
    console.log(e)
    const value = Number((e.target as HTMLInputElement).value)

    if (value) {
      let num = value - 1
      if (value <= 0) num = 0
      if (value >= this.pages().length) num = this.pages().length - 1

      this.clearSelections()
      this.pageService.selectPages([this.pages()[num].id])
      this.uiStore.resetSelection('page', this.pages()[num].id)

      setTimeout(() => {
        const pageElement = this.document.querySelector('.page-item.selected') as HTMLElement
        pageElement.scrollIntoView({
          // not working
          // behavior: 'smooth',
          block: 'center'
        })
        console.log(pageElement)
      })
    }

    this.pageJumping = false
    ;(e.target as HTMLInputElement).value = ''
  }

  enterPage(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.jumpPage(e)
    }
  }

  getPageName() {
    let name = ''
    if (this.selectedPages().length === 0) return
    if (this.selectedPages().length === 1) {
      name = this.selectedPages()[0].name
      this.nameInputPlaceholder = '请输入标题'
    } else {
      const nameMap = new Map()
      this.selectedPages().forEach(i => nameMap.set(i.name, null))
      this.nameInputPlaceholder = `重命名${this.selectedPages().length}个页面`
      if (nameMap.size === 1) {
        name = this.selectedPages()[0].name
      }
    }
    this.pageName = name
  }

  editName() {
    this.nameInput()?.nativeElement.focus()
  }

  focusName() {
    this.pageNameEditing = true

    setTimeout(() => {
      this.nameInput()?.nativeElement.setSelectionRange(0, this.pageName.length)
    })
  }

  inputName(e: Event) {
    this.pageName = (e.target as HTMLInputElement).value
  }

  updateName(e: Event) {
    e.stopPropagation()
    const value = (e.target as HTMLInputElement).value
    if (this.selectedPages().length === 1) {
      this.selectedPages()[0].updatePage({
        name: value
      })
      this.projectService.updatePage({
        id: this.selectedPages()[0].id,
        name: value
      })
    } else if (this.selectedPages().length > 1) {
      const nameMap = new Map()
      this.selectedPages().forEach(i => nameMap.set(i.name, null))
      if (value || nameMap.size === 1) {
        this.selectedPages().forEach(i => {
          i.updatePage({
            name: value
          })
        })
      }
      this.updatePages()
    }
    this.pageNameEditing = false
  }

  keydownName(e: KeyboardEvent) {
    e.stopPropagation()
    if (e.key === 'Enter' || e.key === 'Escape') {
      // TODO: When click the more icon to open the menu and focusing on the name input, press esc. Should not close the menu directly.
      e.preventDefault()
      this.keyManager.setNextItemActive()
    }
  }

  updatePages() {
    this.projectService.updatePages(this.selectedPages().map(n => n.page))
  }

  invertColors(colors: string[]): NameColor[] {
    const nameColors: NameColor[] = []
    // this.document.querySelectorAll('.page-item').forEach((element, i) => {
    colors.forEach((_color, i) => {
      // generate a random color
      // const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`
      try {
        const bgColor = colors[i] || '#ffffff'
        if (bgColor.indexOf('linear-gradient') !== -1) return
        const color = invert(bgColor, {
          black: '#000000',
          white: '#ffffff',
          threshold: 0.3
        })
        // convert random hex to rgb
        const hex = bgColor.match(/\w\w/g)
        if (!hex) return
        const bgRgb = hex.map(n => parseInt(n, 16))
        // ;(element as HTMLElement).style.backgroundColor = randomColor
        // const pageName = element.querySelector('.page-name') as HTMLElement

        // pageName.style.color = `${color} !important`
        // pageName.style.setProperty('color', color, 'important')
        // pageName.style.textShadow = `
        //   rgba(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}, 0.6) 0px 0px 5px,
        //   rgb(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}) 0px 0px 8px
        // `
        // pageName.style.background = `radial-gradient(
        //   100% 120% at 0px 120%,
        //   rgba(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}, 0.8),
        //   rgba(0, 0, 0, 0)
        // )`
        // }, 1000);
        nameColors.push({
          color: `${color} !important`,
          background: `radial-gradient(100% 120% at 0px 120%,rgba(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}, 0.8),rgba(0, 0, 0, 0))`,
          // radial-gradient(
          //   100% 120% at 0px 120%,
          //   rgba(13, 18, 22, 0.8),
          //   rgba(0, 0, 0, 0))
          textShadow: `rgba(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}, 0.6) 0px 0px 5px,rgb(${bgRgb[0]}, ${bgRgb[1]}, ${bgRgb[2]}) 0px 0px 8px`
        })
      } catch (error) {
        console.error('invert colors error: ', error)
        nameColors.push({
          // color: '#fff !important',
          // background: 'radial-gradient(100% 120% at 0px 120%, rgba(13, 18, 22, 0.8), rgba(0, 0, 0, 0))',
          // textShadow: 'text-shadow: rgba(13, 18, 22, 0.6) 0px 0px 5px, rgba(13, 18, 22) 0px 0px 8px'
          color: '#000 !important',
          background: 'radial-gradient(100% 120% at 0px 120%, rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0))',
          textShadow: 'rgba(255, 255, 255, 0.6) 0px 0px 5px, rgba(255, 255, 255) 0px 0px 8px'
        })
      }
    })
    return nameColors
  }

  getNameColor(i: number) {
    const colors = this.nameColors[i]
    return colors ? `color: ${colors.color}; background: ${colors.background}; text-shadow: ${colors.textShadow}` : ''
  }

  onPageRendered(id: string) {
    this.renderedPages.push(id)
    this.cdr.detectChanges()
  }
}
