<div
  class="border-primary flex h-full w-full items-center justify-center"
  [style.cursor]="cursor()"
  [ngClass]="{
    'border-t': horizontal,
    'border-l': vertical,
    'border-dashed': dashed,
    'border-solid': !dashed,
    'pointer-events-none': disabled
  }"
  (mousedown)="onMouseDown($event)"
>
  @if (!hidden && !disabled) {
    <div
      class="rounded-full border border-gray-500 bg-white"
      [ngClass]="horizontal ? 'h-[6px] w-[14px] -translate-y-[3px]' : 'h-[14px] w-[6px] -translate-x-[3px]'"
    ></div>
  }
</div>
