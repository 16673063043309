import { MatPaginatorIntl } from '@angular/material/paginator'

export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  override itemsPerPageLabel = '每页项目数:'
  override nextPageLabel = '下一页'
  override previousPageLabel = '上一页'
  override firstPageLabel = '第一页'
  override lastPageLabel = '最后一页'

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return `0 共 ${length}`
    }
    const startIndex = page * pageSize
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize
    return `${startIndex + 1} - ${endIndex} 共 ${length}`
  }
}
