import { animate, animation, style, transition, trigger, useAnimation } from '@angular/animations'
import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  HostBinding,
  inject,
  OnDestroy,
  output,
  SecurityContext,
  signal,
  viewChild
} from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { MatAutocomplete, MatAutocompleteOrigin, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatOptionSelectionChange } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'

import { debounceTime, distinctUntilChanged, filter } from 'rxjs'

import { IResourceData } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { ChartRecommendComponent } from '#modules/workspace/components/data-panel/chart-recommend/chart-recommend.component'
import { DataExploreComponent } from '#modules/workspace/components/data-panel/data-explore'
import { DataRecordRowComponent } from '#modules/workspace/components/data-panel/data-record-row'
import { DataVisualComponent } from '#modules/workspace/components/data-panel/data-visual'
import { ExcelData } from '#shared/components'

export enum DataPanelMode {
  /** 搜索 */
  SEARCH = 'search',
  /** 查看数据 */
  VISUAL = 'visual'
}
const leaveAnimation = animation([animate(300), style({ transform: 'translateX({{ endX }})', opacity: '{{ endOpacity }}' })])
const slideAnimation = animation([
  style({ transform: 'translateX({{ startX }})', opacity: '{{ startOpacity }}' }),
  animate(300),
  style({ transform: 'translateX({{ endX }})', opacity: '{{ endOpacity }}' })
])

@Component({
  selector: 'ace-data-panel',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocomplete,
    MatIcon,
    MatAutocompleteTrigger,
    FormsModule,
    MatOption,
    MatButton,
    DataExploreComponent,
    DataVisualComponent,
    ChartRecommendComponent,
    MatIconButton,
    MatAutocompleteOrigin,
    DataRecordRowComponent
  ],
  templateUrl: './data-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slide', [
      transition(':enter', useAnimation(slideAnimation, { params: { startX: '-100%', endX: '0', startOpacity: 0, endOpacity: 1 } })),
      transition(':leave', useAnimation(leaveAnimation, { params: { startX: '0', endX: '-100%', startOpacity: 1, endOpacity: 0 } }))
    ])
  ],
  styleUrl: `./data-panel.component.scss`
})
export class DataPanelComponent implements AfterViewInit, OnDestroy {
  // autocompleteRef = viewChild.required(MatAutocomplete)
  /**
   * 关闭弹窗事件
   */
  aceDataPanelClose = output()
  /**
   * 数据搜索
   */
  exploreDom = viewChild.required<ElementRef<HTMLDivElement>>('explore')
  /**
   * 数据编辑及查看
   */
  visualDom = viewChild.required<ElementRef<HTMLDivElement>>('visual')

  tableData = signal<ExcelData[][]>([])
  recommendData = signal<ExcelData[][]>([])
  inputWidth = signal(100)
  inputFocus = signal(false)
  /**
   * 当前面板处于数据搜索还是数据编辑状态
   * @protected
   */
  protected mode = signal<DataPanelMode>(DataPanelMode.SEARCH)

  /**
   * 联想结果 搜索时根据输入的关键是实时获取联想结果
   */
  protected filteredStreets = signal<SafeHtml[]>([])
  /**
   * 显示recommend
   */
  protected showRecommend = signal(false)
  /**
   * 搜索输入框中默认的搜索词
   */
  protected searchPlaceholder = signal('GDP')
  /**
   * 组件使用据对定位的方式展示，因此高度需要根据屏幕的高度来计算，达到弹窗撑满剩余高度的效果
   */
  protected keyword = signal<string>('')
  // protected inputValue = signal<string>('')
  protected data = signal<IResourceData | undefined>(undefined)
  protected state = signal<'init' | 'end'>('init')
  protected readonly activeElement = document.activeElement
  // protected inputFocus = signal(false)

  private dataPanelRef = viewChild.required<ElementRef<HTMLDivElement>>('dataPanelRef')
  private inputContainerRef = viewChild.required<ElementRef<HTMLDivElement>>('inputContainerRef')
  private domSanitizer = inject(DomSanitizer)

  // 确保改变大小是正确的显示面板
  private _resizeObserver = new ResizeObserver(() => {
    this.inputWidth.set(this.inputContainerRef().nativeElement.clientWidth + 10)
    if (this.mode() === 'search') {
      this.exploreDom().nativeElement.scrollIntoView()
    } else {
      this.visualDom().nativeElement.scrollIntoView()
    }
  })
  /**
   * 当前组件的宿主元素
   * @private
   */
  private readonly hostRef = inject(ElementRef)
  private readonly _apiService = inject(ApiService)

  constructor() {
    effect(() => {
      if (this.mode() === 'search') {
        this.exploreDom().nativeElement.scrollIntoView()
      } else {
        this.visualDom().nativeElement.scrollIntoView()
      }
    })

    toObservable(this.keyword)
      .pipe(
        takeUntilDestroyed(),
        debounceTime(200),
        filter(value => !!value),
        distinctUntilChanged()
      )
      .subscribe(val => {
        this._apiService.getSuggestResourceData(val).subscribe(res => {
          this.filteredStreets.set(
            res.list
              .map(item => item.highlight)
              .filter((s): s is string => !!s)
              .map(item => this.domSanitizer.bypassSecurityTrustHtml(item))
          )
        })
      })
  }

  @HostBinding('style') get hostStyle() {
    return {
      height: '100%',
      width: '100%'
    }
  }

  ngAfterViewInit() {
    this._resizeObserver.observe(this.dataPanelRef().nativeElement)
  }

  ngOnDestroy() {
    this._resizeObserver.disconnect()
  }

  /**
   * 处理搜索事件
   */
  handleAISearch() {
    // this.keyword.set(this.inputValue())
    if (!this.keyword()) {
      this.keyword.set(this.searchPlaceholder())
    }
    this.mode.set(DataPanelMode.SEARCH)
  }

  handleDataDetail(data: IResourceData) {
    this.mode.set(DataPanelMode.VISUAL)
    this.data.set(data)
  }

  handleBack() {
    this.mode.set(DataPanelMode.SEARCH)
  }

  /**
   * 关闭当前弹窗
   */
  close() {
    this.aceDataPanelClose?.emit()
  }

  handleGenerate($event: ExcelData[][]) {
    this.showRecommend.set(true)
    this.recommendData.set($event)
  }

  // handleSuggestClick(item: HTMLElement) {
  //   console.log(item.innerText)
  //   this.inputValue.set(item.innerText)
  //   this.filteredStreets.set([])
  //   this.handleAISearch()
  // }

  getPlainValue(value: SafeHtml) {
    const plainValue = this.domSanitizer.sanitize(SecurityContext.HTML, value)
    if (plainValue) {
      return plainValue.replace(/<[^>]*>/g, '')
    }
    return plainValue
  }

  handleSuggestSelect($event: MatOptionSelectionChange<string | null>) {
    if ($event.source.value) {
      this.keyword.set($event.source.value)
      this.handleAISearch()
    }
  }
}
