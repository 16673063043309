import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ace-empty-list',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './emptyList.component.html',
  styleUrl: './emptyList.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListComponent {
  dragging = input(false)
}
