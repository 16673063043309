import { CdkDrag, CdkDragDrop, CdkDragEnd, CdkDragMove, CdkDragPlaceholder, CdkDragStart, CdkDropList } from '@angular/cdk/drag-drop'
import { CdkContextMenuTrigger, CdkMenu, CdkMenuItem } from '@angular/cdk/menu'
import { ScrollingModule } from '@angular/cdk/scrolling'
import { DOCUMENT } from '@angular/common'
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  inject,
  Signal,
  viewChild
} from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import _ from 'lodash'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'

import { GroupElementTreeNode, PageElementTreeNode } from '#modules/workspace/models/element-node'
import { ElementService } from '#modules/workspace/services/element.service'
import { PageService } from '#modules/workspace/services/page.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { ElementTypeEnum } from '#modules/workspace/types/constants'
import { AtomType, IGroupElement, IPageElementBase } from '#modules/workspace/types/element'
import { PageListNode } from '#modules/workspace/types/page'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ContextMenuComponent, IContextMenuItem } from '#shared/components/context-menu/context-menu.component'
import { ProjectService } from '#shared/services/project/project.service'

import { ClipboardService } from '../../services/clipboard.service'
import { ElementContainerComponent } from '../element-container/element-container.component'
import { PageComponent } from '../page/page.component'

@Component({
  selector: 'ace-layer-list',
  standalone: true,
  imports: [
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    MatIconModule,
    CdkContextMenuTrigger,
    CdkMenuItem,
    CdkMenu,
    ScrollbarDirective,
    ScrollingModule,
    PageComponent,
    ElementContainerComponent,
    ContextMenuComponent,
    NgxSkeletonLoaderModule
  ],
  templateUrl: './layer-list.component.html',
  styleUrls: ['./layer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayerListComponent implements AfterViewInit {
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  elementService = inject(ElementService)
  clipboardService = inject(ClipboardService)
  pageService = inject(PageService)
  workspaceService = inject(WorkspaceService)

  page = this.uiStore.onStagePage as Signal<PageListNode>
  pageId = computed(() => this.page().id)

  layers = this.elementService.layers
  dragging = false
  listContainer = viewChild<ElementRef<HTMLElement>>('listContainer')

  selectedIds = this.elementService.selectedIds
  selectedLayers = this.elementService.selectedLayers
  hasSelectedLocked = this.elementService.hasSelectedLocked

  scrollDirection: 'top' | 'bottom' | '' = ''
  scrollSpeed = 10
  scrollInterval: NodeJS.Timeout | null = null

  startLoadElements = false
  renderedLayers: string[] = []

  elContextMenu = computed<IContextMenuItem[]>(() => {
    const alignDistributeStatus = this.elementService.alignDistributeStatus()
    return [
      {
        label: '复制',
        icon: 'custom:element-copy',
        shortcut: ['Ctrl', 'C'],
        callback: this.elementService.copyElements.bind(this.elementService),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '复制样式',
        icon: 'custom:copy-style',
        shortcut: ['Ctrl', 'Alt', 'C'],
        // callback: this.paste.bind(this),
        hide: true
      },
      {
        label: '粘贴',
        icon: 'custom:element-paste',
        shortcut: ['Ctrl', 'V'],
        callback: this.workspaceService.paste.bind(this.workspaceService),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '创建副本',
        icon: 'custom:page-duplicate',
        shortcut: ['Ctrl', 'D'],
        callback: this.duplicateSelections.bind(this),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '删除',
        icon: 'custom:page-delete',
        // shortcut: ['DELETE'],
        callback: this.deleteSelections.bind(this),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '隐藏',
        icon: 'custom:page-hidden',
        callback: this.hideSelections.bind(this),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '图层',
        icon: 'custom:element-layer',
        children: [
          {
            label: '上移',
            icon: 'custom:layer-up',
            disabled: !this.elementService.canMoveUp(),
            shortcut: ['Ctrl', ']'],
            callback: this.elementService.moveUp.bind(this.elementService)
          },
          {
            label: '移动到最上层',
            icon: 'custom:layer-top',
            disabled: !this.elementService.canMoveUp(),
            shortcut: ['Ctrl', 'Alt', ']'],
            callback: this.elementService.moveTop.bind(this.elementService)
          },
          {
            label: '下移',
            icon: 'custom:layer-down',
            disabled: !this.elementService.canMoveDown(),
            shortcut: ['Ctrl', '['],
            callback: this.elementService.moveDown.bind(this.elementService)
          },
          {
            label: '移动到最下层',
            icon: 'custom:layer-bottom',
            disabled: !this.elementService.canMoveDown(),
            shortcut: ['Ctrl', 'Alt', '['],
            callback: this.elementService.moveBottom.bind(this.elementService)
          }
        ],
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '页面对齐',
        icon: 'editorup:align-left',
        children: [
          {
            label: '左对齐',
            icon: 'editorup:align-left',
            disabled: !alignDistributeStatus.alignLeft,
            callback: this.elementService.alignLeft.bind(this.elementService, this.uiStore.selectedRootElements())
          },
          {
            label: '水平居中',
            icon: 'editorup:align-horizontally',
            disabled: !alignDistributeStatus.alignHorizontal,
            callback: this.elementService.alignHorizontal.bind(this.elementService, this.uiStore.selectedRootElements())
          },
          {
            label: '右对齐',
            icon: 'editorup:align-right',
            disabled: !alignDistributeStatus.alignRight,
            callback: this.elementService.alignRight.bind(this.elementService, this.uiStore.selectedRootElements())
          },
          {
            label: '顶部对齐',
            icon: 'editorup:align-top',
            disabled: !alignDistributeStatus.alignTop,
            callback: this.elementService.alignTop.bind(this.elementService, this.uiStore.selectedRootElements())
          },
          {
            label: '垂直居中',
            icon: 'editorup:align-vertical',
            disabled: !alignDistributeStatus.alignVertical,
            callback: this.elementService.alignVertical.bind(this.elementService, this.uiStore.selectedRootElements())
          },
          {
            label: '底部对齐',
            icon: 'editorup:align-bottom',
            disabled: !alignDistributeStatus.alignBottom,
            callback: this.elementService.alignBottom.bind(this.elementService, this.uiStore.selectedRootElements())
          }
        ],
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '锁定',
        icon: 'custom:page-lock',
        callback: this.elementService.lockElements.bind(this.elementService),
        hide: this.page().locked || this.hasSelectedLocked()
      },
      {
        label: '解锁',
        icon: 'custom:page-unlock',
        callback: this.elementService.unlockElements.bind(this.elementService),
        disabled: this.page().locked,
        hide: !this.hasSelectedLocked() && !this.page().locked
      },
      {
        label: '打组',
        icon: '',
        shortcut: [],
        callback: this.elementService.createGroupElement.bind(this.elementService, this.uiStore.selectedRootElements()),
        disabled: false,
        hide: true
      },
      {
        label: '解组',
        icon: '',
        shortcut: [],
        callback: this.elementService.ungroupElement.bind(this.elementService, this.uiStore.selectedRootElements()[0] as GroupElementTreeNode),
        disabled: false,
        hide: true
      }
    ]
  })

  pageContextMenu = computed(() => {
    const menu: IContextMenuItem[] = [
      {
        label: '复制',
        icon: 'custom:element-copy',
        shortcut: ['Ctrl', 'C'],
        callback: this.pageService.copyBackground.bind(this.pageService),
        hide: this.page().locked
      },
      {
        label: '复制样式',
        icon: 'custom:copy-style',
        shortcut: ['Ctrl', 'Alt', 'C'],
        callback: () => {},
        hide: true
      },
      {
        label: '粘贴',
        icon: 'custom:element-paste',
        shortcut: ['Ctrl', 'V'],
        callback: this.workspaceService.paste.bind(this.workspaceService),
        hide: this.page().locked
      },
      {
        label: '删除背景',
        icon: 'custom:page-delete',
        // shortcut: ['DELETE'],
        callback: this.pageService.deleteBackground.bind(this.pageService),
        hide: (!this.page().background.image && !this.page().background.color) || this.page().locked || this.page().background.locked
      },
      {
        label: '锁定背景',
        icon: 'custom:page-lock',
        callback: this.pageService.lockBackground.bind(this.pageService),
        hide: (!this.page().background.image && !this.page().background.color) || this.page().background.locked || this.page().locked
      },
      {
        label: '解锁背景',
        icon: 'custom:page-unlock',
        callback: this.pageService.unlockBackground.bind(this.pageService),
        hide: !this.page().background.locked || this.page().locked
      }
    ]
    return menu
  })

  cdr = inject(ChangeDetectorRef)
  document = inject(DOCUMENT)

  constructor() {
    afterNextRender(() => {
      // this.cdr.detectChanges()
      // queueMicrotask(() => {
      setTimeout(() => {
        this.startLoadElements = true
        this.cdr.detectChanges()
      })
    })
  }

  ngAfterViewInit(): void {
    if (this.uiStore.selectedTarget() === 'page') {
      this.uiStore.resetSelection('background')
      this.cdr.detectChanges()
    }
  }

  getGroupChildren(id: string): IPageElementBase<AtomType>[] {
    return this.layers().filter(el => el.parent === id) as IPageElementBase<AtomType>[]
  }

  layerPreviewScale(layer: PageElementTreeNode) {
    const widthScale = 128 / layer.size.width
    const heightScale = 32 / layer.size.height
    return Math.min(widthScale, heightScale)
  }

  clickLayer(event: MouseEvent, index: number, id: string, layer: PageElementTreeNode) {
    if (!layer.visible) {
      return
    }
    if (
      // 不使用组合键，只使用鼠标左键单击某一个图层
      !event.ctrlKey &&
      !event.metaKey &&
      !event.shiftKey
    ) {
      this.elementService.selectElements([id])
    } else if (event.ctrlKey || event.metaKey) {
      if (this.selectedIds().includes(id)) {
        const ids: string[] = this.selectedIds().filter(i => i !== id)
        if (ids.length === 0) this.clearSelections()
        else this.elementService.selectElements(ids)
      } else {
        this.elementService.selectElements([...this.selectedIds(), id])
      }
    } else if (event.shiftKey) {
      // get the closest selected layer above index and below index
      let start = -1
      let end = -1
      for (let i = index; i >= 0; i--) {
        if (this.selectedIds().includes(this.layers()[i].id)) {
          start = i
          break
        }
      }
      for (let i = index; i < this.layers().length; i++) {
        if (this.selectedIds().includes(this.layers()[i].id)) {
          end = i
          break
        }
      }
      if (start === -1 && end === -1) {
        this.elementService.selectElements([...this.selectedIds(), id])
      } else {
        const ids: string[] = []
        if (!this.selectedIds().includes(id)) {
          if (start !== -1 && end === -1) end = index
          if (start === -1 && end !== -1) start = index
          this.layers().forEach((i, j) => {
            if (j >= start && j <= end) {
              if (i.visible) ids.push(i.id)
            }
          })
          this.elementService.selectElements([...this.selectedIds(), ...ids])
        } else {
          // get the farthest continuous selected layer below index
          for (let i = index + 1; i < this.layers().length; i++) {
            if (!this.layers()[i].visible) return

            const _id = this.layers()[i].id
            if (this.selectedIds().includes(_id)) {
              ids.push(_id)
            } else {
              break
            }
          }

          this.elementService.selectElements(this.selectedIds().filter(k => !ids.includes(k)))
        }
      }
    }
  }

  clickBackground() {
    this.clearSelections()
  }

  rightClickLayer(event: MouseEvent, index: number, layer: PageElementTreeNode) {
    if (!layer.visible) {
      return
    }
    if (!this.selectedIds().includes(layer.id)) {
      this.clearSelections()
      this.elementService.selectElements([layer.id])
    }
  }

  rightClickBackground() {
    this.clearSelections()
  }

  contextMenuDisabled(index: number, layer: PageElementTreeNode) {
    if (this.selectedIds().includes(layer.id)) {
      return this.layers().find(i => this.selectedIds().includes(i.id) && !i.visible)
    } else {
      return !layer.visible
    }
  }

  drop(event: CdkDragDrop<PageElementTreeNode[], PageElementTreeNode[], PageElementTreeNode>) {
    if (!event.isPointerOverContainer) return

    //   moveItemInArray(this.layers(), event.previousIndex, event.currentIndex);

    const selections = this.layers().filter(i => this.selectedIds().includes(i.id))

    const newSelections = _.cloneDeep(selections)

    // insert the selections at the new index: event.currentIndex
    this.layers().splice(event.previousIndex < event.currentIndex ? event.currentIndex + 1 : event.currentIndex, 0, ...newSelections)

    // remove the old ones
    this.layers.set(this.layers().filter(i => !selections.includes(i)))

    // select the new ones
    this.elementService.selectElements(newSelections.map(i => i.id))

    this.projectService.resetElementsOrder(
      this.pageId(),
      this.layers()
        .map(i => i.id)
        .reverse()
    )
  }

  dragStarted(event: CdkDragStart<PageElementTreeNode[]>, index: number, layer: PageElementTreeNode) {
    // console.log(event, index);
    this.dragging = true
    if (!this.selectedIds().includes(layer.id)) {
      this.elementService.selectElements([layer.id])
    }
  }

  dragMoved(event: CdkDragMove<PageElementTreeNode[]>) {
    const positionY = event.pointerPosition.y
    const listContainer = (this.listContainer() as ElementRef<HTMLElement>).nativeElement
    const top = listContainer.getBoundingClientRect().top
    const bottom = top + listContainer.offsetHeight
    // console.log(positionY, top, bottom, positionY - top, positionY - bottom)

    if (positionY - top <= 100) {
      this.scrollSpeed = Math.max(positionY - top, 0) / 5 - 20
      this.startScroll('top')
    } else if (positionY - bottom >= -100) {
      this.scrollSpeed = Math.min(positionY - bottom, 0) / 5 + 20
      this.startScroll('bottom')
    }
  }

  stopScroll() {
    if (this.scrollInterval) clearInterval(this.scrollInterval)
    this.scrollDirection = ''
    this.scrollInterval = null
  }

  startScroll(direction: 'top' | 'bottom' | '') {
    if (direction === '') return
    if (direction !== this.scrollDirection) {
      this.stopScroll()
      this.scrollDirection = direction
      this.scrollInterval = setInterval(() => {
        const listContainer = (this.listContainer() as ElementRef<HTMLElement>).nativeElement
        listContainer.scrollBy(0, this.scrollSpeed)
      }, 10)
    }
  }

  dragEnded(event: CdkDragEnd<IPageElementBase[]>) {
    // console.log(event);
  }

  dropped(event: CdkDragDrop<IPageElementBase[], IPageElementBase[], IPageElementBase>) {
    // console.log(event);
    this.dragging = false
    this.stopScroll()
  }

  selectAll() {
    this.elementService.selectAll()
  }

  isLayerSelected(id: string) {
    return this.selectedIds().includes(id)
  }

  clearSelections() {
    this.uiStore.resetSelection('background')
  }

  deleteSelections() {
    if (this.hasSelectedLocked()) return

    this.layers.set(this.layers().filter(i => !this.selectedIds().includes(i.id)))
    // this.clearSelections()
    this.elementService.deleteElements(...this.selectedIds())
  }

  lockSelections() {
    this.layers().forEach(i => {
      if (this.selectedIds().includes(i.id)) {
        i.update({
          locked: true
        })
      }
    })
  }

  duplicateSelections() {
    this.elementService.duplicateElements()
  }

  hideSelections() {
    this.elementService.hideElements()
  }

  showLayer(layer: PageElementTreeNode) {
    layer.update({
      visible: true
    })
    this.elementService.showElements([layer])
  }

  canGroup() {
    return this.selectedIds().length > 1 && !this.layers().find(i => this.selectedIds().includes(i.id) && i.locked)
  }

  canUngrouped() {
    return !this.layers().find(i => this.selectedIds().includes(i.id) && i.category !== ElementTypeEnum.Group)
  }

  onElementRendered(id: string) {
    this.renderedLayers.push(id)
    this.cdr.detectChanges()
  }
}
