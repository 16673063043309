import { CdkMenu, CdkMenuItem, CdkMenuTrigger, CdkTargetMenuAim } from '@angular/cdk/menu'
import { CdkPortalOutlet } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { MatDivider } from '@angular/material/divider'
import { MatIcon } from '@angular/material/icon'

import { TranslocoPipe } from '@ngneat/transloco'

export interface IContextMenuItem {
  label: string
  icon?: string
  callback?: (...params: never) => void
  shortcut?: string[]
  children?: IContextMenuItem[]
  disabled?: boolean
  br?: boolean
  hide?: boolean
}

@Component({
  selector: 'ace-context-menu',
  standalone: true,
  imports: [CommonModule, CdkMenu, MatIcon, TranslocoPipe, CdkMenuItem, CdkPortalOutlet, CdkMenuTrigger, CdkTargetMenuAim, MatDivider],
  templateUrl: './context-menu.component.html',
  styleUrl: './context-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuComponent {
  menuItems = input.required<IContextMenuItem[], IContextMenuItem[]>({
    transform: items => {
      return items
    }
  })
}
