import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, input, output, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import * as XLSX from 'xlsx'

import { IResourceData } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { DataRecordRowComponent } from '#modules/workspace/components/data-panel/data-record-row'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { DataGridComponent, ExcelData } from '#shared/components'
import { IMenu, MenuComponent } from '#shared/components/menu/menu.component'

@Component({
  selector: 'ace-data-visual',
  standalone: true,
  imports: [CommonModule, MatIconButton, MatIcon, MatButton, DataGridComponent, DataRecordRowComponent, MenuComponent],
  templateUrl: './data-visual.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataVisualComponent {
  aceData = input<IResourceData>()
  tableData = signal<ExcelData[][]>([[]])

  generateActive = signal(false)

  aceDataVisualBack = output()
  aceDataVisualGenerate = output<Array<Array<string>>>()

  similarDataList = signal<IResourceData[]>([])

  downloadOptions: IMenu[] = [
    { text: '下载至本地', icon: 'editorup:download', callback: () => this._dataGridRef().downloadExcel(this.aceData()?.title || '') },
    { text: '保存数据至已上传', icon: 'upload', callback: () => this._workspaceService.uploadData(this.tableData(), this.aceData()?.title) }
  ]

  private gridData: Array<Array<string>> = []
  private _apiService = inject(ApiService)
  private _dataGridRef = viewChild.required(DataGridComponent)
  private _workspaceService = inject(WorkspaceService)

  constructor() {
    toObservable(this.aceData)
      .pipe(takeUntilDestroyed())
      .subscribe(val => {
        if (val) {
          this.getDataDetail(val)
          this.getSimilarData(val)
        }
      })
  }

  handleGenerateChart() {
    this.aceDataVisualGenerate.emit(this.gridData)
  }

  handleSelection($event: Array<Array<string>>) {
    // 数据最小必须为一个2*2的表格
    console.log($event)
    this.generateActive.set($event.length > 1 && $event[0].length > 1)
    this.gridData = $event
  }

  handleDataClick(data: IResourceData) {
    this.getDataDetail(data)
  }

  getSimilarData(data?: IResourceData) {
    // 获取搜索结果
    data = data || this.aceData()
    if (data) {
      const { id, title } = data
      this._apiService.getSimilarResourceData(id, title).subscribe(res => {
        // 仅显示3条数据
        this.similarDataList.set(res.list.slice(0, 3) || [])
      })
    }
  }

  getDataDetail(data: IResourceData) {
    // 获取数据详情
    this._apiService.getResourceDataDetail(data.link).subscribe(({ link }) => {
      // 使用fetch 获取文件详情转化为blob并使用xlsx转化为excel数据
      fetch(link)
        .then(res => res.blob())
        .then(blob => {
          const reader = new FileReader()
          reader.onload = () => {
            const uint8Array = new Uint8Array(reader.result as ArrayBuffer)
            const workbook = XLSX.read(uint8Array, { type: 'array' })
            const sheet = workbook.Sheets[workbook.SheetNames[0]]
            const excelData = XLSX.utils.sheet_to_json(sheet, { header: 1, blankrows: false, defval: '', skipHidden: true, raw: false, rawNumbers: false })
            console.log(excelData)
            this.tableData.set(excelData as ExcelData[][])
          }
          reader.readAsArrayBuffer(blob)
        })
    })
  }
}
