import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, Input, output, untracked } from '@angular/core'

import { IPosition } from '@libs/algorithm'

import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { RESIZE_CURSOR_SVG } from '#modules/workspace/types/constants'
import { Direction } from '#modules/workspace/types/element'

@Component({
  selector: 'ace-element-vertex-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './element-vertex-handler.component.html',
  styleUrl: './element-vertex-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementVertexHandlerComponent {
  @Input({ required: true }) position!: IPosition
  @Input({ required: true }) lockRatio!: boolean
  @Input() hidden = false
  direction = input.required<Direction>()
  scaleStart = output<boolean>()
  resizeStart = output<boolean>()
  uiStore = inject(StageUiStore)

  cursor = computed(() => {
    const elements = untracked(this.uiStore.selectedElements)
    let startRotation = 0
    if (elements.length === 1) {
      startRotation = elements[0].rotation
    } else if (elements.length > 1) {
      startRotation = untracked(this.uiStore.virtualElement)?.rotation || 0
    }
    switch (this.direction()) {
      case 'nw':
      case 'se':
        startRotation += 45
        break
      case 'ne':
      case 'sw':
        startRotation += -45
        break
    }
    const scaleSvg = RESIZE_CURSOR_SVG.replace('$rotation', startRotation.toString())
    return `url("data:image/svg+xml,${scaleSvg}") 16 16, pointer`
  })

  @HostBinding('style.transform') get translate() {
    return `translate(${this.position.x}px, ${this.position.y}px)`
  }

  @HostBinding('style.display') get display() {
    return this.hidden ? 'none' : 'block'
  }

  onMouseDown($event: MouseEvent) {
    $event.stopPropagation()
    if (this.lockRatio) {
      this.scaleStart.emit(true)
    } else {
      this.resizeStart.emit(true)
    }
  }
}
