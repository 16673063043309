<div class="w-82 relative flex h-full flex-col gap-2 rounded-xl bg-white p-2">
  <ace-searchbar [(keywords)]="keywords"></ace-searchbar>
  @if (tagList().length > 0) {
    <ace-scroll-row>
      @for (tag of tagList(); track tag.id) {
        <div
          class="border-primary-200 flex items-center justify-center gap-2 rounded-3xl border px-4 py-2"
          [class.border-primary]="currentSelect()?.id === tag.id"
          (click)="handleTagSelect(tag)"
        >
          <span class="text-tertiary-900 text-nowrap text-base font-normal">{{ tag }}</span>
        </div>
      }
    </ace-scroll-row>
  }
  <div class="w-full flex-grow overflow-hidden">
    <ace-template-masonry [keywords]="keywords()"></ace-template-masonry>
  </div>
</div>
