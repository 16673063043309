import { isDevMode, Signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'

import { addRxPlugin, createRxDatabase, RxReactivityFactory } from 'rxdb'
import { RxDBCleanupPlugin } from 'rxdb/plugins/cleanup'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBMigrationSchemaPlugin } from 'rxdb/plugins/migration-schema'
import { RxDBQueryBuilderPlugin } from 'rxdb/plugins/query-builder'
import { getRxStorageDexie } from 'rxdb/plugins/storage-dexie'
import { RxDBUpdatePlugin } from 'rxdb/plugins/update'

import { EditorUpAppCollections } from '#modules/workspace/types/db'

const reactivityFactory: RxReactivityFactory<Signal<any>> = {
  fromObservable(obs, initialValue: any) {
    return toSignal(obs, { initialValue })
  }
}

export async function initDB() {
  if (isDevMode()) {
    await import('rxdb/plugins/dev-mode').then(module => addRxPlugin(module.RxDBDevModePlugin))
  }
  // addRxPlugin(RxDBcrdtPlugin)
  addRxPlugin(RxDBMigrationSchemaPlugin)
  addRxPlugin(RxDBLeaderElectionPlugin)
  addRxPlugin(RxDBCleanupPlugin)
  addRxPlugin(RxDBUpdatePlugin)
  addRxPlugin(RxDBQueryBuilderPlugin)

  const rxdb = await createRxDatabase<EditorUpAppCollections>({
    name: `editorup-projects`,
    storage: getRxStorageDexie(),
    multiInstance: true,
    eventReduce: true,
    ignoreDuplicate: true,
    reactivity: reactivityFactory
  })

  return rxdb
}
