import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  Injector,
  OnDestroy,
  signal,
  TemplateRef,
  untracked,
  viewChild,
  viewChildren,
  ViewContainerRef
} from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { MatButton } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { MatSliderModule } from '@angular/material/slider'

import { patchState, signalState } from '@ngrx/signals'
import { HotToastRef, HotToastService } from '@ngxpert/hot-toast'
import { produce } from 'immer'
import _ from 'lodash'
import { isEqual } from 'lodash-es'
import { combineLatestWith, debounceTime, distinctUntilChanged, filter, fromEvent, map, Subscription, take, takeUntil, tap, timer } from 'rxjs'

import { IPosition } from '@libs/algorithm'
import { isMac, isWindows } from '@libs/ng-shared/utils/platform'

import { customClick } from '#core/utils/mouseEvent'
import { ImageCropperComponent } from '#modules/workspace/components/image-cropper/image-cropper.component'
import { NumberInputComponent } from '#modules/workspace/components/setting-widget/number-input'
import { ChangeAction, Guide, GuideType } from '#modules/workspace/store'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { RESIZE_CURSOR_SVG, ROTATION_CURSOR_SVG } from '#modules/workspace/types/constants'
import { ISize } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { DropDownChange, DropdownComponent } from '#shared/components'
import { DropdownItemComponent } from '#shared/components/dropdown/dropdown-item.component'
import { ProjectService } from '#shared/services/project/project.service'

import { PageComponent } from '../page/page.component'
import { reverseRadio, RuleChild, RulePopupComponent, transformStep } from './index'

@Component({
  selector: 'ace-canvas',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatSliderModule,
    FormsModule,
    MatButton,
    RulePopupComponent,
    PageComponent,
    DropdownComponent,
    DropdownItemComponent,
    NumberInputComponent,
    ImageCropperComponent
  ],
  templateUrl: './canvas.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CanvasComponent implements AfterViewInit, OnDestroy {
  /**
   * 当前整个画布的容器，用来监听滚轮事件，进行缩放
   */
  scaleContainerRef = viewChild.required('scaleContainerRef', { read: ElementRef<HTMLDivElement> })

  /**
   * 页面容器
   */
  pageContainerRef = viewChild.required('pageContainerRef', { read: ElementRef<HTMLDivElement> })

  /**
   * 滚动区域，设置滚动条
   */
  scrollContainerRef = viewChild.required('scrollContainerRef', { read: ElementRef<HTMLDivElement> })

  /**
   * 辅助线容器
   */
  guideContainerRef = viewChild.required('guideContainerRef', { read: ElementRef<HTMLDivElement> })

  /**
   * 辅助线列表
   */
  guideLineRefList = viewChildren<ElementRef<HTMLDivElement>>('guideLineRef')

  /**
   * 工具区
   */
  toolContainerRef = viewChild.required('toolContainerRef', { read: ElementRef<HTMLDivElement> })

  /**
   * 画布两边的留白，常量
   */
  readonly pagePadding = [56, 56]

  /**
   * 辅助线类型
   */
  readonly GuideType = GuideType

  /**
   * 订阅管理器
   */
  subscription = new Subscription()

  /**
   * 记录页面初始化后及resize后的实际大小
   */
  canvasSize = signal([0, 0])

  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)

  pageSize = this.uiStore.pageSize
  transformWidth = computed(() => Number((this.pageSize().width * this.uiStore.zoom()).toFixed(2)))
  transformHeight = computed(() => Number((this.pageSize().height * this.uiStore.zoom()).toFixed(2)))
  /**
   * workspace store 存储共享数据
   */
  // workspaceStore = inject(WorkspaceStore)

  /**
   * 是否锁定辅助线 锁定后辅助线无法交互
   */
  readonly guideLocked = this.uiStore.guideLocked

  /**
   * 辅助线列表
   */
  guideLines = this.uiStore.guideLines

  /**
   * 预览辅助线列表
   */
  dryGuideLines = signal<Guide[]>([])

  /**
   * 渲染在dom上的辅助线列表
   */
  guideViewList = computed(() => {
    const guides = this.guideLines()
    const dryGuides = this.dryGuideLines()
    return [...guides, ...dryGuides]
  })

  /**
   * 记录产生滚动条的比例
   */
  scrollRadio = computed(
    () => {
      let [canvasWidth, canvasHeight] = this.canvasSize()
      // 减去当前内部的padding
      const [paddingY, paddingX] = this.pagePadding
      canvasWidth = canvasWidth - 2 * paddingX
      canvasHeight = canvasHeight - 2 * paddingY
      // 计算当前画布的宽高比 并保留2为小数
      const widthRadio = canvasWidth / this.pageSize().width
      const heightRadio = canvasHeight / this.pageSize().height
      return [widthRadio, heightRadio]
    },
    {
      equal: _.isEqual
    }
  )
  /**
   * 计算刻度尺的主刻度跨度
   */
  majorTick = computed(() => {
    const radio = this.uiStore.zoom()
    // 每一个格子的最小渲染宽度为35px 此时刚好可以显示一个4位数字
    const majors = [10, 20, 50, 100, 200, 500]
    return (
      majors.find(item => {
        return radio * item > 35
      }) ||
      _.last(majors) ||
      500
    )
  })

  /**
   * 两个主刻度间的分割数
   */
  minor = computed(() => {
    // 获取当前渲染的实际宽度
    const major = this.majorTick()
    const radio = this.uiStore.zoom()

    // 在合适的step中筛选合适的count，保证小刻度有足够的位置来显示
    const minors = [10, 4]
    // 找到最优的step和count
    return (
      minors.find(item => {
        const width = major * radio
        return item - 1 + item * 3 < width
      }) || 4
    )
  })

  /**
   * 根据宽高的最大值计算出刻度尺数据
   * 横向和竖向刻度尺是一个渲染逻辑，仅需从当前全量数据截取部分数据
   */
  scales = computed(() => {
    const { width, height } = this.pageSize()
    const maxLength = Math.max(width, height)
    const arr: Array<{ label: number; children: RuleChild[] }> = []
    const major = this.majorTick()
    const minor = this.minor()
    const count = Math.ceil(maxLength / major)
    for (let i = 0; i <= count; i++) {
      // 刻度数量 分割线 + 1 = 分割数
      const childrenNum = minor - 1
      arr.push({
        label: i * major,
        children: _.times(childrenNum, index => ({
          label: i * major + (index + 1) * (major / minor),
          isMiddle: index === Math.floor(childrenNum / 2)
        }))
      })
    }
    return arr
  })

  /**
   * 水平刻度尺
   */
  horizontalScales = computed(() => {
    const width = this.pageSize().width
    const scales = this.scales()
    return scales.slice(0, this.getScaleEnd(width))
  })

  /**
   * 垂直刻度尺
   */
  verticalScales = computed(() => {
    const height = this.pageSize().height
    return this.scales().slice(0, this.getScaleEnd(height))
  })
  /**
   * 主刻度尺的宽度
   */
  unitRuleWidth = computed(() => this.majorTick() * this.uiStore.zoom() + 'px')

  /**
   * 右侧主刻度尺偏移量
   */
  topRuleTransform = computed(() => {
    const widthScrollRadio = this.scrollRadio()[0]
    // 计算当前页面在没有放大到可以滚动的情况下 居中距离左侧的距离
    // 当缩放小于临近滚动的时候，需要多余的transform
    let transformX = this.pagePadding[1]
    if (this.uiStore.zoom() < widthScrollRadio) {
      transformX += (this.pageSize().width * (widthScrollRadio - this.uiStore.zoom())) / 2
    }
    return `translate(${transformX}px, 0px)`
  })
  /**
   * 左侧主刻度尺偏移量
   */
  leftRuleTransform = computed(() => {
    const heightScrollRadio = this.scrollRadio()[1]
    // 计算当前页面在没有放大到可以滚动的情况下 居中距离左侧的距离
    // 当缩放小于临近滚动的时候，需要多余的transform
    let transformY = this.pagePadding[0]
    if (this.uiStore.zoom() < heightScrollRadio) {
      transformY += (this.pageSize().height * (heightScrollRadio - this.uiStore.zoom())) / 2
    }
    return `translate(0px, ${transformY}px)`
  })

  /**
   * 画布的padding
   */
  pageContainerPadding = computed(() => `${this.pagePadding[0]}px ${this.pagePadding[1]}px`)

  /**
   * 画布的宽度
   */
  pageOuterStyle = computed(() => {
    const [py, px] = this.pagePadding
    const transformWidth = this.transformWidth()
    const transformHeight = this.transformHeight()
    return { width: `${2 * px + transformWidth}px`, height: `${2 * py + transformHeight}px` }
  })

  /**
   * 刻度尺激活状态
   */
  ruleEnable = this.uiStore.ruleEnable

  /**
   * 刻度尺按钮激活状态
   */
  ruleButtonActive = signal(false)

  isDragging = computed(() => !!this.outerGuide())

  /**
   * 显示在画布外侧的辅助线，用于拖拽时显示或者hover在已生成的辅助线上时显示辅助线的刻度信息
   */
  outerLabel = signal<number>(-1)

  outerGuide = signal<Guide | null>(null)

  /**
   * 显示在画布外侧的辅助线的刻度信息
   * 当辅助线在画布内时，不显示刻度数值
   */
  showOutLabel = computed(() => {
    const outerGuide = this.outerGuide()
    const pageSize = this.pageSize()
    const outerLabel = this.outerLabel()
    return (
      outerGuide &&
      outerLabel >= 0 &&
      ((outerGuide.type === GuideType.horizontal && outerLabel <= pageSize.height) || (outerGuide.type === GuideType.vertical && outerLabel <= pageSize.width))
    )
  })

  /**
   * 记录当前滚动条的位置 用于后续对其辅助线的位置进行计算
   */
  currentScrollLeft = signal(0)

  currentScrollTop = signal(0)

  /**
   * 进入全屏
   */
  enterFullScreen = signal(false)

  /**
   * 画布的缩放是否基于画布中间位置
   * 滑块是否处于拖拽状态，在未拖拽时 值的改变不会触发画布的缩放
   */
  isCenterFocus = signal(false)

  /**
   * scrollContainerRef大小变化
   */
  scrollContainerResize = signal({ x: 0, y: 0, width: 0, height: 0, right: 0, bottom: 0 })
  scrollContainerResize$ = toObservable(this.scrollContainerResize)

  /**
   * 画布距离页面左边和上边的距离
   */
  canvasBounding = computed(() => {
    const ruleSize = this.ruleEnable() ? this.ruleSize : 0
    return {
      left: this.scrollContainerResize().x + ruleSize,
      top: this.scrollContainerResize().y + ruleSize
    }
  })

  marqueeState = signalState<{
    show: boolean
    rect: (ISize & IPosition) | null
  }>({
    show: false,
    rect: null
  })

  marqueeSelectArea = computed<{ top: number; left: number; right: number; bottom: number } | null>(() => {
    const { x: pageLeft, y: pageTop } = untracked(this.uiStore.pageOffset)
    // const { left: canvasLeft, top: canvasTop } = untracked(this.canvasContainerRef).nativeElement.getBoundingClientRect()
    // const offsetLeft = pageLeft - canvasLeft
    // const offsetTop = pageTop - canvasTop
    const rect = this.marqueeState.rect()
    if (!rect) {
      return null
    }
    const { x, y, width, height } = rect
    return {
      top: y - pageTop + this.canvasBounding().top,
      left: x - pageLeft + this.canvasBounding().left,
      right: x + width - pageLeft + this.canvasBounding().left,
      bottom: y + height - pageTop + this.canvasBounding().top
    }
  })

  /**
   * 初始化画布时最适合当前屏幕的缩放比例
   */
  initialRadio = computed(() => {
    const radio = this.scrollRadio()
    // console.log('InitialRadio Computed', radio)
    return Number(Math.min(...radio).toFixed(4))
  })

  cursor = computed(() => {
    if (this.uiStore.isMovingElement() === ChangeAction.Page) {
      return 'move'
    } else if (this.uiStore.isRotatingElement() === ChangeAction.Page) {
      const cursorRotation = Math.floor(((this.uiStore.interacting.shadowData.rotation() as number) + 180) / 10) * 10
      const rotateSvg = ROTATION_CURSOR_SVG.replace('$rotation', (cursorRotation + 45).toString())
      return `url("data:image/svg+xml,${rotateSvg}") 16 16, pointer`
    } else if (this.uiStore.isResizingElement() === ChangeAction.Page) {
      const resizingElement = this.uiStore.interactingElement()
      const resizing = this.uiStore.interacting.resizing()
      if (resizingElement) {
        let startRotation = 0
        switch (resizing.handler) {
          case 'n':
          case 's':
            startRotation = 90
            break
          case 'w':
          case 'e':
            startRotation = 0
            break
          case 'nw':
          case 'se':
            startRotation = 45
            break
          case 'ne':
          case 'sw':
            startRotation = -45
            break
        }
        const resizeSvg = RESIZE_CURSOR_SVG.replace('$rotation', (resizingElement.rotation + startRotation).toString())
        return `url("data:image/svg+xml,${resizeSvg}") 16 16, pointer`
      }
    } else if (this.uiStore.isScalingElement() === ChangeAction.Page) {
      const scalingElement = this.uiStore.interactingElement()
      const scaling = this.uiStore.interacting.scaling()
      if (scalingElement) {
        let startRotation = scalingElement.rotation
        switch (scaling.handler) {
          case 'nw':
          case 'se':
            startRotation += 45
            break
          case 'ne':
          case 'sw':
            startRotation += -45
            break
        }
        const scaleSvg = RESIZE_CURSOR_SVG.replace('$rotation', startRotation.toString())
        return `url("data:image/svg+xml,${scaleSvg}") 16 16, pointer`
      }
    }
    return 'default'
  })

  /**
   * 预设页面缩放选项
   */
  zoomOptions = [10, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500]

  currentSelectPosition = computed(
    () => {
      const selectedElements = this.uiStore.selectedElements()
      const baseElementRect = this._workspaceService.baseElementBoundings()
      if (selectedElements.length === 0) {
        return
      } else {
        return {
          startX: baseElementRect.left,
          startY: baseElementRect.top,
          endX: baseElementRect.right,
          endY: baseElementRect.bottom
        }
      }
    },
    {
      equal: isEqual
    }
  )

  currentRealPosition = computed(
    () => {
      const zoom = this.uiStore.zoom()
      const currentSelectPosition = this.currentSelectPosition()
      if (!currentSelectPosition) return
      const { startX, startY, endX, endY } = currentSelectPosition
      return {
        startX: startX * zoom,
        startY: startY * zoom,
        endX: endX * zoom,
        endY: endY * zoom
      }
    },
    {
      equal: isEqual
    }
  )

  /**
   * 锁定刻度尺的toast提示
   */
  guideLockedToastRef = viewChild.required<TemplateRef<any>>('guideLockedToastRef')

  cropImageId = computed(() => this._workspaceService.cropImageElementId())
  showMask = computed(() => !!this.cropImageId())
  private _overlay = inject(Overlay)
  private _currentStep = 1
  private readonly ruleSize = 25
  private _rulePopupOverlayRef: OverlayRef | undefined
  private _injector = inject(Injector)
  private _viewContainerRef = inject(ViewContainerRef)
  private _resizeObserver = new ResizeObserver(() => {
    const bounding = (this.scrollContainerRef().nativeElement as HTMLDivElement).getBoundingClientRect()
    this.scrollContainerResize.set({
      x: bounding.x,
      y: bounding.y,
      width: bounding.width,
      height: bounding.height,
      bottom: bounding.bottom,
      right: bounding.right
    })
  })
  private _toastService = inject(HotToastService)
  private _workspaceService = inject(WorkspaceService)

  constructor() {
    effect(() => {
      const ruleEnable = this.ruleEnable()
      /**
       * 当刻度尺开启时，需要在下一个tick中获取画布的大小，因为画布的大小是在下一个tick中才能获取到
       */
      if (ruleEnable) {
        console.log('Rule Enable Effect')
        timer(0).subscribe(() => {
          this.getCanvasSize()
          this.changeRatio(this.currentStep)
        })
      }
    })

    /*
      当项目的尺寸发生变化时初始的缩放比例会发生变化，此时需要更新当前画布的缩放比
     */
    toObservable(this.initialRadio)
      .pipe(takeUntilDestroyed())
      .subscribe(value => {
        this.zoomToRadio(value)
      })

    // fromEvent(document, 'mouseup')
    //   .pipe(takeUntilDestroyed())
    //   .subscribe(() => {
    //     if (this.uiStore.interacting.id() && this.uiStore.isElementReadyInteractionOnPage()) {
    //       this.uiStore.resetInteractingElement()
    //     }
    //   })
    toObservable(this.uiStore.isElementReadyInteractionOnPage)
      .pipe(
        takeUntilDestroyed(),
        filter(ready => ready)
      )
      .subscribe(() => {
        // 裁剪图片时不交互
        if (this._workspaceService.cropImageElementId()) return
        this.handlePageElementInteraction()
      })

    toObservable(this.uiStore.zoom)
      .pipe(debounceTime(50), distinctUntilChanged())
      .pipe(combineLatestWith(toObservable(this.pageSize).pipe(distinctUntilChanged())))
      .pipe(combineLatestWith(toObservable(this.canvasBounding).pipe(distinctUntilChanged())))
      .pipe(debounceTime(1)) // 保证page组件重绘后再计算位置
      .subscribe(() => {
        const top = this.pageContainerRef().nativeElement.offsetTop + this.pagePadding[0] + this.canvasBounding().top
        const left = this.pageContainerRef().nativeElement.offsetLeft + this.pagePadding[1] + this.canvasBounding().left
        this.uiStore.setPageOffset({ x: left, y: top })
      })

    // 画布中通过右键菜单新增辅助线
    this._workspaceService.createGuides$.pipe(takeUntilDestroyed()).subscribe(({ type, point }) => {
      const containerRect = this.guideContainerRef().nativeElement.getBoundingClientRect()
      // 此时的offsetY就是画布上的实际位置，根据当前比率来计算当前实际的对应刻度尺的刻度值
      const guidePosition = type === GuideType.vertical ? point.x - containerRect.left : point.y - containerRect.top
      const position = Math.round(guidePosition / this.uiStore.zoom())
      this.uiStore.setGuides(
        produce(this.guideLines(), draft => {
          draft.push({
            id: String(Math.random().toString(36).slice(2)),
            type,
            position,
            hidden: false
          })
        })
      )
    })
  }

  get currentStep() {
    return this._currentStep
  }

  set currentStep(val: number) {
    this._currentStep = val
    this.handleSliderChange(val)
  }

  ngAfterViewInit(): void {
    // 当组件尺寸变化时需要重新计算相关尺寸数据
    this._resizeObserver.observe(this.scrollContainerRef().nativeElement)

    // 获取当前画布大小
    this.getCanvasSize()

    // 监听窗口大小变化
    this.subscription.add(
      this.scrollContainerResize$.subscribe(() => {
        this.getCanvasSize()
      })
    )
    // 监听滚动事件
    this.subscription.add(
      fromEvent(this.scrollContainerRef().nativeElement, 'scroll').subscribe(() => {
        const { scrollLeft, scrollTop } = this.scrollContainerRef().nativeElement
        this.currentScrollLeft.set(scrollLeft)
        this.currentScrollTop.set(scrollTop)
      })
    )

    this.subscription.add(
      // 点击页面时，重置选中状态
      customClick({ element: this.scrollContainerRef().nativeElement }).subscribe(() => {
        if (this._workspaceService.cropImageElementId()) {
          this._workspaceService.cropImageElementId.set(undefined)
        } else {
          this.uiStore.resetSelection('background')
        }
      })
    )
  }

  ngOnDestroy(): void {
    this._resizeObserver.disconnect()
    this.subscription.unsubscribe()
    this._rulePopupOverlayRef?.dispose()
  }

  zoomToRadio(value: number) {
    this.uiStore.setPageZoom(value)
    this.isCenterFocus.set(true)
    this.currentStep = reverseRadio(value)
    requestAnimationFrame(() => {
      this.isCenterFocus.set(false)
    })
  }

  /**
   * 监听滚轮事件进行缩放
   * @param $event
   */
  handleWheel($event: WheelEvent) {
    // 需要按住meta键才能进行缩放
    // 在触控板上 ctrlKey会在双指缩放时变为true，所以只要ctrlkey为true那么就可以进行缩放。而在mac上不仅可以ctrl也可以使用meta进行组合键缩放
    if ((isMac() && $event.metaKey) || $event.ctrlKey) {
      // 监听鼠标的滚轮时间然后进行缩放
      $event.preventDefault()
      const { deltaY } = $event
      // 获取deltaY的符号，往上滚动放大，下滚动缩小
      // 0.1是最小值 5是最大值
      const delta = deltaY < 0 ? 1 : deltaY > 0 ? -1 : 0
      // 根据上下方向对step进行加减 范围控制在1和100 [1, 200]，在[1, 100]时 返回的倍率是[0.1, 1]，从101开始使用二次二次函数进行递增，[101, 200] -> [1, 5]
      this.currentStep += delta
      this.currentStep = Math.min(Math.max(this.currentStep, 0), 49)
      // 如果存在事件，并且鼠标在画布上时，根据当前事件中的offsetX和offsetY来计算滚动后的偏移值，然后改变滚动条的位置使滚动前鼠标所在的位置在滚动后仍然在鼠标所在的位置
      const { clientX, clientY } = $event
      const { scrollTop, scrollLeft } = this.scrollContainerRef().nativeElement
      const { left: clientLeft, top: clientTop } = this.scrollContainerRef().nativeElement.getBoundingClientRect()
      // console.log('Canvas Scroll Container', clientLeft, scrollLeft, this.scrollContainerRef().nativeElement)
      this.changeRatio(this.currentStep, {
        x: clientX - clientLeft + scrollLeft - 25 - this.pagePadding[0],
        y: clientY - clientTop + scrollTop - this.ruleSize - this.pagePadding[1]
      })
    }
  }

  /**
   * Slider滑块的发生变化时
   */
  handleSliderChange(val: number) {
    const isCenterFocus = this.isCenterFocus()
    if (isCenterFocus) {
      // 根据当前container中心点进行缩放
      const { offsetWidth, offsetHeight } = this.scaleContainerRef().nativeElement
      // 根据当前画布的中心点进行缩放
      this.changeRatio(val, { x: offsetWidth / 2, y: offsetHeight / 2 })
    }
  }

  /**
   * 切换刻度尺的显示状态
   */
  handleRuleTool() {
    if (!this._rulePopupOverlayRef) {
      this._rulePopupOverlayRef = this._overlay.create({
        positionStrategy: this._overlay
          .position()
          .flexibleConnectedTo(this.toolContainerRef().nativeElement)
          .withPositions([
            {
              originX: 'start',
              originY: 'top',
              overlayX: 'start',
              overlayY: 'bottom',
              offsetY: -10
            }
          ])
      })
      this._rulePopupOverlayRef.outsidePointerEvents().subscribe(event => {
        event.stopPropagation()
        this._rulePopupOverlayRef?.detach()
        this.ruleButtonActive.set(false)
      })
    }
    // 激活按钮状态
    this.ruleButtonActive.set(true)
    // 在当前toolContainerRef上创建一个overlay
    const clearGuides = () => {
      this.uiStore.setGuides([])
      this.uiStore.setGuideLocked(false)
    }
    const ref = this._rulePopupOverlayRef.attach(new ComponentPortal(RulePopupComponent, this._viewContainerRef, this._injector))
    ref.instance.aceRulePopupCreate.subscribe(({ column, row, dry }) => {
      // 清除当前创建的辅助线
      clearGuides()
      this.createBatchGuides(column, row, dry)
    })
    ref.instance.aceRulePopupClear.subscribe(() => {
      clearGuides()
    })
  }

  /**
   * 创建水平辅助线
   */
  handleCreateGuide(type: GuideType) {
    // 停止订阅的事件
    const stop$ = fromEvent(document, 'mouseup', {
      capture: true
    })
    const currentLocked = this.guideLocked()

    // 临时锁定辅助线的交互
    if (!currentLocked) {
      this.uiStore.setGuideLocked(true)
      // 创建辅助线时 阻止其余的辅助线产生交互
      stop$.pipe(take(1)).subscribe(e => {
        e.stopPropagation()
        this.uiStore.setGuideLocked(false)
      })
    }

    let guidePosition = 0
    const scrollContainerEl = this.scrollContainerRef().nativeElement
    const { left, top } = scrollContainerEl.getBoundingClientRect()
    // 在鼠标所在位置创建一个新的辅助线
    const guide = {
      id: String(Math.random().toString(36).slice(2)),
      type: type,
      position: 0
    }

    // 鼠标抬起后 如果辅助线在当前画布中那么保存下来
    stop$.pipe(take(1)).subscribe(() => {
      if (this.showOutLabel()) {
        this.uiStore.setGuides(
          produce(this.guideLines(), draft => {
            draft.push({
              ...guide,
              // 此时的outerLabel显示的值就是innerGuide相对于guideContainerRef的相对位置
              position: this.outerLabel(),
              hidden: false
            })
          })
        )
      }
      // 重置状态
      this.resetOuterGuide()
    })

    // 监听整个dom的移动事件，为了移动outerGuide
    fromEvent(document, 'mousemove')
      .pipe(takeUntil(stop$))
      .subscribe(event => {
        const { clientY, clientX } = event as MouseEvent
        if (type === GuideType.horizontal) {
          this.outerGuide.set({ ...guide, position: clientY - top + this.currentScrollTop() })
        } else {
          this.outerGuide.set({ ...guide, position: clientX - left + this.currentScrollLeft() })
        }
      })
    // 如果移出了画布则停止创建
    fromEvent(document, 'mouseleave')
      .pipe(takeUntil(stop$))
      .subscribe(() => {
        this.resetOuterGuide()
      })

    const containerRect = this.guideContainerRef().nativeElement.getBoundingClientRect()

    // 当前画布上的监听，此时事件中的offset及当前刻度尺的刻度，但是需要根据当前比率来计算实际值
    fromEvent(document, 'mousemove', { capture: true })
      .pipe(takeUntil(stop$))
      .subscribe(event => {
        const { clientX, clientY } = event as MouseEvent
        // console.log(containerRect, clientX, clientY)
        if ((type === GuideType.vertical && clientX - containerRect.left > 0) || (type === GuideType.horizontal && clientY - containerRect.top > 0)) {
          // 此时的offsetY就是画布上的实际位置，根据当前比率来计算当前实际的对应刻度尺的刻度值
          guidePosition = type === GuideType.vertical ? clientX - containerRect.left : clientY - containerRect.top
          this.outerLabel.set(Math.round(guidePosition / this.uiStore.zoom()))
        } else {
          this.outerLabel.set(-1)
        }
      })
  }

  /**
   * 获取画布外延伸到刻度尺上的辅助线样式
   * @param guide
   */
  getOuterGuideStyle(guide: Guide) {
    const { type, position } = guide
    return type === GuideType.horizontal
      ? {
          top: position + 'px',
          left: this.currentScrollLeft() + 'px'
        }
      : {
          left: position + 'px',
          top: this.currentScrollTop() + 'px'
        }
  }

  /**
   * 获取当前的辅助线的位置
   * @param guide
   */
  getGuideStyle(guide: Guide) {
    const { type, position } = guide
    const radio = this.uiStore.zoom()
    // console.log(type, position)
    return type === GuideType.horizontal
      ? {
          top: position * radio + 'px',
          left: 0
        }
      : {
          left: position * radio + 'px',
          top: 0
        }
  }

  /**
   * 移动当前辅助线
   * @param $event
   * @param index
   */
  handleReplaceGuide($event: Event, index: number) {
    $event.stopPropagation()
    // 找到指定的辅助线并删除
    const guides = this.guideLines()
    const type = guides[index].type
    this.uiStore.setGuides(
      produce(guides, draft => {
        draft.splice(index, 1)
      })
    )
    // 手动出发生成辅助线的事件
    this.handleCreateGuide(type)
  }

  /**
   * 鼠标放置辅助线上时显示label及画布外的辅助线
   * @param index
   */
  handleViewGuide(index: number) {
    // 隐藏当前guide 并显示outerGuide
    const guide = this.guideLines()[index]
    // 已经显示则不再处理
    if (guide.hidden) return

    // req.subscribe(() => {
    // 计算当前辅助线的位置
    const line = this.guideLineRefList()[index]
    if (line) {
      const scrollContainerEl = this.scrollContainerRef().nativeElement
      // 获取当前容器相对于整个页面的相对位置
      const { left, top } = scrollContainerEl.getBoundingClientRect()
      // 获取当前辅助线的位置
      const { top: clientY, left: clientX } = line.nativeElement.getBoundingClientRect()
      // 隐藏当前guide 并显示outerGuide
      this.uiStore.setGuides(
        produce(this.guideLines(), draft => {
          draft[index].hidden = true
        })
      )
      // 设置当前的label值
      this.outerLabel.set(Math.round(guide.position))
      // button 区域为了让响应事件的区间更大增加了padding 计算时需要进行修正
      // 放大后会出现滚动条 此时需要加上滚动的距离来修正
      const position = guide.type === 'horizontal' ? clientY - top + this.currentScrollTop() : clientX - left + this.currentScrollLeft()
      this.outerGuide.set({
        ...guide,
        position: position
      })
    }
    // })
  }

  /**
   * 鼠标移出辅助线时隐藏label及画布外的辅助线
   * @param index
   */
  handleHideGuide(index: number) {
    // 隐藏当前guide 并显示outerGuide
    this.resetOuterGuide()
    this.uiStore.setGuides(
      produce(this.guideLines(), draft => {
        draft[index].hidden = false
      })
    )
  }

  /**
   * 关闭toast并解锁辅助线
   * @param toastRef
   */
  handleToastUnLock(toastRef: HotToastRef) {
    toastRef.close()
    this.handleLockGuide(false)
  }

  /**
   * 处理框选事件
   * @param $event
   */
  handleMarqueeSelect($event: MouseEvent) {
    if ($event.button !== 0) return
    const stop$ = fromEvent(document, 'mouseup', {
      capture: true,
      once: true
    })
    const { clientX, clientY } = $event
    const { left, top } = this.canvasBounding()

    const startX = clientX - left + this.scrollContainerRef().nativeElement.scrollLeft
    const startY = clientY - top + this.scrollContainerRef().nativeElement.scrollTop
    // patchState(this.marqueeState, {
    //   show: true,
    //   rect: null
    // })

    fromEvent(document, 'mousemove', {
      capture: true
    })
      .pipe(
        map(evt => evt as MouseEvent),
        distinctUntilChanged((evt1, evt2) => evt1.clientX === evt2.clientX && evt1.clientY === evt2.clientY),
        takeUntil(
          stop$.pipe(
            tap(() =>
              patchState(this.marqueeState, {
                show: false,
                rect: null
              })
            )
          )
        )
      )
      .subscribe(event => {
        if (this._workspaceService.cropImageElementId()) return
        event.stopPropagation()
        let { clientX: x, clientY: y } = event

        x = x - left + this.scrollContainerRef().nativeElement.scrollLeft
        y = y - top + this.scrollContainerRef().nativeElement.scrollTop
        const deltaX = x - startX
        const deltaY = y - startY
        patchState(this.marqueeState, {
          show: true,
          rect: {
            x: deltaX > 0 ? startX : startX + deltaX,
            y: deltaY > 0 ? startY : startY + deltaY,
            width: Math.abs(deltaX),
            height: Math.abs(deltaY)
          }
        })
      })
  }

  handleDropDownChange($event: DropDownChange) {
    this.handleRadioInputChange(this.zoomOptions[$event.index])
  }

  handleRadioInputChange($event: number | undefined) {
    if ($event !== undefined) {
      // 根据当前container中心点进行缩放
      const { offsetWidth, offsetHeight } = this.scaleContainerRef().nativeElement
      const step = reverseRadio($event / 100)
      this.currentStep = step
      // 根据当前画布的中心点进行缩放
      this.changeRatio(step, { x: offsetWidth / 2, y: offsetHeight / 2 })
    }
  }

  /**
   * 切换锁定状态并给出提示
   * @param state
   */
  handleLockGuide(state: boolean) {
    this.uiStore.setGuideLocked(state)
    if (state) {
      this._toastService.show(this.guideLockedToastRef(), { duration: 5000 })
    } else {
      this._toastService.info('辅助线已解锁')
    }
  }

  private handlePageElementInteraction() {
    const mouseUp$ = fromEvent(document, 'mouseup', {
      capture: true
    }).pipe(
      tap(() => {
        if (this.uiStore.interacting.id()) {
          // $event.stopPropagation()
          // timer(1).subscribe(() => {
          this.uiStore.resetInteractingElement()
          // })
        }
      }),
      take(1)
    )
    fromEvent<MouseEvent>(document, 'mousemove')
      .pipe(takeUntil(mouseUp$))
      .subscribe(event => {
        const id = this.uiStore.interacting.id()
        const { x: left, y: top } = this.uiStore.pageOffset()
        const { clientY, clientX } = event
        if (this.uiStore.isMovingOnPageReady()) {
          this.uiStore.setDraggingElement(id, {
            position: {
              x: (clientX + this.currentScrollLeft() - left) / this.uiStore.zoom(),
              y: (clientY + this.currentScrollTop() - top) / this.uiStore.zoom()
            }
          })
        } else if (this.uiStore.isRotatingOnPageReady()) {
          this.uiStore.setRotatingElement(id, {
            endPosition: {
              x: clientX + this.currentScrollLeft() - left,
              y: clientY + this.currentScrollTop() - top
            }
          })
        } else if (this.uiStore.isScalingOnPageReady()) {
          this.uiStore.setScalingElement(id, {
            position: {
              x: (clientX + this.currentScrollLeft() - left) / this.uiStore.zoom(),
              y: (clientY + this.currentScrollTop() - top) / this.uiStore.zoom()
            }
          })
        } else if (this.uiStore.isResizingOnPageReady()) {
          this.uiStore.setResizingElement(id, {
            end: {
              x: (clientX + this.currentScrollLeft() - left) / this.uiStore.zoom(),
              y: (clientY + this.currentScrollTop() - top) / this.uiStore.zoom()
            }
          })
        }
      })
  }

  /**
   * 截取指定长度的刻度尺
   * @param length
   * @private
   */
  private getScaleEnd(length: number) {
    const scales = this.scales()
    const index = scales.findIndex(({ label }) => label >= length)
    return scales[index].label === length ? index + 1 : index
  }

  /**
   * 根据当前整个页面的大小来计算canvas的大小
   * @private
   */
  private getCanvasSize() {
    const element = this.scrollContainerRef().nativeElement
    const { width, height } = element.getBoundingClientRect()
    const scrollBarWidth = element.offsetWidth - element.clientWidth
    const scrollBarHeight = element.offsetHeight - element.clientHeight
    if (this.ruleEnable()) {
      this.canvasSize.set([width - this.ruleSize - scrollBarWidth, height - this.ruleSize - scrollBarHeight])
    } else {
      this.canvasSize.set([width - scrollBarWidth, height - scrollBarHeight])
    }
  }

  /**
   * 根据当前step计算ratio
   * @param step
   * @param point 当前滚动点相对画布的位置
   * @private
   */
  private changeRatio(step: number, point?: { x: number; y: number }) {
    const currentRadio = this.uiStore.zoom()
    // console.log(currentRadio, step)
    const ratio = transformStep(step)
    // 判断当前比例的最大最小值并计算差值
    const afterRatio = Number(Math.min(Math.max(ratio, 0.1), 5).toFixed(4))

    if (afterRatio === currentRadio) return

    if (point) {
      const { x: layerX, y: layerY } = point
      // const [blankY, blankX] = this.pagePadding
      const diffRatio = afterRatio - this.uiStore.zoom()
      // const ruleWidth = this.enableRule() ? 25 : 0
      // 计算放大后该点的位置和现在位置间的差值，改变滚动条使其不变
      // 计算时需注意当前画布的大小和实际大小的比例，diff应该按照最原始的位置关系来计算
      const diffX = (layerX / currentRadio) * diffRatio
      const diffY = (layerY / currentRadio) * diffRatio
      // 将滚动放在下一个事件循环中，避免滚动条的位置计算错误
      requestAnimationFrame(() => {
        this.scrollContainerRef().nativeElement.scrollBy(diffX, diffY)
      })
    }
    // 产生滚动条后计算滚动条的位置
    this.uiStore.setPageZoom(afterRatio)
    // this.workspaceStore.setRatio(afterRatio)
  }

  /**
   * 重置outerGuide
   */
  private resetOuterGuide() {
    this.outerGuide.set(null)
    this.outerLabel.set(-1)
  }

  /**
   * 批量创建预设辅助线
   * @param column 画布分割列数
   * @param row 画布分割行数
   * @param dry
   * @private
   */
  private createBatchGuides(column: number, row: number, dry: boolean) {
    // 默认会开启刻度尺
    this.uiStore.setRuleEnable(true)
    requestAnimationFrame(() => {
      // 重置预览状态
      this.dryGuideLines.set([])
      // 获取当前画布的实际大小
      const { width, height } = this.pageSize()
      // const { width, height } = this.workspaceStore.page()
      // 计算每一列的宽度和每一行的高度
      const eachWidth = width / column
      const eachHeight = height / row

      // 用于存储创建的辅助线
      const guides: Guide[] = []

      // column - 1 表示需要创建的垂直辅助线数 3列仅需2条辅助线
      for (let i = 0; i < column - 1; i++) {
        guides.push({
          id: Math.random().toString(16).slice(2),
          type: GuideType.vertical,
          position: (i + 1) * eachWidth,
          hidden: false
        })
      }

      // row - 1 表示需要创建的水平辅助线数 3行仅需2条辅助线
      for (let i = 0; i < row - 1; i++) {
        guides.push({
          id: Math.random().toString(16).slice(2),
          type: GuideType.horizontal,
          position: (i + 1) * eachHeight,
          hidden: false
        })
      }
      // 将创建的辅助线存储到store中
      if (dry) {
        // 保存到dryGuides中
        this.dryGuideLines.set(guides)
      } else {
        // 默认锁定当前辅助线
        this.handleLockGuide(true)
        // 关闭弹窗
        this._rulePopupOverlayRef?.detach()
        // 保存到guides中
        const guideList = this.guideLines()
        this.uiStore.setGuides(_.cloneDeep([...guideList, ...guides]))
      }
    })
  }
}
