import { NgComponentOutlet } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  effect,
  ElementRef,
  HostBinding,
  inject,
  input,
  output,
  signal,
  Type,
  viewChild,
  ViewContainerRef
} from '@angular/core'

import { patchState, signalState } from '@ngrx/signals'
import _ from 'lodash'

import { boundingRect, IPosition, rectVertices, scaleSize } from '@libs/algorithm'

import { ElementBaseComponent } from '#modules/workspace/components/element/element-base.component'
import { ElementViewComponent } from '#modules/workspace/components/element/element-view/element-view.component'
import { ElementGroupComponent } from '#modules/workspace/components/element/group/element-group.component'
import { ChildElementTreeNode, GroupElementTreeNode, PageElementTreeNode } from '#modules/workspace/models/element-node'
import { ElementTypeEnum } from '#modules/workspace/types/constants'
import { AtomType, ElementType, IChildElement, IPageElementSetting, ISetting, IShadowElement, ISize } from '#modules/workspace/types/element'

@Component({
  selector: 'ace-element-container',
  standalone: true,
  imports: [NgComponentOutlet],
  templateUrl: './element-container.component.html',
  styleUrl: './element-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementContainerComponent implements AfterViewInit {
  standalone = input<boolean>(false)
  preview = input<boolean>(false)
  previewScale = input<number>(1)
  locked = input<boolean>(false)

  data = input.required({
    transform: (value: PageElementTreeNode) => {
      if (value.category === ElementTypeEnum.Group) {
        return value as GroupElementTreeNode
      } else {
        return value
      }
    }
  })
  interactionState = input<IShadowElement, IShadowElement | undefined>(
    {
      position: {},
      size: {},
      scale: undefined,
      rotation: undefined,
      setting: {}
    },
    {
      transform: value => {
        if (value) {
          if (!_.isEmpty(value.position)) {
            patchState(this.interaction, { position: value.position })
          }
          if (!_.isEmpty(value.size)) {
            patchState(this.interaction, { size: value.size })
          }
          if (!_.isUndefined(value.scale)) {
            patchState(this.interaction, { scale: value.scale })
          }
          if (!_.isUndefined(value.rotation)) {
            patchState(this.interaction, { rotation: value.rotation })
          }
          if (!_.isEmpty(value.setting)) {
            patchState(this.interaction, { setting: value.setting })
          }

          return value
        } else {
          const state = {
            position: {},
            size: {},
            scale: undefined,
            rotation: undefined,
            setting: {}
          }
          patchState(this.interaction, state)
          return state
        }
      }
    }
  )
  dragStart = output<{ id: string; $event: { clientX: number; clientY: number }; parent?: string }>()

  elementRef = inject(ElementRef)
  interaction = signalState<IShadowElement>({
    position: {},
    size: {},
    scale: undefined,
    rotation: undefined,
    setting: {}
  })

  isGroup = computed(() => {
    return this.data().category === ElementTypeEnum.Group
  })

  children = computed<IChildElement<AtomType>[]>(() => {
    if (this.isGroup()) {
      const elements = (this.data().children as ChildElementTreeNode[]) || []
      return elements.map(el => {
        return el.data as IChildElement<AtomType>
      })
    } else {
      return []
    }
  })

  position = computed<IPosition>(
    () => {
      if (this.standalone()) {
        return { x: 0, y: 0 }
      } else if (!_.isEmpty(this.interaction.position())) {
        return this.interaction.position() as IPosition
      } else {
        return this.data().position
      }
    },
    {
      equal: (a: IPosition, b: IPosition) => a.x === b.x && a.y === b.y
    }
  )

  left = computed(() => {
    // 如果组元素的子元素正在编辑，组元素的位置应该根据子元素的位置计算
    // const childInteraction = this.childrenInteraction()
    // if (!_.isEmpty(childInteraction)) {
    //   return this.boundingChildrenRect().x
    // }
    return this.position().x
  })
  top = computed(() => {
    // 如果组元素的子元素正在编辑，组元素的位置应该根据子元素的位置计算
    // const childInteraction = this.childrenInteraction()
    // if (!_.isEmpty(childInteraction)) {
    //   return this.boundingChildrenRect().y
    // }
    return this.position().y
  })

  scale = computed(() => {
    if (!_.isUndefined(this.interaction.scale())) {
      return this.interaction.scale() as number
    } else {
      return this.data().scale
    }
  })

  size = computed(
    () => {
      if (!_.isEmpty(this.interaction.size())) {
        return this.interaction.size() as ISize
      } else {
        // if (this.isGroup()) {
        //   // 如果组元素的子元素正在编辑，组元素的宽度应该根据子元素的宽度计算
        //   const childInteraction = this.childrenInteraction()
        //   if (!_.isEmpty(childInteraction)) {
        //     return {
        //       width: this.boundingChildrenRect().width,
        //       height: this.boundingChildrenRect().height
        //     }
        //   }
        // }
        return this.data().size
      }
    },
    {
      equal: (a, b) => a.width === b.width && a.height === b.height
    }
  )

  setting = computed<IPageElementSetting[AtomType]>(
    () => {
      const setting = this.interaction.setting()
      if (!_.isEmpty(setting)) {
        return this.interaction.setting() as IPageElementSetting[AtomType]
      } else {
        return this.data().setting as IPageElementSetting[AtomType]
      }
    },
    {
      equal: (a: ISetting, b: ISetting) => a.version === b.version
    }
  )

  rotation = computed(() => {
    if (!_.isUndefined(this.interaction.rotation())) {
      return (this.interaction.rotation() as number) + 'deg'
    } else {
      return this.data().rotation + 'deg'
    }
  })

  rotateRad = computed(() => {
    return (this.data().rotation * Math.PI) / 180
  })

  vertices = computed(() => {
    return rectVertices(this.position(), scaleSize(this.size(), this.scale()), this.rotateRad())
  })

  boundings = computed(() => {
    return boundingRect(this.position(), scaleSize(this.size(), this.scale()), this.data().rotation)
  })

  elementComponenetRef: ComponentRef<ElementBaseComponent<AtomType> | ElementGroupComponent> | null = null
  elementComponent = signal<ElementGroupComponent | ElementBaseComponent<AtomType> | null>(null)

  groupElement = viewChild('groupElement', { read: ViewContainerRef })
  atomElement = viewChild('atomElement', { read: ViewContainerRef })

  rendered = output<boolean>()

  elementViewCmp: Type<ElementViewComponent<ElementType>> | null = null
  private cdr = inject(ChangeDetectorRef)

  constructor() {
    effect(() => {
      if (this.elementComponent()) {
        const componentRef = this.elementComponenetRef as ComponentRef<ElementBaseComponent<AtomType> | ElementGroupComponent>
        if (componentRef.componentType === ElementGroupComponent) {
          componentRef.setInput('id', this.data().id)
          componentRef.setInput('size', this.size())
          componentRef.setInput('scale', this.scale())
          componentRef.setInput('children', this.children())
          componentRef.setInput('locked', this.locked())
        } else {
          componentRef.setInput('id', this.data().id)
          componentRef.setInput('category', this.atomCategory)
          componentRef.setInput('setting', this.setting())
          componentRef.setInput('size', this.size())
          componentRef.setInput('preview', false)
          componentRef.setInput('scale', this.scale())
          componentRef.setInput('locked', this.locked())
          componentRef.setInput('position', this.position())
        }
      }
    })
  }

  @HostBinding('style.transform') get transform() {
    return `translate(${this.left()}px, ${this.top()}px)  rotate(${this.rotation()})`
  }

  @HostBinding('style.display') get display() {
    return this.data().visible ? 'block' : 'none'
  }
  @HostBinding('style.width.px') get width() {
    const scale = this.standalone() ? this.previewScale() : this.scale()
    return this.size().width * scale
  }
  @HostBinding('style.height.px') get height() {
    const scale = this.standalone() ? this.previewScale() : this.scale()
    return this.size().height * scale
  }

  @HostBinding('style.position') get stylePosition() {
    return 'absolute'
  }

  get atomCategory() {
    if (this.isGroup()) {
      throw new Error('Group element does not have atom category')
    } else {
      return this.data().category as AtomType
    }
  }

  get boundingClientRect() {
    return (this.elementRef.nativeElement as HTMLElement).getBoundingClientRect()
  }

  async createElement() {
    if (!this.preview()) {
      if (this.isGroup()) {
        const cmp = await import('#modules/workspace/components/element/group/element-group.component')
        this.elementComponenetRef = (this.groupElement() as ViewContainerRef).createComponent(cmp.ElementGroupComponent)
        this.elementComponenetRef.instance.dragStart.subscribe(event => {
          this.startDrag(event)
        })
        this.elementComponent.set(this.elementComponenetRef.instance)
        // this.cdr.detectChanges()
      } else {
        const cmp = await import('#modules/workspace/components/element/element-base.component')
        this.elementComponenetRef = (this.atomElement() as ViewContainerRef).createComponent(cmp.ElementBaseComponent)
        this.elementComponenetRef.instance.dragStart.subscribe(event => {
          this.startDrag(event)
        })
        this.elementComponent.set(this.elementComponenetRef.instance)
        // this.cdr.detectChanges()
      }
    } else {
      const cmp = await import('#modules/workspace/components/element/element-view/element-view.component')
      this.elementViewCmp = cmp.ElementViewComponent
      this.cdr.detectChanges()
    }
  }

  ngAfterViewInit(): void {
    this.createElement()
    this.rendered.emit(true)
  }

  protected startDrag(params: { $event: { clientX: number; clientY: number }; id: string; parent?: string }) {
    this.dragStart.emit(params)
  }
}
