<div
  class="relative rounded-lg hover:shadow"
  (mouseenter)="showControls()"
  (mouseleave)="hideControls()"
  [class.item-selected]="item().selected"
  [class.item-active]="item().isActive"
>
  <div
    [cdkContextMenuTriggerFor]="context_menu"
    (contextmenu)="rightClick($event)"
    [cdkContextMenuDisabled]="item().isEdit"
    (cdkContextMenuClosed)="closeMoreMenu()"
  >
    @if (type() === 'image') {
      <div class="relative" (click)="insertImage()">
        <!--                图片-->
        <img
          class="bg-primary-600 h-24 w-24 rounded-lg bg-opacity-10 object-scale-down dark:bg-neutral-50 dark:bg-opacity-5"
          style="box-sizing: border-box"
          [src]="item().preview || item().snapshot"
        />

        @if (item().status === 'uploading') {
          <mat-progress-bar
            mode="determinate"
            [value]="item().progress ? item().progress : 10"
            class="bg-tertiary-100 dark:bg-tertiary-800 absolute h-2 rounded-lg shadow-inner"
            style="left: 4px; bottom: 14px; width: 88px"
          ></mat-progress-bar>
        }
      </div>
    }
    @if (type() === 'data') {
      <div class="w-50 flex h-[58px] items-center gap-2 px-2 py-3" (click)="openTable($event)">
        <mat-icon class="icon-size-7" [svgIcon]="'custom:upload-userdata'"></mat-icon>
        <div class="flex flex-grow flex-col items-start gap-1 overflow-hidden">
          <div class="text-tertiary-900 w-full overflow-hidden text-ellipsis text-nowrap text-lg leading-[18px] dark:text-neutral-50">
            {{ item().name }}
          </div>
          @if (item().status === 'done') {
            <div class="text-tertiary-600 dark:text-tertiary-300 w-full overflow-hidden text-ellipsis text-nowrap text-sm leading-3">
              <span>于</span>
              <span>{{ item().timeAgo }}</span>
              <span>上传</span>
            </div>
          }
          <!--                上传进度条-->
          @if (item().status === 'uploading') {
            <mat-progress-bar
              mode="determinate"
              [value]="item().progress ? item().progress : 40"
              class="bg-tertiary-100 dark:bg-tertiary-800 h-2 w-full rounded-lg"
            >
            </mat-progress-bar>
          }
        </div>
      </div>
    }
    <!--                报错蒙版-->
    @if (item().status === 'error') {
      <div (click)="errorRetryUpload(item())">
        <!--                      蒙版-->
        <div class="bg-tertiary-950 absolute left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-60">
          <mat-icon class="icon-size-5" [svgIcon]="'editorup:报错'"></mat-icon>
        </div>
      </div>
    }
    <!--                复选框-->
    @if (item().status !== 'error' && item().hoverCheckbox) {
      <mat-checkbox class="absolute left-0 top-0" [checked]="item().selected" [color]="'primary'" (change)="checkboxChecked()"></mat-checkbox>
    }
  </div>
  <!--                更多按钮-->
  @if (item().status !== 'error' && item().hoverMore) {
    <button
      mat-icon-button
      class="hover:bg-primary absolute right-2 top-2 flex h-4 w-7 items-center justify-center rounded-lg"
      [class]="moreObj === item() ? 'bg-primary' : 'bg-black bg-opacity-60'"
      (click)="leftClick($event)"
      [cdkMenuTriggerFor]="context_menu"
      (cdkMenuClosed)="closeMoreMenu()"
      (contextmenu)="$event.preventDefault()"
    >
      <mat-icon class="icon-size-5 text-neutral-50" [svgIcon]="'custom:more-horizontal'"></mat-icon>
    </button>
  }

  <!--                离线上传-->
  @if (item().status === 'ready') {
    <div class="bg-tertiary-950 absolute left-0 top-0 flex h-full w-full items-center justify-center bg-opacity-60">
      <mat-icon class="icon-size-5" [svgIcon]="'editorup:待上传'"></mat-icon>
    </div>
  }
</div>

<!-- 更多弹窗 -->
<ng-template #context_menu>
  <div
    class="dark:bg-tertiary-950 text-tertiary-600 dark:text-tertiary-300 flex w-52 flex-col items-start justify-start gap-2 overflow-hidden rounded-lg bg-neutral-50 text-sm"
    cdkMenu
  >
    <li class="border-tertiary-100 dark:border-tertiary-800 flex h-16 w-full border-b p-3">
      <div class="flex flex-grow flex-col overflow-hidden">
        <div class="flex h-5 items-center justify-start gap-2">
          <div class="relative overflow-hidden" [ngClass]="{ 'w-full': editing }">
            <input
              matInput
              #titleInput
              class="dark:hover:border-tertiary-300 hover:border-tertiary-400 absolute w-full overflow-ellipsis border-b border-dashed border-opacity-0 text-base leading-tight"
              [value]="item().name"
              (focus)="focusTitle()"
              (blur)="updateTitle($event)"
              (keydown)="enterTitle($event)"
            />
            <span class="pointer-events-none relative whitespace-pre text-lg leading-tight opacity-0">{{ item().name }}</span>
          </div>
          <span class="w-6 shrink-0">
            @if (!editing) {
              <button
                mat-button
                class="group flex h-6 min-h-6 w-6 min-w-6 items-center justify-center rounded-lg p-0"
                (click)="editTitle()"
                [class]="
                  'active:bg-primary-600 hover:bg-primary-600 bg-opacity-0 hover:bg-opacity-10 active:bg-opacity-100 dark:hover:bg-neutral-50 dark:hover:bg-opacity-5'
                "
              >
                <mat-icon
                  class="icon-size-5"
                  [color]="
                    'text-tertiary group-hover:text-tertiary-700 dark:text-tertiary-300 dark:group-hover:text-neutral-50 group-active:text-neutral-50 dark:group-active:text-neutral-50'
                  "
                  [svgIcon]="'custom:name-edit'"
                ></mat-icon>
              </button>
            }
          </span>
        </div>
        <div class="mt-1 flex items-center justify-start leading-3">
          <span>于</span>
          <span>{{ item().timeAgo }}</span>
          <span>上传</span>
        </div>
      </div>
    </li>
    <ul class="text-tertiary-600 dark:text-tertiary-300 w-full overflow-hidden text-base leading-none">
      <li class="context-menu-item" cdkMenuItem>
        <button class="menu-item-button" (click)="download()">
          <div class="menu-item-left">
            <span>下载</span>
          </div>
          <div class="menu-item-right">
            <mat-icon class="icon-size-5 text-tertiary-400 dark:text-tertiary-300" [svgIcon]="'editorup:Download (下载s)'"></mat-icon>
          </div>
        </button>
      </li>
      <li class="context-menu-item pb-1" cdkMenuItem>
        <button class="menu-item-button" (click)="openDelete()">
          <div class="menu-item-left">
            <span>删除</span>
          </div>
          <div class="menu-item-right">
            <mat-icon class="icon-size-5 text-tertiary-400 dark:text-tertiary-300" [svgIcon]="'editorup:Delete (删除)'"></mat-icon>
          </div>
        </button>
      </li>
    </ul>
  </div>
</ng-template>
