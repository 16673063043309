import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import _ from 'lodash'
import { filter, takeWhile } from 'rxjs'

import { IPosition } from '@libs/algorithm'

import { ElementContainerComponent } from '#modules/workspace/components/element-container/element-container.component'
import { ElementBaseComponent } from '#modules/workspace/components/element/element-base.component'
import { ElementGroupComponent } from '#modules/workspace/components/element/group/element-group.component'
import { ChildElementTreeNode } from '#modules/workspace/models/element-node'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { AtomType, IChildElement, IPageElementSetting, IShadowElement, ISize } from '#modules/workspace/types/element'

@Component({
  selector: 'ace-virtual-group-shadow',
  standalone: true,
  imports: [CommonModule, ElementBaseComponent, ElementGroupComponent],
  templateUrl: './virtual-group-shadow.component.html',
  styleUrl: './virtual-group-shadow.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualGroupShadowComponent extends ElementContainerComponent {
  uiStore = inject(StageUiStore)
  childrenInteraction = signal<Record<string, IShadowElement>>({})

  override children = computed<IChildElement<AtomType>[]>(() => {
    const elements = (this.data().children as ChildElementTreeNode[]) || []
    return elements.map(el => {
      const childInteraction = this.childrenInteraction()[el.id]

      const atomCategory = this.data().category as AtomType
      return {
        ...el.data,
        position: _.isEmpty(childInteraction.position) ? el.position : (childInteraction.position as IPosition),
        size: _.isEmpty(childInteraction.size) ? el.size : (childInteraction.size as ISize),
        scale: _.isUndefined(childInteraction.scale) ? el.scale : childInteraction.scale,
        rotation: _.isUndefined(childInteraction.rotation) ? el.rotation : childInteraction.rotation,
        setting: _.isEmpty(childInteraction.setting) ? el.setting : (childInteraction.setting as IPageElementSetting[typeof atomCategory])
      } as IChildElement<AtomType>
    })
  })

  constructor() {
    super()
    toObservable(this.uiStore.isElementInteracting)
      .pipe(takeUntilDestroyed())
      .subscribe(isElementInteracting => {
        if (isElementInteracting) {
          const interactState = this.uiStore.interacting()
          const childInteractElement = this.data().children?.find(child => child.id === interactState.id)
          if (childInteractElement) {
            this.childrenInteraction.set({
              [interactState.id]: {
                position: interactState.shadowData.position,
                size: interactState.shadowData.size,
                scale: interactState.shadowData.scale,
                rotation: interactState.shadowData.rotation,
                setting: interactState.shadowData.setting
              }
            })
          }
        } else {
          this.childrenInteraction.set({})
        }
      })
  }
}
