import { inject } from '@angular/core'
import { ResolveFn } from '@angular/router'

import { filter, firstValueFrom, map } from 'rxjs'

import { IProject } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { ProjectCollectionService } from '#shared/services/rxdb/project-collection.service'

export const projectDataResolver: ResolveFn<IProject> = async route => {
  const projectId = route.params['projectId'] as string
  const apiService = inject(ApiService)
  // const pageCollection = inject(PageCollectionService)
  // const pageElementCollection = inject(PageElementCollectionService)
  // await firstValueFrom(pageCollection.initialized$.pipe(filter(initialized => initialized)))
  // await firstValueFrom(pageElementCollection.initialized$.pipe(filter(initialized => initialized)))
  // const db = inject(APP_DB)
  // if (db.isLeader()) {
  //   await projectCollectionService.replicaState.awaitInitialReplication()
  // }

  return await firstValueFrom(apiService.getProjectById(projectId))
}

export const projectListResolver: ResolveFn<boolean> = async route => {
  const projectCollection = inject(ProjectCollectionService)
  return await firstValueFrom(projectCollection.initialized$.pipe(filter(initialized => initialized))).then(() => {
    projectCollection.reSync()
    return true
  })
}
