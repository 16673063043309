<div class="flex h-full flex-auto flex-col items-center sm:justify-center md:p-8">
  <div class="sm:bg-card flex overflow-hidden sm:rounded-2xl sm:shadow md:w-full md:max-w-6xl">
    <div class="relative hidden h-full flex-auto items-center justify-center overflow-hidden p-16 md:flex lg:px-28 dark:border-r">
      <!-- Background - @formatter:off -->
      <video #video autoplay muted loop class="absolute z-0 h-full w-full overflow-hidden object-cover">
        <source src="http://cdn.core.editorup.com/static/videos/bg.webm" type="video/webm" />
      </video>
      <!-- @formatter:on -->
      <!-- Content -->
      <div class="relative z-10 w-full max-w-2xl">
        <div class="text-7xl font-bold leading-none text-gray-100">
          <div>{{ 'Welcome to' | transloco }}</div>
          <div>Demo version</div>
        </div>
        <div class="mt-6 text-lg leading-6 tracking-tight text-gray-400">
          Key7men helps developers to build organized and well coded dashboards full of beautiful and rich modules. Join us and start building your application
          today.
        </div>
      </div>
    </div>
    <div class="flex w-full flex-col px-4 py-8 sm:w-auto sm:p-12 md:p-16">
      <div class="mx-auto w-full max-w-80 sm:mx-0 sm:w-80">
        <!-- Logo -->
        <div class="w-12">
          <img src="assets/images/logo/logo.svg" />
        </div>
        <router-outlet></router-outlet>
      </div>
      <a (click)="updateLang()" class="text-primary-500 cursor-pointer py-4 text-center font-medium hover:underline">{{ 'switch to chinese' | transloco }}</a>
    </div>
  </div>
</div>
