import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ace-element-move-handler',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './element-move-handler.component.html',
  styleUrl: './element-move-handler.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementMoveHandlerComponent {
  dragStart = output<MouseEvent>()
  onDragStart($event: MouseEvent) {
    $event.stopPropagation()
    this.dragStart.emit($event)
  }
}
