<div class="dark:bg-tertiary-950 inline-flex rounded-[8px] bg-white p-[4px] drop-shadow-lg" [class.invisible]="isCropImage()">
  @for (group of actions(); track $index; let last = $last) {
    @for (action of group; track $index) {
      <button
        mat-icon-button
        class="h-[32px] w-[32px] p-[6px]"
        (click)="action.callback ? action.callback() : null"
        [cdkMenuTriggerFor]="subMenu || null"
        [disabled]="action.disabled"
      >
        <mat-icon [svgIcon]="action.icon" class="text-tertiary-700 icon-size-5 dark:text-tertiary-300"></mat-icon>
      </button>
      <ng-template #subMenu>
        @if (action.children) {
          <ace-context-menu [menuItems]="action.children"></ace-context-menu>
        }
      </ng-template>
      @if (last && $last && !locked()) {
        <button mat-icon-button class="h-[32px] w-[32px] p-[6px]" [cdkMenuTriggerFor]="more">
          <mat-icon class="text-tertiary-700 icon-size-5 dark:text-tertiary-300" [svgIcon]="'custom:more-horizontal'"></mat-icon>
        </button>
      }
    }
    @if (!last) {
      <mat-divider [vertical]="true"></mat-divider>
    }
  }
</div>

<ng-template #more>
  <ace-context-menu [menuItems]="contextMenu()"></ace-context-menu>
</ng-template>
