import { CommonModule } from '@angular/common'
import {
  afterNextRender,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  inject,
  model,
  OnDestroy,
  output,
  signal,
  viewChild
} from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { FormsModule } from '@angular/forms'
import { MatAutocomplete, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatRipple } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'

import { cloneDeep, find, first, tail } from 'lodash'
import { debounceTime, distinctUntilChanged, filter, Subscription, take } from 'rxjs'

import { ScrollListComponent } from '@libs/ng-shared/components/scroll-list'
import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'
import { IPaginatedListRes, IResourceData } from '@libs/payload'

import { ApiService } from '#core/services/api.service'
import { ChildTag, DataExploreHot, DataExploreMode, DataExploreTopic, FatherTag } from '#modules/workspace/components/data-panel/data-explore/index'
import { ScrollRowComponent } from '#shared/components/scroll-row/scroll-row.component'

import { DataRecordRowComponent } from '../data-record-row'

@Component({
  selector: 'ace-data-explore',
  standalone: true,
  imports: [
    CommonModule,
    MatAutocomplete,
    MatIcon,
    MatAutocompleteTrigger,
    MatOption,
    MatButton,
    FormsModule,
    MatRipple,
    ScrollbarDirective,
    MatIconButton,
    DataRecordRowComponent,
    ScrollListComponent,
    ScrollRowComponent
  ],
  templateUrl: './data-explore.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataExploreComponent implements AfterViewInit, OnDestroy {
  animationDisabled = true
  /**
   * 下一页
   */
  aceForward = output<IResourceData>()
  /**
   * 标签容器
   */
  tagContainer = viewChild.required<ElementRef<HTMLDivElement>>('tagContainer')
  /**
   * PrefectScrollBar
   */
  scrollbar = viewChild.required<ScrollbarDirective>('scrollbar')
  keyword = model<string>('北京')
  /**
   * 当前组件状态
   */
  mode = signal<DataExploreMode>(DataExploreMode.INIT)
  /**
   * 是否为初始化模式
   */
  isInitMode = computed(() => this.mode() === DataExploreMode.INIT)
  /**
   * 是否为搜索模式
   */
  isTopicMode = computed(() => this.mode() === DataExploreMode.TOPIC)
  /**
   * 是否为主题模式
   */
  isSearchMode = computed(() => this.mode() === DataExploreMode.SEARCH)
  /**
   * 热门数据列表
   */
  hotList = signal<DataExploreHot[]>([
    { id: '1', name: '全国空气质量数据', description: '获取全国各城市最新的空气质量指数 (AQI) 数据。' },
    { id: '2', name: '房价走势分析', description: '了解全国各大城市的房价趋势和市场动态。' },
    { id: '3', name: '热门旅游景点', description: '发现全国最受欢迎的旅游景点和目的地。' },
    { id: '4', name: '高校录取分数线', description: '查询全国各大高校的录取分数线和招生政策。' },
    { id: '5', name: '就业市场分析', description: '了解全国各行业的就业市场趋势和薪资水平。' },
    { id: '6', name: '人口普查数据', description: '获取全国各地区的人口数量、分布和结构数据。' },
    { id: '7', name: '经济发展指标', description: '了解全国各地区的经济发展状况和主要指标。' },
    { id: '8', name: '环境污染监测', description: '获取全国各地的环境污染监测数据，包括空气、水和土壤质量。' }
  ])

  /**
   * 主题列表
   */
  topicList = signal<DataExploreTopic[]>([
    {
      id: '1',
      name: '环境',
      image: 'https://picsum.photos/seed/PRm7bdGbSs/720/520'
    },
    {
      id: '2',
      name: '经济',
      image: 'https://picsum.photos/seed/iZ6Mcq/720/520'
    },
    {
      id: '3',
      name: '教育',
      image: 'https://loremflickr.com/720/520?lock=845731908812800'
    },
    {
      id: '4',
      name: '医疗',
      image: 'https://loremflickr.com/720/520?lock=2993306751467520'
    },
    {
      id: '5',
      name: '交通',
      image: 'https://picsum.photos/seed/wEQQLEunjs/720/520'
    },
    {
      id: '6',
      name: '旅游',
      image: 'https://picsum.photos/seed/7dxg3Q/720/520'
    },
    {
      id: '7',
      name: '科技',
      image: 'https://picsum.photos/seed/Wdl3r72kMB/720/520'
    },
    {
      id: '8',
      name: '文化',
      image: 'https://picsum.photos/seed/fERWaz/720/520'
    },
    {
      id: '9',
      name: '体育',
      image: 'https://loremflickr.com/720/520?lock=8750573843120128'
    },
    {
      id: '10',
      name: '政治',
      image: 'https://loremflickr.com/720/520?lock=1236593417388032'
    },
    {
      id: '11',
      name: '社会',
      image: 'https://picsum.photos/seed/kaSMtD/720/520'
    },
    {
      id: '12',
      name: '农业',
      image: 'https://picsum.photos/seed/WM1mTX/720/520'
    }
  ])

  /**
   * 搜索结果列表 非初始状态下根据topic或者keywords获取数据
   */
  searchDataList = signal<IResourceData[]>([])
  /**
   * 搜索结果的数量，由于使用ngTemplateOutlet导致无法直接使用searchDataList.length
   */
  searchDataCount = computed(() => this.searchScrollListRef()?.total() || 0)

  /**
   * 主题列表中的第一个主题，渲染时第一个主题的高度为2行，需要单独处理样式
   */
  firstTopic = computed(() => first(this.topicList()))
  otherTopics = computed(() => tail(this.topicList().slice(1)))
  /**
   * 进入页面后获取到的最新数据，保存在单独的数组中，确保在切换模式时不会丢失数据从而不再需要重新获取
   */
  newDataList = signal<IResourceData[]>([])
  // /**
  //  * 组件使用据对定位的方式展示，因此高度需要根据屏幕的高度来计算，达到弹窗撑满剩余高度的效果
  //  */
  // maxHeight = signal(document.body.clientHeight)
  /**
   * 当前选中的topic
   */
  currentTopic = signal<string>('')
  /**
   * 进入搜索模式后，会展示根据搜索词相关的标签，用于用户选择
   */
  tagList = signal<FatherTag[]>([])
  /**
   * 当前选中的tag
   */
  currentTagIndex = signal<number>(-1)
  currentTag = computed<FatherTag | undefined>(() => this.tagList()[this.currentTagIndex()])
  tagChildList = computed(() => this.currentTag()?.children || [])
  /**
   * 搜索词被选中的列表
   */
  pinnedList = computed(() => this.tagList().filter(tag => tag.pinned))

  private newDataScrollListRef = viewChild<ScrollListComponent>('newDataScrollListRef')
  private searchScrollListRef = viewChild<ScrollListComponent>('searchScrollListRef')

  /**
   * 当前组件的宿主元素
   * @private
   */
  // private readonly hostRef = inject(ElementRef)
  private readonly apiService = inject(ApiService)
  private subscription = new Subscription()
  private _newDataScrollListRef$ = toObservable(this.newDataScrollListRef)

  constructor() {
    toObservable(this.keyword)
      .pipe(takeUntilDestroyed(), debounceTime(500), distinctUntilChanged())
      .subscribe(keyword => {
        if (keyword) {
          this.handleAISearch()
        } else {
          this.mode.set(DataExploreMode.INIT)
          this._newDataScrollListRef$
            .pipe(
              filter((ref): ref is ScrollListComponent => !!ref),
              take(1)
            )
            .subscribe(ref => {
              if (this.newDataList().length > 0) {
                ref?.reset()
              } else {
                ref?.isInit.set(false)
              }
            })
        }
      })
  }

  ngAfterViewInit() {
    this.animationDisabled = false
    this.newDataScrollListRef()?.reset()
    console.log(this.topicList())
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  /**
   * 处理选中topic事件
   * @param topic
   */
  handleTopicSelect(topic: DataExploreTopic) {
    console.log(topic)
    this.mode.set(DataExploreMode.TOPIC)
    this.currentTopic.set(topic.name)
    this.searchDataList.set(cloneDeep(this.newDataList()))
  }

  /**
   * 从TOPIC 模式返回，清除状态
   */
  handleTopicBack() {
    this.mode.set(DataExploreMode.INIT)
    this.currentTopic.set('')
    this.searchDataList.set([])
  }

  /**
   * 处理选中热门搜索事件
   * @param hot
   */
  handleHotSelect(hot: DataExploreHot) {
    this.keyword.set(hot.name)
    this.mode.set(DataExploreMode.SEARCH)
    // this.handleAISearch()
  }

  /**
   * 处理搜索事件
   */
  handleAISearch() {
    // 获取搜索相关的标签

    this.mode.set(DataExploreMode.SEARCH)
    requestAnimationFrame(() => {
      this.searchDataList.set([])
      this.searchScrollListRef()?.reset()
    })
  }

  /**
   * 处理父标签的选中状态
   * @param tag
   */
  handleSelectTag(tag: FatherTag) {
    const index = this.tagList().findIndex(t => t.id === tag.id)
    this.currentTagIndex.set(index)
  }

  /**
   * 处理选中tag的子标签 维护父标签的状态和选中数量
   * @param tag
   * @param add
   */
  handleSelectChild(tag: ChildTag, add: boolean) {
    this.tagList.update(dataList => {
      const father = dataList[this.currentTagIndex()]
      const current = find(father.children, { id: tag.id })
      if (current) {
        if (add) {
          father.selectedCount += 1
          father.pinned = true
          current.selected = true
        } else {
          father.selectedCount -= 1
          current.selected = false
          father.pinned = father.selectedCount > 0
        }
      }
      return cloneDeep(dataList)
    })
  }

  /**
   * 清除选中的tag
   * @param tag
   */
  clearTagSelect(tag: FatherTag) {
    this.tagList.update(dataList => {
      const father = dataList.find(t => t.id === tag.id)
      if (!father) return cloneDeep(dataList)
      father.selectedCount = 0
      father.pinned = false
      father.children.forEach(child => (child.selected = false))
      return cloneDeep(dataList)
    })
  }
  //
  // /**
  //  * 处理tag滚动事件 左右翻页
  //  * @param direction
  //  */
  // handleScrollTag(direction: 'left' | 'right') {
  //   console.log(this.scrollbar().position(), this.scrollbar().geometry())
  //   const { x } = this.scrollbar().geometry()
  //   const { clientWidth } = this.tagContainer().nativeElement
  //   if (direction === 'left') {
  //     this.scrollbar().scrollToX(x - clientWidth - 80, 200)
  //   } else {
  //     this.scrollbar().scrollToX(x + clientWidth - 80, 200)
  //   }
  // }

  goRecordDetail(data: IResourceData) {
    this.aceForward.emit(data)
  }

  newDataLoader = (pageNo: number, limit: number) => {
    return this.apiService.searchResourceData({ keyword: '', page: pageNo, limit })
  }

  searchDataLoader = (pageNo: number, limit: number) => {
    return this.apiService.searchResourceData({ keyword: this.keyword(), page: pageNo, limit })
  }

  handleNewDataChange($event: IPaginatedListRes<unknown>) {
    this.newDataList.update(dataList => dataList.concat($event.list as IResourceData[]))
  }

  handleSearchDataChange($event: IPaginatedListRes<unknown>) {
    this.searchDataList.update(dataList => dataList.concat($event.list as IResourceData[]))
  }
}
