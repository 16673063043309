export * from './canvas.component'
export * from './rule-popup.component'

/**
 * 刻度尺的子集
 */
export interface RuleChild {
  label: number
  isMiddle: boolean
}

/**
 * 辅助线创建事件
 */
export interface EuRulePopupCreate {
  // 当前创建的辅助线是否是干净的
  dry: boolean
  // 创建辅助线将画布分割的列数
  column: number
  // 创建辅助线将画布分割的行数
  row: number
}

/**
 * 非线性计算步长和放大倍数的关系
 * @param x
 */
const f = (x: number) => (10 + x + Math.pow(x / 49, 3) * 441) / 100

/**
 *  f(x)的导数
 * @param x
 */
const df = (x: number) => (1 + 3 * Math.pow(x / 49, 2) * (441 / 49)) / 100

/**
 * 转换步长
 * @param val
 */
export function transformStep(val: number) {
  const step = Math.min(Math.max(val, 0), 49)
  return f(step)
}

/**
 * 牛顿迭代法求解反函数
 * @param val
 */
export function reverseRadio(val: number) {
  const maxIter = 100 // 最大迭代次数
  const tol = 1e-6 // 容忍误差
  let x = 0
  for (let iter = 1; iter <= maxIter; iter++) {
    const fx = f(x) - val
    const dfx = df(x)

    // 检查导数是否为零，避免除以零
    if (dfx === 0) {
      throw new Error('Zero derivative. No solution found.')
    }

    const x1 = x - fx / dfx // 牛顿迭代公式
    const error = Math.abs(x1 - x)

    // 输出每次迭代结果，用于分析
    // console.log(`Iteration ${iter}: x = ${x}, f(x) = ${fx}, Error = ${error}`)

    if (error < tol) {
      // 如果达到容忍误差范围，返回当前估计的根
      return x1
    }

    // 准备下一次迭代
    x = x1
  }
  // 如果在最大迭代次数后仍未达到容忍误差，则抛出异常
  throw new Error(`No convergence after ${maxIter} iterations.`)
}
