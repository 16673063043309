import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, ElementRef, HostBinding, inject, input, Signal, untracked, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatIcon } from '@angular/material/icon'

import { patchState, signalState } from '@ngrx/signals'
import _, { isEqual } from 'lodash-es'
import { abs, atan2, cos, Matrix, multiply, pi, sin, tan } from 'mathjs'
import { combineLatestWith, debounceTime, distinctUntilChanged, EMPTY, filter, fromEvent, iif, takeWhile } from 'rxjs'

import { boundingRect, IPosition, rectVertices, rotateMatrixFactory, scaleMatrixFactory, transformPosition } from '@libs/algorithm'

import { ElementVertexHandlerComponent } from '#modules/workspace/components/element-interact/element-anchor-handler/element-vertex-handler.component'
import { ElementMoveHandlerComponent } from '#modules/workspace/components/element-interact/element-drag-handler/element-move-handler.component'
import { ElementEdgeHandlerComponent } from '#modules/workspace/components/element-interact/element-resize-handler/element-edge-handler.component'
import { ElementRotateHandlerComponent } from '#modules/workspace/components/element-interact/element-rotate-handler/element-rotate-handler.component'
import { ElementToolboxComponent } from '#modules/workspace/components/element-interact/element-toolbox/element-toolbox.component'
import { LineInteractComponent } from '#modules/workspace/components/element-interact/line-interact'
import { TextInteractComponent } from '#modules/workspace/components/element-interact/text-interact/text-interact.component'
import { GroupElementTreeNode, PageElementTreeNode, VirtualGroupElementTreeNode } from '#modules/workspace/models/element-node'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { DRAG_START_DISTANCE, ELEMENT_MIN_HEIGHT, ELEMENT_MIN_WIDTH } from '#modules/workspace/types/constants'
import { Direction, Direction2, IChildElement, IGroupElement, IInteractionState, IPageElementBase, ISize, ITextSetting } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'

@Component({
  selector: 'ace-element-interact-box',
  standalone: true,
  imports: [
    CommonModule,
    ElementVertexHandlerComponent,
    ElementEdgeHandlerComponent,
    ElementRotateHandlerComponent,
    ElementMoveHandlerComponent,
    MatIcon,
    ElementToolboxComponent,
    TextInteractComponent,
    LineInteractComponent
  ],
  templateUrl: './element-interact-box.component.html',
  styleUrl: './element-interact-box.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementInteractBoxComponent {
  @HostBinding('class.pointer-events-none') pointerEventNone = true
  data = input.required({
    transform: (value: PageElementTreeNode | VirtualGroupElementTreeNode) => {
      return value
    }
  })
  highLightOnly = input(false)
  isVirtualGroupChild = input(false)

  projectService = inject(ProjectService)
  workspaceService = inject(WorkspaceService)

  // 拖动角标只能控制scale的元素类型
  vertexScaleCategories = ['group', 'text', 'chart', 'image', 'shape']

  children = computed<PageElementTreeNode[]>(() => {
    if (this.isVirtualGroup) {
      return (this.data() as VirtualGroupElementTreeNode).children
    } else {
      return this.data().children?.map(child => new PageElementTreeNode(child)) || []
    }
  })

  vertexDirections = computed<Direction[]>(() => {
    if (this.category() === 'line') {
      return []
    }
    return ['nw', 'ne', 'se', 'sw']
  })
  edgeDirections = computed<Direction2[]>(() => {
    if (this.category() === 'line') {
      return ['w', 'e']
    }
    return ['n', 'e', 's', 'w']
  })

  dragContainerRef = viewChild('dragContainerRef', { read: ElementRef })

  isInteractingOnPage = computed(() => {
    return this.uiStore.interacting.id() === this.data().id && this.uiStore.isElementInteractingOnPage()
  })
  isInteracting = computed(() => {
    return this.uiStore.interacting.id() === this.data().id
  })
  category = computed(() => {
    return this.data().category
  })

  // 是否锁定宽高比
  lockRatio = computed(() => {
    // 当元素为group, chart, text或按住shift时，锁定宽高比
    if (this.isVertexScale) {
      return true
    } else {
      return this.uiStore.isShiftKeydown()
    }
  })

  // zoom = computed(() => this.uiStore.zoom())

  dataPosition = computed(
    () => {
      return this.data().position
    },
    {
      equal: isEqual
    }
  )

  dataSize = computed(
    () => {
      return this.data().size
    },
    {
      equal: isEqual
    }
  )

  dataScale = computed(() => {
    return this.data().scale
  })

  dataScaleSize = computed(
    () => {
      const { width, height } = this.data().size
      const size = multiply([width, height], scaleMatrixFactory(this.data().scale))
      return {
        width: size.get([0]),
        height: size.get([1])
      }
    },
    {
      equal: isEqual
    }
  )

  selected = computed(() => {
    return (
      this.isVirtualGroup ||
      (this.uiStore.selectedIds().includes(this.data().id) && this.uiStore.selectedTarget() === 'element') ||
      (this.data().children && (this.data() as IGroupElement).children.some(child => this.uiStore.selectedIds().includes(child.id)))
    )
  })

  scale = computed(
    () => {
      if (this.uiStore.isScalingElement() && untracked(this.isInteracting)) {
        return this.uiStore.interacting.shadowData().scale ?? this.data().scale
      } else {
        return this.data().scale
      }
    },
    {
      equal: isEqual
    }
  )

  position = computed(() => {
    if ((this.uiStore.isMovingElement() || this.uiStore.isResizingElement() || this.uiStore.isScalingElement()) && untracked(this.isInteracting)) {
      return this.uiStore.interacting.shadowData().position as IPosition
    } else {
      return this.dataPosition()
    }
  })

  left = computed(() => {
    return this.position().x
  })

  top = computed(() => {
    return this.position().y
  })

  rotation = computed(() => {
    if (this.uiStore.isRotatingElement() && untracked(this.isInteracting)) {
      return this.uiStore.interacting.shadowData().rotation as number
    } else {
      return this.data().rotation
    }
  })

  rotateRad = computed(
    () => {
      return this.rotation() * (Math.PI / 180)
    },
    {
      equal: isEqual
    }
  )

  size = computed(
    () => {
      if (this.uiStore.isResizingElement() && untracked(this.isInteracting)) {
        return {
          width: (this.uiStore.interacting.shadowData().size as ISize).width,
          height: (this.uiStore.interacting.shadowData().size as ISize).height
        } as ISize
      } else {
        return this.dataSize()
      }
    },
    {
      equal: isEqual
    }
  )

  scaleSize = computed(
    () => {
      // console.log(this.uiStore.isElementInteracting())
      const { width, height } = this.size()
      const size = multiply([width, height], scaleMatrixFactory(this.scale()))

      return {
        width: size.get([0]),
        height: size.get([1])
      }
    },
    {
      equal: isEqual
    }
  )

  width = computed(() => {
    return this.scaleSize().width
  })
  height = computed(() => {
    return this.scaleSize().height
  })

  // 旋转后顶点的方位转换
  rotateVertexDirection: Signal<Record<Direction, Direction>> = computed(() => {
    if ((this.rotation() >= 0 && this.rotation() < 45) || (this.rotation() <= 0 && this.rotation() >= -45)) {
      return {
        nw: 'nw',
        ne: 'ne',
        sw: 'sw',
        se: 'se'
      }
    } else if (this.rotation() >= 45 && this.rotation() < 135) {
      return {
        nw: 'ne',
        ne: 'se',
        se: 'sw',
        sw: 'nw'
      }
    } else if (this.rotation() >= 135 || (this.rotation() < 0 && this.rotation() < -135)) {
      return {
        nw: 'se',
        ne: 'sw',
        se: 'nw',
        sw: 'ne'
      }
    } else {
      return {
        nw: 'sw',
        ne: 'nw',
        se: 'ne',
        sw: 'se'
      }
    }
  })

  // 矩形对角线与顶（底）边的夹角
  theta = computed(() => {
    return atan2(this.dataScaleSize().height, this.dataScaleSize().width)
  })

  //对角线长度
  diagonal = computed(() => {
    return this.dataScaleSize().width / cos(this.theta())
  })

  //不同顶点的旋转矩阵
  directionRotationMatrix: Signal<Record<Direction, Matrix>> = computed(() => {
    return {
      nw: rotateMatrixFactory(-(this.rotateRad() + this.theta())),
      ne: rotateMatrixFactory(this.theta() - this.rotateRad()),
      se: rotateMatrixFactory(-this.rotateRad() - this.theta()),
      sw: rotateMatrixFactory(this.theta() - this.rotateRad())
    }
  })

  //元素的旋转矩阵
  rotationMatrix = computed(() => {
    return rotateMatrixFactory(this.rotateRad())
  })

  //元素的逆旋转矩阵
  inverseRotationMatrix = computed(() => {
    return rotateMatrixFactory(-this.rotateRad())
  })

  // 交互控制点位置
  controlPoints = computed(() => {
    return {
      nw: {
        x: 0,
        y: 0
      },
      ne: {
        x: this.styleWidth,
        y: 0
      },
      sw: {
        x: 0,
        y: this.styleHeight
      },
      se: {
        x: this.styleWidth,
        y: this.styleHeight
      },
      center: {
        x: this.styleWidth / 2,
        y: this.styleHeight / 2
      },
      w: {
        x: 0,
        y: 0
      },
      e: {
        x: this.styleWidth,
        y: 0
      },
      n: {
        x: 0,
        y: 0
      },
      s: {
        x: 0,
        y: this.styleHeight
      }
    }
  })

  centerPosition = computed(() => {
    return {
      x: this.left() + this.width() / 2,
      y: this.top() + this.height() / 2
    }
  })

  bounding = computed(() => {
    const { x, y } = this.dataPosition()
    const { width, height } = this.dataScaleSize()
    return boundingRect({ x, y }, { width, height }, this.rotateRad())
  })

  // 初始状态矩形顶点位置
  vertices: Signal<Record<Direction, IPosition>> = computed(() => {
    const { x, y } = this.dataPosition()
    const { width, height } = this.dataScaleSize()
    return rectVertices({ x, y }, { width, height }, this.rotateRad())
  })

  /**
   * 是否有子元素被选中
   */
  isGroupChildFocus = computed(() => {
    if (this.isGroup) {
      return !!this.data().children?.find(child => this.uiStore.selectedIds().includes(child.id))
    } else {
      return false
    }
  })

  minSize = computed(() => {
    const zoom = this.uiStore.zoom()
    return {
      width: Number((ELEMENT_MIN_WIDTH / zoom).toFixed(2)),
      height: Number((ELEMENT_MIN_HEIGHT / zoom).toFixed(2))
    }
  })

  // 旋转按钮位置
  protected buttonsTransform = computed(() => {
    const startRotation = this.uiStore.isRotatingOnPageReady()
    const rotation = untracked(this.rotation)
    return {
      x: abs(rotation) < 135 ? 0 : (rotation < 0 ? -1 : 1) * (this.styleWidth / 2 + 33),
      y: abs(rotation) < 135 ? this.styleHeight / 2 + 33 : 0,
      rotate: abs(rotation) < 135 ? 0 : (rotation < 0 ? -1 : 1) * 90
    }
  })

  protected uiStore = inject(StageUiStore)
  protected locked = computed(() => this.data().locked || !!this.uiStore.onStagePage()?.locked)
  // 交互状态
  protected interactionState = signalState<IInteractionState>({
    moving: false,
    resizing: false,
    scaling: '',
    rotating: false,
    setting: false
  })

  /**
   * 正在裁剪图片
   * @protected
   */
  protected isCropImage = computed(() => {
    return this.data().id === this.workspaceService.cropImageElementId()
  })

  constructor() {
    toObservable(this.uiStore.isElementInteracting)
      .pipe(
        distinctUntilChanged(),
        filter(interacting => !interacting),
        combineLatestWith(fromEvent(document, 'mouseup', { capture: true }).pipe(takeWhile(() => !this.isVirtualGroupChild() && !this.isGroupChild))),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        patchState(this.interactionState, {
          moving: false,
          scaling: '',
          resizing: false,
          rotating: false,
          setting: false
        })
      })
    this.registerDragEvent()
    this.registerRotation()
    this.registerScale()
    this.registerResize()

    // this.registerSetting()
  }

  @HostBinding('style.transform') get transform() {
    return `translate(${this.left() * this.uiStore.zoom()}px, ${this.top() * this.uiStore.zoom()}px) rotate(${this.rotation()}deg)`
  }

  @HostBinding('style.width.px') get styleWidth() {
    return this.width() * this.uiStore.zoom()
  }

  @HostBinding('style.height.px') get styleHeight() {
    return this.height() * this.uiStore.zoom()
  }

  // 是否是等比缩放的元素
  get isVertexScale() {
    return this.vertexScaleCategories.includes(this.data().category)
  }

  get isGroup() {
    return this.data() instanceof VirtualGroupElementTreeNode || this.data() instanceof GroupElementTreeNode
  }

  get isVirtualGroup() {
    return this.data() instanceof VirtualGroupElementTreeNode
  }

  get isGroupChild() {
    return !!this.data().parent
  }

  get scalingHandler() {
    return this.interactionState().scaling
  }

  get resizingHandler() {
    return this.interactionState().resizing ? this.uiStore.interacting.resizing.handler() : ''
  }

  /**
   * @return
   * 'group-element-focus': 选中组元素
   *
   * ‘group-child-focus': 包含被选中元素的组元素
   *
   * ‘child-focus': 组中被选中的元素
   *
   * 'virtual-child-focus': 虚拟组中被选中的元素
   *
   * ‘element-focus': 选中非组根元素
   *
   */
  get focusType() {
    if (this.isGroup) {
      if (this.isVirtualGroup) {
        return 'group-child-focus'
      } else {
        return this.isGroupChildFocus() ? 'group-child-focus' : 'group-element-focus'
      }
    } else {
      if (this.isVirtualGroupChild()) {
        return 'virtual-child-focus'
      } else {
        return this.data().parent ? 'child-focus' : 'element-focus'
      }
    }
  }

  protected elementTrackBy(index: number, el: IPageElementBase) {
    return el.id + el.updatedAt
  }

  /**
   * 判断指定方向是否可resize
   * @param direction
   */
  protected enabledEdgeDirection(direction: Direction2): boolean {
    switch (this.data().category) {
      case 'group':
        return false
      case 'text':
        if ((this.data().setting as ITextSetting).direction === 'horizontal-tb') {
          return ['e', 'w'].includes(direction)
        } else {
          return ['n', 's'].includes(direction)
        }
      default:
        return true
    }
  }

  protected onMoveHandlerDown($event: MouseEvent) {
    const { offsetLeft, offsetTop } = this.dragContainerRef()?.nativeElement as HTMLElement

    const offsetX = 22 + 30 * cos(this.buttonsTransform().rotate) + $event.offsetX + this.buttonsTransform().x + offsetLeft
    const offsetY = 22 + $event.offsetY + this.buttonsTransform().y + offsetTop

    const { x, y } = transformPosition([offsetX, offsetY], [this.controlPoints().center.x, this.controlPoints().center.y], this.rotationMatrix())
    this.onDragStart({ x, y })
  }

  protected onDragStart(position: IPosition) {
    // this.dragStart.emit(position)
    this.uiStore.setDraggingElement(this.data().id, {
      offset: {
        x: position.x / this.uiStore.zoom(),
        y: position.y / this.uiStore.zoom()
      }
    })
  }

  protected onRotateStart() {
    // const { x, y } = this.data().position
    const { x, y } = this.centerPosition()
    this.uiStore.setRotatingElement(this.data().id, {
      origin: {
        x: multiply(x, this.uiStore.zoom()),
        y: multiply(y, this.uiStore.zoom())
      }
    })
  }

  protected onScaleStart($event: Direction) {
    let startPosition: IPosition
    switch ($event) {
      case 'nw':
        startPosition = this.vertices()['se']
        break
      case 'ne':
        startPosition = this.vertices()['sw']
        break
      case 'se':
        startPosition = this.vertices()['nw']
        break
      case 'sw':
      default:
        startPosition = this.vertices()['ne']
    }
    this.uiStore.setScalingElement(this.data().id, {
      handler: $event,
      startPosition
    })
  }

  protected onResizeStart(direction: Direction | Direction2) {
    let startPosition: IPosition
    if (direction.length === 2) {
      startPosition = this.vertices()[this.rotateVertexDirection()[direction as Direction]]
    } else {
      startPosition = this.centerPosition()
    }

    this.uiStore.setResizingElement(this.data().id, {
      start: {
        x: startPosition.x,
        y: startPosition.y
      },
      handler: direction as Direction | Direction2
    })
  }

  private moveElement() {
    const { x: offsetX, y: offsetY } = this.uiStore.interacting.moving.offset()
    const { x, y } = this.uiStore.interacting.moving.position() || { x: 0, y: 0 }

    const position = {
      x: Number((x - offsetX).toFixed(2)),
      y: Number((y - offsetY).toFixed(2))
    }

    // 限制拖动开始的阈值
    if (
      !this.interactionState.moving() &&
      abs(this.dataPosition().x - position.x) * this.uiStore.zoom() < DRAG_START_DISTANCE &&
      abs(this.dataPosition().y - position.y) * this.uiStore.zoom() < DRAG_START_DISTANCE
    ) {
      return
    }
    patchState(this.interactionState, {
      moving: true
    })
    this.uiStore.setInteractShadowData(this.data().id, {
      position
    })
  }

  private rotateElement(position: IPosition) {
    const { x, y } = position

    const { x: originX, y: originY } = this.uiStore.interacting.rotating.origin()

    const deltaY = y - originY
    const deltaX = x - originX
    let rotation
    if (deltaY === 0) {
      if (deltaX > 0) {
        rotation = (3 * 180) / 2
      } else {
        rotation = 1 / 180
      }
    } else if (deltaX === 0) {
      if (deltaY > 0) {
        rotation = 0
      } else {
        rotation = 180
      }
    } else {
      const buttonRotate = this.buttonsTransform().rotate
      let theta = (180 * atan2(abs(deltaX), abs(deltaY))) / pi
      if (deltaY > 0 && deltaX > 0) {
        if (buttonRotate !== 0) {
          theta -= buttonRotate
        }
        rotation = -theta
      } else if (deltaY < 0 && deltaX > 0) {
        if (buttonRotate !== 0) {
          theta += buttonRotate
        }
        rotation = -180 + theta
      } else if (deltaY > 0 && deltaX < 0) {
        if (buttonRotate !== 0) {
          theta += buttonRotate
        }
        rotation = theta
      } else {
        if (buttonRotate !== 0) {
          theta -= buttonRotate
        }
        rotation = 180 - theta
      }
    }

    if (rotation < -180) {
      rotation = 360 + rotation
    } else if (rotation > 180) {
      rotation = -360 + rotation
    }
    this.uiStore.setInteractShadowData(this.data().id, {
      rotation: Number(rotation.toFixed(2))
    })
    patchState(this.interactionState, {
      rotating: true
    })
  }

  // 以拖动顶点所在对角线为X轴，该顶点为坐标轴原点，计算终点在该X轴上的投影坐标，即为scale的增量距离
  private scaleElement() {
    const { endPosition, handler, startPosition } = this.uiStore.interacting.scaling()
    if (endPosition) {
      const { x: startX, y: startY } = startPosition
      const { x: endX, y: endY } = endPosition
      const x = endX - startX
      const y = endY - startY

      let { x: transformX } = transformPosition([x, y], [0, 0], this.directionRotationMatrix()[handler])

      // 限制缩放方向
      if (handler === 'nw' || handler === 'sw') {
        if (transformX >= 0) {
          transformX = 0
        }
      } else if (handler === 'ne' || handler === 'se') {
        if (transformX <= 0) {
          transformX = 0
        }
      }
      let delta = abs(transformX) - this.diagonal()
      let deltaX = multiply<number>(delta, cos(this.theta()))
      let deltaY = multiply<number>(delta, sin(this.theta()))

      // 限制缩放最小尺寸
      // if (deltaX + this.scaleSize().width < this.minSize().width || deltaY + this.scaleSize().height < this.minSize().width) {
      //   deltaX = this.minSize().width - this.scaleSize().width
      //   deltaY = this.minSize().height - this.scaleSize().height
      //   delta = Math.min(deltaX / cos(this.theta()), deltaY / sin(this.theta()))
      // }
      const assumeWidth = deltaX + this.dataScaleSize().width
      const assumeHeight = deltaY + this.dataScaleSize().height

      // if (assumeWidth < 0) assumeWidth = 0
      // if (assumeHeight < 0) assumeHeight = 0

      if (abs(assumeWidth) <= abs(assumeHeight) && assumeWidth < this.minSize().width) {
        deltaX = this.minSize().width - this.dataScaleSize().width
        // deltaY = deltaX * tan(this.theta())
        delta = deltaX / cos(this.theta())
      } else if (abs(assumeHeight) < abs(assumeWidth) && assumeHeight < this.minSize().height) {
        deltaY = this.minSize().height - this.dataScaleSize().height
        // deltaX = deltaY / tan(this.theta())
        delta = deltaY / sin(this.theta())
      }

      const proportion = (delta + this.diagonal()) / this.diagonal()
      const offset = this.getScalePositionOffset(delta, this.dataScale() * proportion)
      if (this.isVertexScale) {
        patchState(this.interactionState, {
          scaling: handler
        })
        this.uiStore.setInteractShadowData(this.data().id, {
          scale: this.dataScale() * proportion,
          position: {
            x: this.dataPosition().x + offset.x,
            y: this.dataPosition().y + offset.y
          }
        })
      } else {
        patchState(this.interactionState, {
          // scaling: anchor,
          resizing: true
        })
        this.uiStore.setInteractShadowData(this.data().id, {
          size: {
            width: this.dataSize().width * proportion,
            height: this.dataSize().height * proportion
          },
          position: {
            x: this.dataPosition().x + offset.x,
            y: this.dataPosition().y + offset.y
          }
        })
      }
    }
  }

  private resizeElement() {
    const { x: startX, y: startY } = this.uiStore.interacting.resizing.startPosition()
    const { x: endX, y: endY } = this.uiStore.interacting.resizing.endPosition() || { x: 0, y: 0 }
    const direction = this.uiStore.interacting.resizing.handler()

    const x = endX - startX
    const y = endY - startY
    const delta = transformPosition([x, y], [0, 0], this.inverseRotationMatrix())
    let deltaX = delta.x
    let deltaY = delta.y

    const position = { x: 0, y: 0 }
    const size = { width: this.dataScaleSize().width, height: this.dataScaleSize().height }

    const minHeight = this.minSize().height
    const minWidth = this.minSize().width

    switch (direction) {
      case 'n':
        deltaX = 0
        deltaY += size.height / 2
        size.height -= deltaY
        if (size.height < minHeight) {
          const dy = size.height - minHeight
          size.height = minHeight
          deltaY += dy
        }
        break
      case 'e':
        deltaX -= size.width / 2
        deltaY = 0
        size.width += deltaX
        if (size.width < minWidth) {
          const dx = size.width - minWidth
          size.width = minWidth
          deltaX -= dx
        }
        break
      case 's':
        deltaX = 0
        deltaY -= size.height / 2
        size.height = size.height + deltaY
        if (size.height < minHeight) {
          const dy = size.height - minHeight
          size.height = minHeight
          deltaY -= dy
        }
        break
      case 'w':
        deltaX += size.width / 2
        deltaY = 0
        size.width = size.width - deltaX
        if (size.width < minWidth) {
          const dx = size.width - minWidth
          size.width = minWidth
          deltaX += dx
        }
        break
      case 'nw':
        size.width -= deltaX
        size.height -= deltaY
        if (size.width < minWidth) {
          const dx = size.width - minWidth
          size.width = minWidth
          deltaX += dx
        }
        if (size.height < minHeight) {
          const dy = size.height - minHeight
          size.height = minHeight
          deltaY += dy
        }
        break
      case 'ne':
        size.width += deltaX
        size.height -= deltaY
        break
      case 'se':
        size.width += deltaX
        size.height += deltaY
        break
      case 'sw':
        size.width -= deltaX
        size.height += deltaY
        break
    }

    // 以新中心点为原点，在旋转后的坐标系中，新的左上角位置
    position.x = -size.width / 2
    position.y = -size.height / 2

    // 以新中心点为原点，在水平垂直坐标系中，新的左上角位置
    const transform = transformPosition([position.x, position.y], [0, 0], this.rotationMatrix())

    // 以原始中心点为原点，在水平垂直坐标系中，新的中心点位置
    const newCenter = transformPosition([deltaX / 2, deltaY / 2], [0, 0], this.rotationMatrix())

    // 以原始中心点为原点，在水平垂直坐标系中，新的左上角位置
    position.x = transform.x + newCenter.x
    position.y = transform.y + newCenter.y

    // 绕新的中心点反向旋转后的位置
    const { x: transformX, y: transformY } = transformPosition([position.x, position.y], [newCenter.x, newCenter.y], this.inverseRotationMatrix())
    position.x = transformX
    position.y = transformY

    const center = {
      x: this.dataPosition().x + (this.dataSize().width * this.dataScale()) / 2,
      y: this.dataPosition().y + (this.dataSize().height * this.dataScale()) / 2
    }

    // 换算到原始坐标系中的位置
    position.x = Number((position.x + center.x).toFixed(2))
    position.y = Number((position.y + center.y).toFixed(2))

    patchState(this.interactionState, {
      resizing: true
    })

    this.uiStore.setInteractShadowData(this.data().id, {
      size: {
        width: Number((size.width / this.dataScale()).toFixed(2)),
        height: Number((size.height / this.dataScale()).toFixed(2))
      },
      position
    })
  }

  private registerDragEvent() {
    // 拖拽元素变化
    toObservable(this.uiStore.interacting.moving.position)
      .pipe(
        // filter(() => !(this.data().category === ElementTypeEnum.Text && this.isEditing())),
        filter(position => !this.locked() && this.isInteracting() && !_.isUndefined(position) && this.uiStore.isElementReadyInteractionOnPage()),
        distinctUntilChanged((prev, next) => _.isEqual(prev, next)),
        takeUntilDestroyed()
      )
      .subscribe(position => {
        this.moveElement()
      })
  }

  private registerRotation() {
    toObservable(this.uiStore.interacting.rotating.position)
      .pipe(
        filter<unknown, IPosition>(
          (position): position is IPosition => !this.locked() && this.isInteracting() && !_.isUndefined(position) && this.uiStore.isRotatingOnPageReady()
        ),
        distinctUntilChanged((prev, next) => _.isEqual(prev, next)),
        takeUntilDestroyed()
      )
      .subscribe(endPosition => {
        this.rotateElement(endPosition)
      })
  }

  private registerScale() {
    toObservable(this.uiStore.interacting.scaling.endPosition)
      .pipe(
        filter(position => !this.locked() && this.isInteracting() && !_.isUndefined(position) && this.uiStore.isElementReadyInteractionOnPage()),
        distinctUntilChanged((prev, next) => _.isEqual(prev, next)),
        takeUntilDestroyed()
      )
      .subscribe(endPosition => {
        if (endPosition) {
          this.scaleElement()
        }
      })
  }

  private registerResize() {
    toObservable(this.uiStore.interacting.resizing.endPosition)
      .pipe(
        filter(position => !this.locked() && this.isInteracting() && !_.isUndefined(position) && this.uiStore.isElementReadyInteractionOnPage()),
        distinctUntilChanged((prev, next) => _.isEqual(prev, next)),
        takeUntilDestroyed()
      )
      .subscribe(endPosition => {
        if (endPosition) {
          this.resizeElement()
        }
      })
  }

  /**
   * 由于该组件没有css scale，直接用scale和原始size计算新的尺寸应用到css width和height，
   * 而原始position是基于原始size和css scale组合下的位置信息，因此，需要计算新尺寸产生的位置偏移
   */
  private getScalePositionOffset(delta: number, proportion: number) {
    // 以原矩形中心点为原点
    // scale后的矩形中心点位置
    let ox = 0
    let oy = 0

    // 以scale后的矩形中心为原点，scale顶点的位置
    let deltaX = 0
    let deltaY = 0
    const scaling = this.uiStore.interacting.scaling.handler()

    if (scaling === 'nw') {
      ox = -(cos(this.theta() + this.rotateRad()) * delta) / 2
      oy = -(sin(this.theta() + this.rotateRad()) * delta) / 2

      deltaX = -(cos(this.theta() + this.rotateRad()) * (this.diagonal() + delta)) / 2
      deltaY = -(sin(this.theta() + this.rotateRad()) * (this.diagonal() + delta)) / 2
    } else if (scaling === 'sw') {
      ox = -(cos(this.theta() - this.rotateRad()) * delta) / 2
      oy = (sin(this.theta() - this.rotateRad()) * delta) / 2

      deltaX = -(cos(this.theta() - this.rotateRad()) * (this.diagonal() + delta)) / 2
      deltaY = (sin(this.theta() - this.rotateRad()) * (this.diagonal() + delta)) / 2
    } else if (scaling === 'ne') {
      ox = (cos(this.theta() - this.rotateRad()) * delta) / 2
      oy = -(sin(this.theta() - this.rotateRad()) * delta) / 2

      deltaX = (cos(this.theta() - this.rotateRad()) * (this.diagonal() + delta)) / 2
      deltaY = -(sin(this.theta() - this.rotateRad()) * (this.diagonal() + delta)) / 2
    } else {
      ox = (cos(this.theta() + this.rotateRad()) * delta) / 2
      oy = (sin(this.theta() + this.rotateRad()) * delta) / 2

      deltaX = (cos(this.theta() + this.rotateRad()) * (this.diagonal() + delta)) / 2
      deltaY = (sin(this.theta() + this.rotateRad()) * (this.diagonal() + delta)) / 2
    }

    // 终点相对新的矩形圆心旋转-rotation后的位置
    const position = multiply(this.inverseRotationMatrix(), [deltaX, deltaY])
    // 终点实际位置
    const x = position.get([0]) + ox + this.dataScaleSize().width / 2
    const y = position.get([1]) + oy + this.dataScaleSize().height / 2
    if (scaling === 'nw') {
      return {
        x,
        y
      }
    } else if (scaling === 'sw') {
      return {
        x,
        y: y - this.dataSize().height * proportion
      }
    } else if (scaling === 'ne') {
      return {
        x: x - this.dataSize().width * proportion,
        y
      }
    } else {
      return {
        x: x - this.dataSize().width * proportion,
        y: y - this.dataSize().height * proportion
      }
    }
  }
}
