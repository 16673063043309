<div class="relative flex h-full w-full items-center justify-center" [class.invisible]="isCropImage()" [ngClass]="focusType">
  @if (category() === 'text') {
    <!--    <ace-text-interact class="element-interact" (editing)="onElementEdit($event)" ></ace-text-interact>-->
  } @else if (category() === 'group') {
    @if (!highLightOnly()) {
      <div class="absolute left-0 top-0" [style.scale]="scale()">
        <ace-element-interact-box
          *ngFor="let child of children(); trackBy: elementTrackBy"
          [data]="child"
          [isVirtualGroupChild]="isVirtualGroup"
        ></ace-element-interact-box>
      </div>
    }
  } @else if (category() === 'line') {
    <ace-line-interact></ace-line-interact>
  }
  @if (selected() && !isVirtualGroupChild() && !isInteractingOnPage() && focusType !== 'child-focus' && !locked()) {
    <div
      #dragContainerRef
      class="absolute flex w-[96px] items-center justify-center p-[22px]"
      [style.transform]="'translate(' + buttonsTransform().x + 'px, ' + buttonsTransform().y + 'px)' + 'rotate(' + buttonsTransform().rotate + 'deg)'"
    >
      <ace-element-rotate-handler class="pointer-events-auto" (rotateStart)="onRotateStart()"></ace-element-rotate-handler>
      <!--      文本元素在编辑时不能移动位置-->
      <!-- [class.hidden]="category() === 'text' && isEditing()" -->
      <ace-element-move-handler class="pointer-events-auto ml-[8px]" (dragStart)="onMoveHandlerDown($event)"></ace-element-move-handler>
    </div>
  }
  <div class="absolute left-0 top-0 h-full w-full">
    @if (selected() || focusType !== 'child-focus') {
      @for (direction of edgeDirections(); let i = $index; track i) {
        <ace-element-edge-handler
          class="pointer-events-auto"
          [position]="controlPoints()[direction]"
          [direction]="direction"
          [hidden]="
            isVirtualGroupChild() ||
            (!!resizingHandler && resizingHandler !== direction) ||
            !!scalingHandler ||
            interactionState.moving() ||
            interactionState.rotating()
          "
          [dashed]="focusType === 'group-child-focus'"
          [disabled]="!enabledEdgeDirection(direction) || this.highLightOnly() || focusType !== 'element-focus' || locked()"
          (resizeStart)="onResizeStart(direction)"
        ></ace-element-edge-handler>
      }
    }

    @if (selected() && !isVirtualGroupChild() && !interactionState.moving() && !interactionState.rotating() && focusType !== 'child-focus') {
      @for (direction of vertexDirections(); let i = $index; track i) {
        <ace-element-vertex-handler
          class="pointer-events-auto"
          [position]="controlPoints()[direction]"
          [direction]="direction"
          [lockRatio]="lockRatio()"
          [hidden]="((!!resizingHandler || !!scalingHandler) && resizingHandler !== direction && scalingHandler !== direction) || locked()"
          (scaleStart)="onScaleStart(direction)"
          (resizeStart)="onResizeStart(direction)"
        ></ace-element-vertex-handler>
      }
    }
  </div>
</div>
