import { CommonModule } from '@angular/common'
import { AfterContentInit, ChangeDetectionStrategy, Component, contentChildren } from '@angular/core'

import { RulePopupRowComponent } from '#modules/workspace/components/canvas/rule-popup-row.component'

/**
 * 辅助线弹出框行组件
 */
@Component({
  selector: 'ace-rule-popup-row-group',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div class="p-1">
      <ng-content></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulePopupRowGroupComponent implements AfterContentInit {
  /**
   * 子组件
   */
  contents = contentChildren(RulePopupRowComponent)

  ngAfterContentInit() {
    // 监听子组件的激活状态变更事件 统一处理组件的激活状态
    // 当一个组件被激活时，其他组件的激活状态应该被取消
    this.contents().forEach(cmp => {
      cmp.aceRulePopupRowChange.subscribe((active: boolean) => {
        if (active) {
          this.contents()
            .filter(c => c !== cmp)
            .forEach(c => {
              if (c.aceRulePopupRowActive()) {
                c.setState(false)
              }
            })
        }
      })
    })
  }
}
