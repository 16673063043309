import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, effect, HostBinding, input } from '@angular/core'

import { IParaSetting, ISize, ITextSetting } from '#modules/workspace/types/element'

import { TextInputCharComponent } from '../text-input-char/text-input-char.component'

@Component({
  selector: 'ace-text-input-paragraph',
  standalone: true,
  imports: [CommonModule, TextInputCharComponent],
  templateUrl: './text-input-paragraph.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextInputParagraphComponent {
  @HostBinding('class') class = 'block'

  textSetting = input.required<ITextSetting>()
  paraSetting = input.required<IParaSetting>()
  horizontalMode = input.required<boolean>()

  size = input.required<ISize>()

  hasStroke = computed(() => this.paraSetting().chars.some(char => char.textStroke))

  fontSize = computed(() => {
    return Math.max(...this.paraSetting().chars.map(char => char.fontSize))
  })

  lineHeight = computed(() => {
    return this.paraSetting().lineHeight * this.fontSize()
  })

  interactWidth = computed(() => {
    if (this.horizontalMode()) {
      if (this.textSetting().autoSize) {
        return 'max-content'
      } else {
        return this.size().width + 'px'
      }
    } else {
      return 'auto'
    }
  })

  interactHeight = computed(() => {
    if (!this.horizontalMode()) {
      if (this.textSetting().autoSize) {
        return 'max-content'
      } else {
        return this.size().height + 'px'
      }
    } else {
      return 'auto'
    }
  })

  constructor() {
    // effect(() => {
    //   console.log(this.paraSetting(), this.textSetting())
    // })
  }
}
