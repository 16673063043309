import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'

import { max } from 'mathjs'

import { calculateBoundingBox } from '@libs/algorithm'

import { IPageBackground } from '#modules/workspace/types/page'
import { getFlipScale } from '#shared/utils/image'

@Component({
  selector: 'ace-page-background',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './page-background.component.html',
  styleUrl: './page-background.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageBackgroundComponent {
  background = input.required<IPageBackground>()
  pageSize = input.required<{ width: number; height: number }>()

  /**
   * 计算背景图片外接矩形容器大小
   */
  backgroundContainerStyle = computed(() => {
    const { width, height } = this.pageSize()
    const rotation = this.background().rotation
    if (rotation) {
      const rect = calculateBoundingBox(width, height, rotation)
      const afterWidth = width > height ? (rect.boundingHeight / height) * width : rect.boundingWidth
      const afterHeight = width > height ? rect.boundingHeight : (rect.boundingWidth / width) * height
      return {
        width: afterWidth + 'px',
        height: afterHeight + 'px',
        transform: `translate(${(width - afterWidth) / 2}px, ${(height - afterHeight) / 2}px) rotate(${rotation}deg)`
      }
    } else {
      return {
        width: `${width}px`,
        height: `${height}px`
      }
    }
  })

  /**
   * 计算背景图片的样式
   */
  backgroundStyle = computed(() => {
    const { color: backgroundColor, image: backgroundImage, opacity, flip } = this.background()
    if (backgroundColor || backgroundImage) {
      const background = []
      const resizeRange = [300, 600, 1200, 2400, 4800]
      if (backgroundImage) {
        const { width, height } = this.pageSize()
        const maxLength = max(width, height)
        const resize = resizeRange.find(size => size > maxLength) || resizeRange[resizeRange.length - 1]
        background.push(`url(${backgroundImage}?x-oss-process=image/resize,l_${resize})`)
      }
      if (backgroundColor) {
        background.push(backgroundColor)
      }

      const flipScale = flip ? getFlipScale(flip) : ''

      return {
        background: background.join(', '),
        opacity: opacity,
        transform: `${flipScale}`
      }
    } else {
      return {}
    }
  })
}
