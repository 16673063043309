<div
  class="absolute origin-top-left overflow-hidden"
  [style.width.px]="page().size.width"
  [style.height.px]="page().size.height"
  [style.scale]="pageScale()"
  [cdkContextMenuDisabled]="!isBackgroundSelected()"
  (contextmenu)="handleContextMenu($event)"
  [cdkContextMenuTriggerFor]="contextMenu"
>
  <div class="elements-container relative h-full w-full overflow-hidden bg-cover bg-center bg-no-repeat" #elementsContainerRef>
    <ace-page-background [pageSize]="page().size" [background]="page().background"></ace-page-background>

    @if (selectedRootElements().length > 1 && virtualGroup()) {
      <ace-virtual-group-shadow
        [data]="virtualGroup()!"
        [interactionState]="elementInteractionStates()[virtualGroup()!.id]"
        (dragStart)="onElementStartDrag($event)"
        (mousedown)="menu.close()"
        (contextmenu)="$event.preventDefault()"
        [cdkContextMenuTriggerFor]="virtualGroupContextMenu"
        #menu="cdkContextMenuTriggerFor"
      ></ace-virtual-group-shadow>
      <ng-template #virtualGroupContextMenu>
        <ace-context-menu [menuItems]="elementsContextMenu()[virtualGroup()!.id]"></ace-context-menu>
      </ng-template>
    }

    @for (el of pageRootElements(); track el.id) {
      <ace-element-container
        #elementComponent
        [style.z-index]="$index"
        (mouseenter)="!hasInteractingElement() && highLightElement($event, el)"
        (mouseleave)="removeHighLightElement($event, el.id)"
        (mousedown)="menu.close()"
        (contextmenu)="$event.preventDefault()"
        [data]="el"
        [interactionState]="elementInteractionStates()[el.id]"
        [cdkContextMenuTriggerFor]="elContextMenu"
        [cdkContextMenuTriggerData]="{ id: el.id }"
        #menu="cdkContextMenuTriggerFor"
        [preview]="false"
        [locked]="el.locked"
        (dragStart)="onElementStartDrag($event)"
      ></ace-element-container>
    }
    <ng-template #elContextMenu let-id="id">
      <ace-context-menu [menuItems]="elementsContextMenu()[isVirtualChild(id) ? virtualGroup()!.id : id]"></ace-context-menu>
    </ng-template>
  </div>
</div>

@if (isBackgroundSelected()) {
  <div class="border-primary pointer-events-none absolute -inset-0.5 border bg-transparent"></div>
}

<div class="interact-element-editor-container pointer-events-none">
  @if (textStore.element() && textStore.editing()) {
    <ace-text-input-container
      class="text-input-container absolute origin-top-left"
      [style.width.px]="page().size.width"
      [style.height.px]="page().size.height"
      [style.scale]="pageScale()"
    />
  }
</div>

<div class="selections-container h-full w-full bg-transparent">
  @if (virtualGroup()) {
    <ace-element-interact-box #virtualInteractBox [data]="virtualGroup()!"> </ace-element-interact-box>
    @if (!virtualInteractBox.isInteractingOnPage()) {
      <ace-element-toolbox
        [data]="virtualGroup()!"
        [boundingBox]="virtualInteractBox.bounding()"
        [contextMenu]="elementsContextMenu()[virtualGroup()!.id]"
      ></ace-element-toolbox>
    }
  } @else {
    @for (el of interactElements(); track el.id) {
      <ace-element-interact-box #boxComponent [data]="el" [highLightOnly]="elementHighLightOnly(el.id)"></ace-element-interact-box>
      @if (boxComponent.selected() && !boxComponent.isInteractingOnPage() && (!textStore.editing() || textStore.element()?.id !== el.id)) {
        <ace-element-toolbox [data]="el" [boundingBox]="boxComponent.bounding()" [contextMenu]="elementsContextMenu()[el.id]"></ace-element-toolbox>
      }
    }
  }
</div>
<div class="guide-lines-container pointer-events-none absolute left-0 top-0 h-full w-full bg-transparent">
  @for (line of elementGuidLines(); track $index) {
    @if (line.type === 'vertical') {
      <div
        class="border-primary absolute border-l border-dashed"
        [style.left.px]="line.position"
        [style.top.px]="line.from"
        [style.height.px]="line.to - line.from"
      ></div>
    } @else {
      <div
        class="border-primary absolute border-t border-dashed"
        [style.top.px]="line.position"
        [style.left.px]="line.from"
        [style.width.px]="line.to - line.from"
      ></div>
    }
  }
</div>

<ng-template #contextMenu>
  <ace-context-menu [menuItems]="pageContextMenu()"></ace-context-menu>
</ng-template>
