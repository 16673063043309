<ng-container *ngTemplateOutlet="contextMenu; context: { $implicit: menuItems() }"></ng-container>

<ng-template #contextMenu let-items>
  <ul
    class="text-tertiary-600 dark:text-tertiary-300 dark:bg-tertiary-950 flex w-64 flex-col items-start justify-start overflow-hidden rounded-lg bg-neutral-50 text-sm shadow"
    cdkMenu
    cdkTargetMenuAim
  >
    @for (item of items; track $index) {
      @if (!item.hide) {
        @if (item.br) {
          <mat-divider class="w-full"></mat-divider>
        }
        @if (item.children) {
          <li
            class="context-menu-item flex h-10 w-full items-center justify-between px-3"
            cdkMenuItem
            [class.disabled]="item.disabled"
            [cdkMenuItemDisabled]="item.disabled"
            [cdkMenuTriggerFor]="child"
          >
            <div class="inline-flex items-center gap-3">
              @if (item.icon) {
                <mat-icon class="menu-item-icon" [svgIcon]="item.icon"></mat-icon>
              }
              <span class="menu-item-name">{{ item.label | transloco }}</span>
            </div>
            <div>
              <mat-icon [svgIcon]="'custom:menu-right'"></mat-icon>
            </div>
            <ng-template #child>
              <ace-context-menu [menuItems]="item.children" class="ml-2"></ace-context-menu>
            </ng-template>
          </li>
        } @else {
          <li
            class="context-menu-item flex h-10 w-full items-center justify-between px-3"
            cdkMenuItem
            [class.disabled]="item.disabled"
            [cdkMenuItemDisabled]="item.disabled"
            (click)="item.callback && !item.disabled ? item.callback($event) : null"
          >
            <div class="inline-flex items-center gap-3">
              @if (item.icon) {
                <mat-icon class="menu-item-icon" [svgIcon]="item.icon"></mat-icon>
              }
              <span class="menu-item-name">{{ item.label | transloco }}</span>
            </div>
            <div>
              @if (item.shortcut) {
                <span class="menu-item-shortcut">{{ item.shortcut.join('+') }}</span>
              }
            </div>
          </li>
        }
      }
    }
  </ul>
</ng-template>
