import { OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, effect, ElementRef, inject, viewChild } from '@angular/core'
import { MatButton } from '@angular/material/button'
import { MatIconModule } from '@angular/material/icon'
import { Router } from '@angular/router'

import { ApiService } from '#core/services/api.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { ProjectService } from '#shared/services/project/project.service'

import { DownloadModalComponent } from '../download-modal/download-modal.component'
import { MetaBarComponent } from '../meta-bar/meta-bar.component'

@Component({
  selector: 'ace-header',
  standalone: true,
  imports: [CommonModule, MatIconModule, DownloadModalComponent, OverlayModule, MetaBarComponent, MatButton],
  templateUrl: './header.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  projectService = inject(ProjectService)
  apiService = inject(ApiService)
  uiStore = inject(StageUiStore)
  router = inject(Router)

  project = this.uiStore.currentProject

  title = ''
  titleInput = viewChild<ElementRef<HTMLInputElement>>('input')

  page = this.uiStore.onStagePage

  active: 'download' | '' = ''

  editingTitle = false

  downloadModalOpen = false

  canUndo = this.uiStore.canUndo
  canRedo = this.uiStore.canRedo

  constructor() {
    effect(() => {
      this.title = this.project().title
      console.log(this.project(), this.project().title)
    })
  }
  back() {
    this.router.navigate([''])
  }

  undo() {
    this.uiStore.undo()
  }

  redo() {
    this.uiStore.redo()
  }

  onKeyup(e: KeyboardEvent) {
    if (e.key === 'Enter') {
      this.onBlur()
    }
  }

  editTitle() {
    this.editingTitle = true
    setTimeout(() => {
      this.titleInput()?.nativeElement.focus()
    }, 0)
  }

  onBlur() {
    this.title = this.titleInput()?.nativeElement.value || ''
    this.uiStore.updateProject(this.project().id, { title: this.title })
    this.editingTitle = false
  }

  openDownloadModal() {
    this.downloadModalOpen = true
    this.active = 'download'
  }

  closeDownloadModal(e?: MouseEvent) {
    e?.stopPropagation()

    this.downloadModalOpen = false
    this.active = ''
  }
}
