import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, input } from '@angular/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ace-search-no-result',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './searchNoResult.component.html',
  styleUrl: './searchNoResult.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchNoResultComponent {
  //当两个tab都用到了这个组件时，会导致第二个tab的这个svg加载不完全，考虑是svg内的fill属性引用id导致的，因此这里用数组来存储id，保证每个tab里的svg都有自己的fill的id
  // ids = input.required<string[]>()
}
