import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, ElementRef, signal, viewChild } from '@angular/core'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import { ScrollbarDirective } from '@libs/ng-shared/directives/scrollbar'

@Component({
  selector: 'ace-scroll-row',
  standalone: true,
  imports: [CommonModule, MatButton, MatIcon, ScrollbarDirective, MatIconButton],
  template: `
    <div class="relative w-full overflow-hidden" #tagContainer>
      @if (showScrollHelper()) {
        <!--          左右翻页-->
        <div (click)="handleScrollTag('left')" class="w-26 gradient-right absolute left-0 z-10 flex h-full -translate-x-1/3 items-center justify-center pb-3">
          <mat-icon svgIcon="editorup:Left (左)" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>
        </div>
        <div (click)="handleScrollTag('right')" class="w-26 gradient-left absolute right-0 z-10 flex h-full translate-x-1/3 items-center justify-center pb-3">
          <mat-icon svgIcon="editorup:Right (右)" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>
        </div>
      }
      <!-- all -->
      <div class="flex w-full flex-grow items-start gap-2 overflow-hidden pb-3" aceScrollbar [aceScrollbarOptions]="{ suppressScrollY: true }">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: `
    .gradient-right {
      background: linear-gradient(to right, #fff 55%, rgba(255, 255, 255, 0) 100%);
    }
    .gradient-left {
      background: linear-gradient(to left, #fff 55%, rgba(255, 255, 255, 0) 100%);
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollRowComponent {
  /**
   * 当前tag父级标签是否出现滚动条
   */
  showScrollHelper = signal<boolean>(true)
  /**
   * 标签容器
   */
  tagContainer = viewChild.required<ElementRef<HTMLDivElement>>('tagContainer')

  /**
   * PrefectScrollBar
   */
  scrollbar = viewChild.required(ScrollbarDirective)

  /**
   * 处理tag滚动事件 左右翻页
   * @param direction
   */
  handleScrollTag(direction: 'left' | 'right') {
    console.log(this.scrollbar().position(), this.scrollbar().geometry())
    const { x } = this.scrollbar().geometry()
    const { clientWidth } = this.tagContainer().nativeElement
    if (direction === 'left') {
      this.scrollbar().scrollToX(x - clientWidth - 80, 200)
    } else {
      this.scrollbar().scrollToX(x + clientWidth - 80, 200)
    }
  }
}
