<div class="relative flex h-full select-none items-start" [style.z-index]="showMask() ? '20' : '10'">
  <div class="relative flex w-16 origin-top-left" #sidebarMenu>
    <div class="dark:bg-tertiary-950 flex w-full flex-col rounded-xl bg-white pb-2">
      @for (menu of menus; track $index) {
        <div
          [class]="{ active: activeIndex() === $index }"
          class="group relative flex h-16 max-h-16 flex-col items-center justify-evenly rounded-xl border-none bg-transparent outline-none"
          (click)="handleMenuClick($index)"
          [class.mb-2.5]="!$last"
        >
          <button
            class="box-content h-6 min-h-6 w-6 min-w-6 rounded-lg p-2"
            [class]="
              activeIndex() === $index
                ? 'bg-primary'
                : ' group-hover:bg-primary-600 group-hover:bg-opacity-10 dark:group-hover:bg-white dark:group-hover:bg-opacity-5'
            "
          >
            <mat-icon
              [svgIcon]="menu.icon"
              class="icon-size-6"
              [class]="activeIndex() === $index ? 'text-white dark:text-white' : 'group-hover:text-tertiary-700 dark:group-hover:text-tertiary-50'"
            ></mat-icon>
          </button>
          <span
            [class]="activeIndex() === $index ? 'text-tertiary-900 dark:text-white' : ' group-hover:text-tertiary-900    dark:group-hover:text-white '"
            class="font-sans text-sm leading-3"
            >{{ menu.label }}</span
          >
          @if (menu.outlet) {
            <ng-container *ngTemplateOutlet="menu.outlet"></ng-container>
          }
        </div>
      }
    </div>
  </div>

  <div class="min-w-50 dark:bg-tertiary-950 ml-2 h-full rounded-xl bg-neutral-50" [hidden]="isDynamicEmpty()">
    <ng-container #dynamicContainer></ng-container>
  </div>
</div>

<ng-template #ai>
  <div class="absolute right-3 top-2 flex h-3 w-[18px] items-center justify-center rounded-[5.5px] bg-green-400 shadow-[0_2.75px_2.75px_0_rgba(0,0,0,0.25)]">
    <span class="font-sans text-xs leading-[8.25px] text-black">AI</span>
  </div>
</ng-template>

@if (showMask()) {
  <div class="fixed inset-0 z-10 bg-black bg-opacity-85"></div>
}
