<ace-loading-bar [autoMode]="false"></ace-loading-bar>

<div class="flex h-screen w-screen select-none flex-col bg-white">
  <!--Header-->
  <div class="border-tertiary-100 flex h-16 w-full items-center justify-between border-b px-5">
    <!-- Logo -->
    <img
      class="h-9 w-40 flex-shrink-0"
      width="159"
      height="36"
      ngSrc="https://core-dev.oss-cn-hangzhou.aliyuncs.com/static/images/wps/wps_%E6%85%A7%E6%8A%A5.png"
      [loaderParams]="{ original: true }"
      alt="WPS_慧报"
    />
    <!-- user menu  -->
    <div class="z-49 bg-card relative flex h-16 w-full items-center px-4 md:px-6 dark:bg-transparent print:hidden">
      <div class="ml-auto flex items-center space-x-0.5 pl-2 sm:space-x-2">
        <!--        <ace-fullscreen class="hidden md:block"></ace-fullscreen>-->
        <ace-user></ace-user>
      </div>
    </div>
  </div>
  <!--  content-->
  <div class="flex h-0 flex-grow gap-2 overflow-hidden">
    <div class="w-50 flex flex-col gap-2 p-2">
      <!-- Menus -->
      @for (menu of menus; track menu) {
        <div #router="routerLinkActive" class="w-full" [routerLink]="menu.link" routerLinkActive>
          <div
            [class]="router.isActive ? 'bg-primary-400 text-white' : 'hover:bg-primary-600 text-current hover:bg-opacity-10'"
            class="flex h-10 w-full cursor-pointer items-center gap-3 rounded-lg p-3 px-3"
          >
            <mat-icon class="icon-size-5 m-0" [class]="router.isActive ? 'text-white' : 'text-current'" [svgIcon]="menu.icon"></mat-icon>
            <span class="text-lg font-normal">{{ menu.name | transloco }}</span>
          </div>
        </div>
      }
    </div>
    <!-- Content -->
    <div class="h-full w-0 flex-grow overflow-hidden">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
