import { CdkContextMenuTrigger } from '@angular/cdk/menu'
import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  HostBinding,
  HostListener,
  inject,
  Injector,
  input,
  Input,
  output,
  signal,
  untracked,
  viewChild,
  viewChildren
} from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import _ from 'lodash'
import { abs, max, min } from 'mathjs'
import { combineLatestWith, distinctUntilChanged, filter, map, merge, skip, takeWhile } from 'rxjs'

import { calculateBoundingBox, childrenRealSetting, IPosition, isRectsIntersect, Rectangle, rectContainerPoint, scalePosition } from '@libs/algorithm'

import { ApiService } from '#core/services/api.service'
import { ElementContainerComponent } from '#modules/workspace/components/element-container/element-container.component'
import { ElementInteractBoxComponent } from '#modules/workspace/components/element-interact/element-interact-box/element-interact-box.component'
import { ElementToolboxComponent } from '#modules/workspace/components/element-interact/element-toolbox/element-toolbox.component'
import { ElementBaseComponent } from '#modules/workspace/components/element/element-base.component'
import { PageBackgroundComponent } from '#modules/workspace/components/page-background/page-background.component'
import { isImageSetting } from '#modules/workspace/components/setting-panel/util'
import { VirtualGroupShadowComponent } from '#modules/workspace/components/virtual-group-shadow/virtual-group-shadow.component'
import { GroupElementTreeNode, PageElementTreeNode } from '#modules/workspace/models/element-node'
import { ElementService } from '#modules/workspace/services/element.service'
import { PageService } from '#modules/workspace/services/page.service'
import { GuideType, IGuidLine } from '#modules/workspace/store'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { TextStore } from '#modules/workspace/store/text.store'
import { ADSORB_DISTANCE, ElementTypeEnum } from '#modules/workspace/types/constants'
import { CreateVirtualElementParams, IPageElementBase, IShadowElement } from '#modules/workspace/types/element'
import { PageListNode } from '#modules/workspace/types/page'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ContextMenuComponent, IContextMenuItem } from '#shared/components/context-menu/context-menu.component'
import { ProjectService } from '#shared/services/project/project.service'
import { getFlipScale } from '#shared/utils/image'

import { TextInputContainerComponent } from '../element/text/text-input/text-input-container/text-input-container.component'

@Component({
  selector: 'ace-page',
  standalone: true,
  imports: [
    CommonModule,
    ElementInteractBoxComponent,
    ElementContainerComponent,
    ElementToolboxComponent,
    ContextMenuComponent,
    CdkContextMenuTrigger,
    TextInputContainerComponent,
    PageBackgroundComponent,
    VirtualGroupShadowComponent
  ],
  templateUrl: './page.component.html',
  styleUrl: './page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageComponent implements AfterViewInit {
  apiService = inject(ApiService)
  elementService = inject(ElementService)
  workspaceService = inject(WorkspaceService)
  textStore = inject(TextStore)
  page = input.required<PageListNode, PageListNode>({
    transform: value => {
      return value
    }
  })
  pageScale = input(1)
  scrollTop = input(0)
  scrollLeft = input(0)
  marqueeSelectArea = input<{ top: number; left: number; right: number; bottom: number } | null>(null)
  elementContainerComponents = viewChildren(ElementContainerComponent)

  rendered = output<boolean>()

  triggerMenuPoint = signal({ x: 0, y: 0 })

  imageInstance = computed(() => {
    const container = this.elementContainerComponents()
    const imageComponent = container.find(cmp => cmp.data().category === ElementTypeEnum.Image)?.elementComponent?.()
    if (imageComponent) {
      return (imageComponent as ElementBaseComponent<ElementTypeEnum.Image>).imageInstance()
    } else {
      return undefined
    }
  })

  replaceBackgroundEnable = computed(() => {
    const imageInstance = this.imageInstance()
    return imageInstance && !imageInstance.willUpload()
  })

  // pageElements = computed(() => this.page().elementTreeNodes())
  /**
   * 计算背景图片的样式
   */
  backgroundStyle = computed(() => {
    const { color: backgroundColor, image: backgroundImage, opacity, flip } = this.page().background
    if (backgroundColor || backgroundImage) {
      const background = []
      const resizeRange = [300, 600, 1200, 2400, 4800]
      if (backgroundImage) {
        const { width, height } = this.page().size
        const maxLength = max(width, height)
        const resize = resizeRange.find(size => size > maxLength) || resizeRange[resizeRange.length - 1]
        background.push(`url(${backgroundImage}?x-oss-process=image/resize,l_${resize})`)
      }
      if (backgroundColor) {
        background.push(backgroundColor)
      }

      const flipScale = flip ? getFlipScale(flip) : ''

      return {
        background: background.join(', '),
        opacity: opacity,
        transform: `${flipScale}`
      }
    } else {
      return {}
    }
  })

  /**
   * 计算背景图片外接矩形容器大小
   */
  backgroundContainerStyle = computed(() => {
    const { width, height } = this.page().size
    const rotation = this.page().background.rotation
    if (rotation) {
      const rect = calculateBoundingBox(width, height, rotation)
      const afterWidth = width > height ? (rect.boundingHeight / height) * width : rect.boundingWidth
      const afterHeight = width > height ? rect.boundingHeight : (rect.boundingWidth / width) * height
      return {
        width: afterWidth + 'px',
        height: afterHeight + 'px',
        transform: `translate(${(width - afterWidth) / 2}px, ${(height - afterHeight) / 2}px) rotate(${rotation}deg)`
      }
    } else {
      return {
        width: `${width}px`,
        height: `${height}px`
      }
    }
  })

  isBackgroundSelected = computed(() => {
    return this.uiStore.onStagePage()?.id === this.page().id && this.uiStore.selectedTarget() !== 'element'
  })

  elementTreeNodes = computed(() => {
    return this.page().elementTreeNodes()
  })

  pageRootElements = computed<Array<PageElementTreeNode>>(() => {
    return this.elementTreeNodes().filter(node => node.visible)
  })
  interactElements = computed<PageElementTreeNode[]>(() => {
    const highLightElements = this.pageRootElements().filter(el => this.highLightElementIds().includes(el.id))
    return _.unionWith(highLightElements, this.selectedRootElements(), (a, b) => a.id === b.id)
  })

  hasInteractingElement = computed(() => this.uiStore.isElementInteractingOnPage())

  isBackgroundEmpty = computed(() => {
    const background = this.page().background
    return !background.image && (!background.color || background.color.toLowerCase() === '#ffffff')
  })

  protected elementsContextMenu = computed(() => {
    const menus: Record<string, IContextMenuItem[]> = {}

    const virtualGroup = this.virtualGroup()
    const elements = virtualGroup ? [virtualGroup] : this.selectedRootElements()
    const alignDistributeStatus = this.elementService.alignDistributeStatus()
    const size = elements.length
    elements.forEach((element, index) => {
      const childLocked = element.children?.some(child => child.locked)
      menus[element.id] = [
        {
          label: '复制',
          icon: 'custom:element-copy',
          shortcut: ['Ctrl', 'C'],
          callback: this._elementService.copyElements.bind(this._elementService),
          hide: this.page().locked || element.locked
        },
        {
          label: '复制样式',
          icon: 'custom:copy-style',
          shortcut: ['⌥', '⌘', 'C'],
          // callback: this._elementService.copyElements.bind(this._elementService, [element]),
          hide: true
        },
        {
          label: '粘贴',
          icon: 'custom:element-paste',
          shortcut: ['Ctrl', 'V'],
          callback: this.workspaceService.paste.bind(this.workspaceService),
          hide: this.page().locked || element.locked
        },
        {
          label: '创建副本',
          icon: 'custom:page-duplicate',
          shortcut: ['Ctrl', 'D'],
          callback: this.duplicateSelectedElement.bind(this),
          hide: this.page().locked || element.locked
        },
        {
          label: '删除',
          icon: 'custom:page-delete',
          // shortcut: ['DELETE'],
          callback: this.deleteSelectedElement.bind(this),
          hide: this.page().locked || element.locked
        },
        {
          label: '隐藏',
          icon: 'custom:page-hidden',
          callback: this._elementService.hideElements.bind(this._elementService),
          hide: this.page().locked || element.locked
        },
        {
          label: '图层',
          icon: 'custom:element-layer',
          children: [
            {
              label: '上移',
              icon: 'custom:layer-up',
              disabled: !this._elementService.canMoveUp(),
              shortcut: ['Ctrl', ']'],
              callback: this._elementService.moveUp.bind(this._elementService)
            },
            {
              label: '移动到最上层',
              icon: 'custom:layer-top',
              disabled: !this._elementService.canMoveUp(),
              shortcut: ['Ctrl', 'Alt', ']'],
              callback: this._elementService.moveTop.bind(this._elementService)
            },
            {
              label: '下移',
              icon: 'custom:layer-down',
              disabled: !this._elementService.canMoveDown(),
              shortcut: ['Ctrl', '['],
              callback: this._elementService.moveDown.bind(this._elementService)
            },
            {
              label: '移动到最下层',
              icon: 'custom:layer-bottom',
              disabled: !this._elementService.canMoveDown(),
              shortcut: ['Ctrl', 'Alt', '['],
              callback: this._elementService.moveBottom.bind(this._elementService)
            }
          ],
          hide: this.page().locked || element.locked || this.isVirtualGroup(element.id)
        },
        {
          label: '页面对齐',
          icon: 'editorup:align-left',
          children: [
            {
              label: '左对齐',
              icon: 'editorup:align-left',
              disabled: !alignDistributeStatus.alignLeft,
              callback: this.elementService.alignLeft.bind(this.elementService, this.selectedRootElements())
            },
            {
              label: '水平居中',
              icon: 'editorup:align-horizontally',
              disabled: !alignDistributeStatus.alignHorizontal,
              callback: this.elementService.alignHorizontal.bind(this.elementService, this.selectedRootElements())
            },
            {
              label: '右对齐',
              icon: 'editorup:align-right',
              disabled: !alignDistributeStatus.alignRight,
              callback: this.elementService.alignRight.bind(this.elementService, this.selectedRootElements())
            },
            {
              label: '顶部对齐',
              icon: 'editorup:align-top',
              disabled: !alignDistributeStatus.alignTop,
              callback: this.elementService.alignTop.bind(this.elementService, this.selectedRootElements())
            },
            {
              label: '垂直居中',
              icon: 'editorup:align-vertical',
              disabled: !alignDistributeStatus.alignVertical,
              callback: this.elementService.alignVertical.bind(this.elementService, this.selectedRootElements())
            },
            {
              label: '底部对齐',
              icon: 'editorup:align-bottom',
              disabled: !alignDistributeStatus.alignBottom,
              callback: this.elementService.alignBottom.bind(this.elementService, this.selectedRootElements())
            }
          ],
          hide: this.page().locked || element.locked || this.isVirtualGroup(element.id)
        },
        {
          label: '素材对齐',
          icon: 'editorup:align-left',
          children: [
            {
              label: '左对齐',
              icon: 'editorup:align-left',
              disabled: !alignDistributeStatus.alignLeft,
              callback: this._elementService.alignLeft.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '水平居中',
              icon: 'editorup:align-horizontally',
              disabled: !alignDistributeStatus.alignHorizontal,
              callback: this._elementService.alignHorizontal.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '右对齐',
              icon: 'editorup:align-right',
              disabled: !alignDistributeStatus.alignRight,
              callback: this._elementService.alignRight.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '顶部对齐',
              icon: 'editorup:align-top',
              disabled: !alignDistributeStatus.alignTop,
              callback: this._elementService.alignTop.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '垂直居中',
              icon: 'editorup:align-vertical',
              disabled: !alignDistributeStatus.alignVertical,
              callback: this._elementService.alignVertical.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '底部对齐',
              icon: 'editorup:align-bottom',
              disabled: !alignDistributeStatus.alignBottom,
              callback: this._elementService.alignBottom.bind(this._elementService, this.selectedRootElements())
            }
          ],
          hide: this.page().locked || childLocked || !this.isVirtualGroup(element.id)
        },
        {
          label: '等间距',
          icon: 'editorup:distribute-spacing',
          children: [
            {
              label: '水平',
              icon: 'editorup:distribute-horizontal-spacing',
              disabled: !alignDistributeStatus.distributeHorizontal,
              callback: this._elementService.distributeHorizontalSpacing.bind(this._elementService, this.selectedRootElements())
            },
            {
              label: '垂直',
              icon: 'editorup:distribute-vertical-spacing',
              disabled: !alignDistributeStatus.distributeVertical,
              callback: this._elementService.distributeVerticalSpacing.bind(this._elementService, this.selectedRootElements())
            }
          ],
          hide: this.page().locked || childLocked || !this.isVirtualGroup(element.id)
        },
        {
          label: '建组',
          icon: 'custom:element-group',
          shortcut: ['Ctrl', 'G'],
          callback: this._elementService.createGroupElement.bind(this._elementService, this.selectedRootElements()),
          hide: this.page().locked || childLocked || !this.isVirtualGroup(element.id),
          br: true
        },
        {
          label: '解组',
          icon: 'custom:element-ungroup',
          shortcut: ['Alt', 'Ctrl', 'G'],
          callback: () => {
            if (element.category === ElementTypeEnum.Group) {
              this._elementService.ungroupElement(element as GroupElementTreeNode)
            }
          },
          hide: this.page().locked || element.locked || element.category !== ElementTypeEnum.Group || this.isVirtualGroup(element.id)
        },
        {
          label: '锁定',
          icon: 'custom:page-lock',
          callback: this._elementService.lockElements.bind(this._elementService),
          hide: this.page().locked || element.locked
        },
        {
          label: '解锁',
          icon: 'custom:page-unlock',
          callback: this._elementService.unlockElements.bind(this._elementService),
          disabled: this.page().locked,
          hide: !element.locked && !this.page().locked
        },
        {
          label: '替换背景',
          icon: 'custom:img-to-bg',
          callback: this.updatePageBackgroundImage.bind(this, element as PageElementTreeNode<ElementTypeEnum.Image>),
          disabled: !this.replaceBackgroundEnable(),
          hide: this.page().locked || element.locked || element.category !== ElementTypeEnum.Image
        },
        {
          label: '下载选项',
          icon: 'custom:element-download',
          callback: () => {},
          br: true,
          hide: element.category === ElementTypeEnum.Shape || element.category === ElementTypeEnum.Text || element.category === ElementTypeEnum.Line
        }
      ]
    })
    return menus
  })
  protected pageContextMenu = computed(() => {
    const menu: IContextMenuItem[] = [
      {
        label: '复制',
        icon: 'custom:element-copy',
        shortcut: ['Ctrl', 'C'],
        callback: this._pageService.copyBackground.bind(this._pageService),
        hide: this.page().locked
      },
      { label: '复制样式', icon: 'custom:copy-style', shortcut: ['Ctrl', 'Alt', 'C'], callback: () => {}, hide: true },
      {
        label: '粘贴',
        icon: 'custom:element-paste',
        shortcut: ['Ctrl', 'V'],
        callback: this.workspaceService.paste.bind(this.workspaceService),
        hide: this.page().locked
      },
      {
        label: '删除背景',
        icon: 'custom:page-delete',
        // shortcut: ['DELETE'],
        callback: this.removeBackground.bind(this),
        hide: this.isBackgroundEmpty() || this.page().locked
      },
      // {
      //   label: '锁定背景',
      //   icon: 'custom:page-lock',
      //   callback: () => {},
      //   hide: this.isBackgroundEmpty() || this.page().background.locked || this.page().locked
      // },
      {
        label: '解锁背景',
        icon: 'custom:page-unlock',
        callback: () => {},
        hide: !this.page().background.locked || this.page().locked
      },
      {
        label: '辅助线',
        icon: 'editorup:标尺',
        br: true,
        children: [
          { label: '新增水平辅助线', icon: '', callback: this.createGuide.bind(this, GuideType.horizontal) },
          { label: '新增垂直辅助线', icon: '', callback: this.createGuide.bind(this, GuideType.vertical) }
        ],
        hide: !this.uiStore.ruleEnable()
      }
    ]
    return menu
  })

  protected elementInteractionStates = signal<Record<string, IShadowElement>>({})
  protected highLightElementIds = signal<string[]>([])

  protected elementsCollisionPoints = signal<Record<string, IPosition[]>>({}) // 元素碰撞点
  protected elementGuidLines = signal<IGuidLine[]>([])
  protected virtualInteractBox = viewChild('virtualInteractBox', { read: ElementInteractBoxComponent })
  protected uiStore = inject(StageUiStore)
  protected _pageService = inject(PageService)
  protected _elementService = inject(ElementService)
  protected _injector = inject(Injector)
  protected selectedRootElements = this.uiStore.selectedRootElements
  protected _changeDetectorRef = inject(ChangeDetectorRef)
  protected _projectService = inject(ProjectService)
  protected virtualGroup = this.uiStore.virtualElement

  constructor() {
    // 元素的碰撞检测点
    toObservable(this.pageRootElements)
      .pipe(combineLatestWith(toObservable(this.virtualGroup), toObservable(this.uiStore.pageOffset)))
      .pipe(takeUntilDestroyed())
      .subscribe(([els]) => {
        const collisions: Record<string, IPosition[]> = {}
        els.forEach(el => {
          if (!this.isVirtualChild(el.id)) {
            const elCmp = this.elementContainerComponents().find(cmp => cmp.data().id === el.id)
            if (elCmp) {
              collisions[el.id] = this.elementCollisionPoints(this.elementBoundingPageRect(elCmp.boundingClientRect))
            }
          }
        })
        this.elementsCollisionPoints.set(collisions)
      })

    // 自动吸附
    merge(
      toObservable(this.uiStore.interacting.resizing.endPosition),
      toObservable(this.uiStore.interacting.scaling.endPosition),
      toObservable(this.uiStore.interacting.moving.position)
    )
      .pipe(combineLatestWith(toObservable(this.elementsCollisionPoints)))
      .pipe(takeUntilDestroyed())
      .subscribe(([position, elementsCollisionPoints]) => {
        const interactElementId = this.uiStore.interacting.id()
        const interactElement = untracked(this.elementContainerComponents).find(el => untracked(el.data).id === interactElementId)
        if (interactElement && position) {
          const { x: xGuidLines, y: yGuidLines } = this.elementPotentialGuidLines(interactElement)

          const xAbsorbLines: number[] = this.absorbToGuidLines(xGuidLines)
          const yAbsorbLines: number[] = this.absorbToGuidLines(yGuidLines)

          const xCollision = xGuidLines.get(xAbsorbLines[0])
          const yCollision = yGuidLines.get(yAbsorbLines[0])
          const { x, y } = interactElement.position()
          if (xCollision || yCollision) {
            // if (this.uiStore.isMovingElement()) {
            //   this.uiStore.setInteractShadowData(interactElementId, {
            //     position: {
            //       x: x - (xCollision ? xCollision.distance : 0),
            //       y: y - (yCollision ? yCollision.distance : 0)
            //     }
            //   })
            // }

            if (this.uiStore.isResizingElement()) {
              // const resizeHandler = this.uiStore.interacting.resizing.handler()
              // switch (resizeHandler) {
              //   case 'n':
              // }
            }
          }
          this.elementGuidLines.set([
            ...Array.from(xGuidLines)
              .filter(([key]) => xAbsorbLines.includes(key))
              .map(([key, { range }]) => ({ type: GuideType.vertical, position: key, from: range[0], to: range[1] })),
            ...Array.from(yGuidLines)
              .filter(([key]) => yAbsorbLines.includes(key))
              .map(([key, { range }]) => ({ type: GuideType.horizontal, position: key, from: range[0], to: range[1] }))
          ])
        } else {
          this.elementGuidLines.set([])
        }
      })

    // 选中框选范围内的元素
    toObservable(this.marqueeSelectArea)
      .pipe(takeUntilDestroyed(), skip(1))
      .subscribe(marqueeSelectArea => {
        if (marqueeSelectArea) {
          let { top, left, right, bottom } = marqueeSelectArea
          const scale = 1 / this.pageScale()
          top = Number((top * scale).toFixed(2))
          left = Number((left * scale).toFixed(2))
          right = Number((right * scale).toFixed(2))
          bottom = Number((bottom * scale).toFixed(2))
          if (top === bottom || left === right) {
            this.highLightElementIds.set([])
            return
          }

          const tl = { x: left, y: top }
          const tr = { x: right, y: top }
          const bl = { x: left, y: bottom }
          const br = { x: right, y: bottom }
          const marqueeRect: Rectangle = [tl, tr, bl, br]

          const elements = this.elementContainerComponents()
            .filter(el => {
              if (el.data().locked) return false
              if (this.uiStore.isShiftKeydown() && this.uiStore.selectedIdsSet().has(el.data().id)) return true
              const vertices = _.values(el.vertices()) as Rectangle
              return isRectsIntersect(marqueeRect, vertices)
              // const elInMarquee = vertices.some(point => {
              //   return rectContainerPoint(marqueeRect, point)
              // })
              //
              // return (
              //   elInMarquee ||
              //   marqueeRect.some(point => {
              //     return rectContainerPoint(vertices, point)
              //   })
              // )
            })
            .map(el => el.data().id)
          this.highLightElementIds.set(elements)
        } else {
          if (this.highLightElementIds().length > 0) {
            if (this.uiStore.isShiftKeydown()) {
              this.uiStore.addSelection(...this.highLightElementIds())
            } else {
              this.uiStore.resetSelection('element', ...this.highLightElementIds())
            }
            this.highLightElementIds.set([])
          } else {
            if (!untracked(this.uiStore.isShiftKeydown)) {
              // this.selectBackground()
            }
          }
        }
      })

    toObservable(this.uiStore.isElementInteracting)
      .pipe(takeUntilDestroyed())
      // .pipe(filter(() => this.uiStore.isElementReadyInteractionOnPage()))
      .subscribe(isElementInteracting => {
        if (isElementInteracting) {
          this.onElementInteract(this.uiStore.interacting.shadowData(), this.uiStore.interacting.id())
        }
      })

    // 选中多个元素时构造虚拟组元素
    toObservable(this.selectedRootElements)
      .pipe(
        filter(() => this.uiStore.onStagePage()?.id === this.page().id),
        takeUntilDestroyed(),
        // map(elements => elements.length),
        distinctUntilChanged(),
        skip(1)
      )
      .subscribe(elements => {
        const virtualElement = this.virtualGroup()
        // const virtualElementState = virtualElement ? this.elementInteractionStates()[virtualElement.id] : undefined
        // this.elementInteractionStates.set({})
        if (elements.length > 0) {
          this.highLightElementIds.set([])
        }
        if (elements.length > 1) {
          if (!virtualElement) {
            this.setVirtualGroup(elements)
          } else {
            if (!this.uiStore.isElementInteracting()) {
              this.uiStore.updateVirtualElement()
            }
          }
        } else {
          if (this.virtualGroup()) {
            this.uiStore.clearVirtualElement()
          }
          this.elementInteractionStates.set({})
        }
      })

    // toObservable(this.selectedRootElements)
    //   .pipe(takeWhile(() => !this.preview))
    //   .pipe(takeUntilDestroyed(), skip(1))
    //   .subscribe(() => {
    //     const virtualElement = this.virtualGroup()
    //     if (virtualElement && !this.elementInteractionStates()[virtualElement.id]) {
    //       this.elementInteractionStates.set({})
    //       this.uiStore.updateVirtualElement()
    //     } else {
    //       this.elementInteractionStates.set({})
    //     }
    //   })
    toObservable(this.virtualGroup)
      .pipe(takeUntilDestroyed(), skip(1))
      .subscribe(virtualElement => {
        if (!virtualElement) {
          this.elementInteractionStates.set({})
        } else {
          if (this.elementInteractionStates()[virtualElement.id]) {
            const interactState = this.elementInteractionStates()

            this.elementInteractionStates.set({ [virtualElement.id]: interactState[virtualElement.id] })
            // this.elementInteractionStates.set(_.omit(this.elementInteractionStates(), virtualElement.id))
          } else {
            // this.elementInteractionStates.set({})
          }
        }
      })
  }

  @HostBinding('style.pointer-events') get pointerEvents() {
    return 'auto'
  }

  @HostListener('contextmenu', ['$event'])
  protected onRightClick(event: MouseEvent) {
    event.preventDefault()
  }

  ngAfterViewInit(): void {
    this.rendered.emit(true)
  }

  onElementInteract(state: IShadowElement, id: string) {
    if (id === '') {
      this.elementInteractionStates.set({})
    } else {
      if (this.isVirtualGroup(id)) {
        const virtualElement = this.virtualInteractBox() as ElementInteractBoxComponent
        const selectedElements = childrenRealSetting(
          {
            id,
            position: virtualElement.position(),
            size: {
              width: virtualElement.width(),
              height: virtualElement.height()
            },
            scale: 1,
            rotation: virtualElement.rotation(),
            setting: virtualElement.data().setting
          },
          virtualElement.children().map(child => {
            return {
              id: child.id,
              position: {
                x: child.position.x * virtualElement.scale(),
                y: child.position.y * virtualElement.scale()
              },
              size: child.size,
              scale: child.scale * virtualElement.scale(),
              rotation: child.rotation,
              setting: child.setting
            }
          })
        )

        const updateStates: Record<string, IShadowElement> = {
          [id]: state
        }

        selectedElements.forEach(child => {
          if (state.scale === undefined) {
            updateStates[child.id] = child
          } else {
            updateStates[child.id] = {
              ...child,
              scale: child.scale,
              size: {
                width: child.size.width,
                height: child.size.height
              }
            }
          }
        })
        this.elementInteractionStates.set(updateStates)
      } else {
        // 只处理根元素，子元素在group element中处理
        const rootElement = this.pageRootElements().find(el => el.id === id)
        if (rootElement) {
          this.elementInteractionStates.set({ [id]: state })
        }
      }
    }
  }

  elementHighLightOnly(id: string) {
    if (this.selectedRootElements().length === 1) {
      if (this.selectedRootElements()[0].id === id) {
        return false
      }
    }
    return true
  }

  highLightElement($event: MouseEvent, el: IPageElementBase) {
    if (this.marqueeSelectArea()) return
    if (this.highLightElementIds().includes(el.id)) return
    if (this.uiStore.selectedIdsSet().has(el.id)) return

    this.highLightElementIds.set([...this.highLightElementIds(), el.id])
  }

  removeHighLightElement($event: MouseEvent, id: string) {
    if (this.marqueeSelectArea()) return
    // 防止interact-box被移除后触发element-container的mouseenter导致重新高亮
    // setTimeout(() => {
    if (this.highLightElementIds().includes(id)) {
      this.highLightElementIds.set(this.highLightElementIds().filter(el => el !== id))
    }
    // }, 1)
  }

  isVirtualChild(id: string) {
    const virtualGroup = this.virtualGroup()
    return !!(virtualGroup && virtualGroup.children.find(child => child.id === id))
  }

  isVirtualGroup(id: string) {
    return this.virtualGroup()?.id === id
  }

  handleContextMenu($event: MouseEvent) {
    $event.preventDefault()
    this.triggerMenuPoint.set({
      x: $event.clientX,
      y: $event.clientY
    })
  }

  protected onElementStartDrag(params: { $event: { clientX: number; clientY: number }; id: string; parent?: string }) {
    const { $event: $event, id, parent } = params
    // $event.stopPropagation()
    const isVirtualChild = this.isVirtualChild(id)
    const virtualGroup = this.virtualGroup()

    if (!isVirtualChild) {
      const virtualInteractBox = this.virtualInteractBox()
      const elementContainerCmp = parent
        ? this.elementContainerComponents().find(el => el.data().id === parent)
        : this.elementContainerComponents().find(el => el.data().id === id)
      const { x, y } =
        virtualInteractBox && id === virtualInteractBox.data().id
          ? virtualInteractBox.position()
          : elementContainerCmp
            ? elementContainerCmp.position()
            : { x: 0, y: 0 }

      const offset = {
        x: ($event.clientX + this.scrollLeft() - this.uiStore.pageOffset().x) / this.pageScale() - x,
        y: ($event.clientY + this.scrollTop() - this.uiStore.pageOffset().y) / this.pageScale() - y
      }

      // 防止右键触发
      // if ($event.button === 0) {
      this.startDragging(offset, parent ?? id)
      // }
    } else {
      // 手动触发虚拟组元素的mousedown事件
      this.onElementStartDrag({ $event: { clientX: $event.clientX, clientY: $event.clientY }, id: (virtualGroup as IPageElementBase).id })
    }
  }

  private startDragging(position: IPosition, id: string) {
    this.uiStore.setDraggingElement(id, {
      offset: {
        x: position.x,
        y: position.y
      }
    })
  }

  private setVirtualGroup(elements: PageElementTreeNode[]) {
    const groupElement = this._elementService.createGroupElement(elements, true) as CreateVirtualElementParams
    if (groupElement) {
      this.uiStore.setVirtualElement(groupElement)
    }
  }

  private elementBoundingPageRect(rect: { top: number; left: number; width: number; height: number }) {
    const { top, left, width, height } = rect
    const { x: pageLeft, y: pageTop } = this.uiStore.pageOffset()
    const elLeft = left + this.scrollLeft() - pageLeft
    const elTop = top + this.scrollTop() - pageTop
    const elRight = elLeft + width
    const elBottom = elTop + height
    return {
      top: elTop,
      left: elLeft,
      right: elRight,
      bottom: elBottom,
      width,
      height
    }
  }

  private elementCollisionPoints(rect: { top: number; left: number; right: number; bottom: number; width: number; height: number }) {
    const { top, left, right, bottom, width, height } = rect
    return [
      { x: left, y: top },
      { x: right, y: top },
      { x: left, y: bottom },
      { x: right, y: bottom },
      { x: left + width / 2, y: top },
      { x: left + width / 2, y: bottom },
      { x: right, y: top + height / 2 },
      { x: left, y: top + height / 2 }
    ]
  }

  private elementPotentialGuidLines(interactElement: ElementContainerComponent) {
    const elementsCollisionPoints = this.elementsCollisionPoints()
    const elCollisionPoints = this.elementCollisionPoints(this.elementBoundingPageRect(interactElement.boundingClientRect))
    const xGuidLines: Map<number, { range: [number, number]; distance: number }> = new Map()
    const yGuidLines: Map<number, { range: [number, number]; distance: number }> = new Map()
    _.forEach(elementsCollisionPoints, (points, id) => {
      if (id !== interactElement.data().id) {
        points.forEach(point => {
          elCollisionPoints.forEach(elPoint => {
            const deltaX = elPoint.x - point.x
            const deltaY = elPoint.y - point.y
            if (abs(deltaX) <= ADSORB_DISTANCE) {
              const exist = xGuidLines.get(point.x)
              if (exist) {
                xGuidLines.set(point.x, { range: [min(exist.range[0], point.y, elPoint.y), max(exist.range[1], point.y, elPoint.y)], distance: deltaX })
              } else {
                xGuidLines.set(point.x, { range: [min(point.y, elPoint.y), max(point.y, elPoint.y)], distance: deltaX })
              }
            }
            if (abs(deltaY) <= ADSORB_DISTANCE) {
              const exist = yGuidLines.get(point.y)
              if (exist) {
                yGuidLines.set(point.y, { range: [min(exist.range[0], point.x, elPoint.x), max(exist.range[1], point.x, elPoint.x)], distance: deltaY })
              } else {
                yGuidLines.set(point.y, { range: [min(point.x, elPoint.x), max(point.x, elPoint.x)], distance: deltaY })
              }
            }
          })
        })
      }
    })

    return { x: xGuidLines, y: yGuidLines }
  }

  private absorbToGuidLines(guidLines: Map<number, { range: [number, number]; distance: number }>) {
    let closest = NaN
    let absorbLines: number[] = []
    guidLines.forEach((value, key) => {
      if (isNaN(closest)) {
        closest = key
        absorbLines = [key]
      } else {
        const closestValue = guidLines.get(closest)
        if (closestValue) {
          if (abs(closestValue.distance) > abs(value.distance)) {
            closest = key
            absorbLines = [key]
          } else if (abs(closestValue.distance) === abs(value.distance)) {
            absorbLines.push(key)
          }
        }
      }
    })
    return absorbLines
  }

  /**
   * 更新页面背景图片并删除当前图片
   * @param element
   * @private
   */
  private updatePageBackgroundImage(element: PageElementTreeNode<ElementTypeEnum.Image>) {
    const imageInstance = this.imageInstance()
    if (isImageSetting(element) && imageInstance) {
      const page = this.page()
      const filterSrc = element.setting.adjustment.src
      const imageSrc = filterSrc && imageInstance.enableCanvas() ? filterSrc : element.setting.src
      this.apiService
        .forkImage({
          projectId: this._projectService.projectId,
          path: imageSrc.replace(/https?:\/\/[^/]+\//, ''),
          destPath: `${page.id}_${Date.now()}_${Math.random().toString(36).slice(2)}.png`
        })
        .subscribe(res => {
          this._projectService.updatePage({
            id: page.id,
            // TODO baseURL需要统一
            background: {
              color: this.page().background.color,
              locked: this.page().background.locked,
              image: res.link,
              opacity: element.setting.opacity,
              flip: element.setting.flip,
              rotation: element.rotation
            }
          })
          // 删除当前图片
          this._projectService.deletePageElements(page.id, element.id)
          this.uiStore.resetSelection('background')
        })
    }
  }

  private removeBackground() {
    this._pageService.deleteBackground()
  }

  private duplicateSelectedElement() {
    this._elementService.duplicateElements()
  }

  private deleteSelectedElement() {
    this._elementService.deleteElements(...this.selectedRootElements().map(el => el.id))
  }

  private createGuide(type: GuideType) {
    this.workspaceService.sendCreateGuides(type, this.triggerMenuPoint())
  }
}
