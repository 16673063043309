import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, effect, HostListener, inject, OnDestroy, viewChild, ViewContainerRef } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatLabel, MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select'
import { ActivatedRoute } from '@angular/router'

import { filter, first } from 'rxjs'

import { SsOriginDirective } from '@editorup/settings'
import { IProject } from '@libs/payload'

import { AppStore } from '#core/store/app.store'
import { SettingPanelComponent } from '#modules/workspace/components/setting-panel'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ProjectService } from '#shared/services/project/project.service'
import { PageCollectionService } from '#shared/services/rxdb/page-collection.service'
import { PageElementCollectionService } from '#shared/services/rxdb/page-element-collection.service'
import { extractFontFamilyValues } from '#shared/utils'
import { loadWithName } from '#shared/utils/fonts'

import { CanvasComponent } from './components/canvas'
import { HeaderComponent } from './components/header/header.component'
import { Menu, SidebarComponent } from './components/sidebar'
import { FileUploadService } from './components/upload/upload.service'
import { ClipboardService } from './services/clipboard.service'
import { ElementService } from './services/element.service'
import { HotkeyService } from './services/hotkey.service'
import { PageService } from './services/page.service'
import { TextService } from './services/text.service'
import { TextStore } from './store/text.store'

@Component({
  selector: 'ace-workspace',
  standalone: true,
  imports: [
    CommonModule,
    CanvasComponent,
    SidebarComponent,
    MatSelect,
    MatOption,
    MatSelectTrigger,
    MatLabel,
    SettingPanelComponent,
    SsOriginDirective,
    HeaderComponent
  ],
  templateUrl: './workspace.component.html',
  styleUrl: './workspace.component.scss',
  providers: [
    PageCollectionService,
    PageElementCollectionService,
    ProjectService,
    StageUiStore,
    TextStore,
    WorkspaceService,
    ElementService,
    TextService,
    ClipboardService,
    PageService,
    HotkeyService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceComponent implements OnDestroy {
  container = viewChild.required('container', { read: ViewContainerRef })
  sidebar = viewChild.required<SidebarComponent>('sidebar')

  projectService = inject(ProjectService)
  uiStore = inject(StageUiStore)
  appStore = inject(AppStore)
  uploadService = inject(FileUploadService)
  workspaceService = inject(WorkspaceService)
  hotkeyService = inject(HotkeyService)

  upgrading = this.projectService.isUpgrading

  data: IProject

  constructor(private activatedRoute: ActivatedRoute) {
    effect(() => {
      console.log(this.upgrading())
    })
    this.data = this.activatedRoute.snapshot.data['project']
    toObservable(this.projectService.pageLinkedListNodes)
      .pipe(takeUntilDestroyed())
      .subscribe(pages => {
        const onStagePage = this.uiStore.onStagePage()
        if (pages.length > 0) {
          if (!onStagePage) {
            // 初始化项目，设置第一个页面为onStagePage
            this.uiStore.resetSelection('page', pages[0].id)
          } else {
            const updatedOnStagePage = pages.find(page => page.id === onStagePage.id)
            if (!updatedOnStagePage) {
              // onStagePage被删除了，重新设置onStagePage
              this.uiStore.resetSelection('page', pages[0].id)
            } else {
              // page更新了
              // this.uiStore.updateOnStagePage(updatedOnStagePage)
            }
          }
        } else {
          // 项目没有页面了
          this.uiStore.resetSelection('page', '')
        }
      })

    this.appStore.getMetaCharts()

    // 获取字体 仅在第一次加载时触发，后续更改字体会在selectFont组件中触发字体加载
    toObservable(this.projectService.currentPages)
      .pipe(
        takeUntilDestroyed(),
        filter(pages => pages.length > 0),
        first()
      )
      .subscribe(pages => {
        const fonts = extractFontFamilyValues(pages)
        console.log('fonts', fonts)
        loadWithName(fonts)
          .then(() => {
            console.log('字体加载完成')
          })
          .catch(err => {
            console.error('字体加载失败', err)
          })
      })
  }

  get loading() {
    return this.projectService.isReplicating()
  }

  @HostListener('window:keydown', ['$event']) onKeyDown($event: KeyboardEvent) {
    this.hotkeyService.onKeydown($event)
  }

  // @HostListener('window:keyup', ['$event']) onKeyUp($event: KeyboardEvent) {
  //   console.log($event)
  //   this.uiStore.removeKey($event.key)
  // }

  @HostListener('window:dragenter', ['$event']) onDragEnter($event: DragEvent) {
    if (this.sidebar().activeIndex() !== 5) {
      this.sidebar().handleMenuClick(5)
    }
  }

  ngOnDestroy() {
    console.log('workspace destroy')
    this.projectService.destroy()
  }

  handleSidebarChange($event: Menu | undefined) {
    if ($event) {
      switch ($event.label) {
        case '数据':
          console.log('数据')
          break
        case '图层':
          console.log('图层')
          break
        case '页面':
          console.log('页面')
          break
        case '素材':
          console.log('素材')
          break
        case '模版':
          console.log('模版')
          break
        case '上传':
          console.log('上传')
          break
        default:
          break
      }
    } else {
    }
  }
}
