import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'
import { MatIcon } from '@angular/material/icon'

import { toLower } from 'lodash'

import { StageUiStore } from '#modules/workspace/store/stage-ui.store'

@Component({
  selector: 'ace-meta-shape-list',
  standalone: true,
  imports: [CommonModule, MatIcon],
  templateUrl: './meta-shape-list.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MetaShapeListComponent {
  _uiStore = inject(StageUiStore)

  keydown = this._uiStore.keydown

  onSelect = output<string>()

  shapeList = [
    {
      name: '矩形',
      icon: 'editorup:rect',
      keyMap: 'R',
      path: 'M0,0 L256,0 L256,256 L0,256 Z'
    },
    {
      name: '圆形',
      icon: 'editorup:circle',
      keyMap: 'O',
      path: 'M128,128 m-128,0 a128,128 0 1,0 256,0 a128,128 0 1,0 -256,0'
    },
    // { name: '直线', icon: 'editorup:line', keyMap: 'L', path: '' },
    {
      name: '星形',
      icon: 'editorup:star',
      keyMap: 'S',
      path: 'M128,0 L156.8,88.8 L256,96 L182.4,153.6 L204.8,256 L128,204.8 L51.2,256 L73.6,153.6 L0,96 L99.2,88.8 Z'
    },
    { name: '三角形', icon: 'editorup:triangle', keyMap: 'T', path: 'M128,0 L256,256 L0,256 Z' },
    { name: '菱形', icon: 'editorup:diamond', keyMap: 'D', path: 'M128,0 L256,128 L128,256 L0,128 Z' }
  ]

  constructor() {
    // 快捷键监听
    toObservable(this.keydown)
      .pipe(takeUntilDestroyed())
      .subscribe(keySet => {
        const currentKey = Array.from(keySet)[0]
        console.log(currentKey, keySet)
        if (currentKey) {
          const shape = this.shapeList.find(s => toLower(`key${s.keyMap}`) === toLower(currentKey))
          if (shape) {
            this.addShape(shape.path)
          }
        }
      })
  }

  addShape(path: string) {
    this.onSelect.emit(path)
  }
}
