import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnDestroy, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { cloneDeep, isEmpty } from 'lodash'
import Moveable, { OnScale } from 'moveable'
import { delay } from 'rxjs'

import { boundingRect, IPosition } from '@libs/algorithm'

import { ElementVertexHandlerComponent } from '#modules/workspace/components/element-interact/element-anchor-handler/element-vertex-handler.component'
import { ElementRotateHandlerComponent } from '#modules/workspace/components/element-interact/element-rotate-handler/element-rotate-handler.component'
import { ImageComponent } from '#modules/workspace/components/element/image'
import { PageElementTreeNode } from '#modules/workspace/models/element-node'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { ElementTypeEnum, ROTATION_CURSOR_SVG } from '#modules/workspace/types/constants'
import { IImageSetting } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { getFlipScale } from '#shared/utils/image'

interface Rect {
  size: { height: number; width: number }
  rotation: number
  center: { x: number; y: number }
}

interface Point {
  x: number
  y: number
}

interface ResultRect {
  size: { height: number; width: number }
  translate: { x: number; y: number }
  scale: number
}

let initialSize: { height: number; width: number } = { height: 0, width: 0 } // 初始矩形大小
function rotatePoint(px: number, py: number, cx: number, cy: number, theta: number): { x: number; y: number } {
  const radians = theta * (Math.PI / 180)
  const cosTheta = Math.cos(radians)
  const sinTheta = Math.sin(radians)

  const translatedX = px - cx // 转换到以中心为原点的坐标系
  const translatedY = py - cy

  const rotatedX = translatedX * cosTheta - translatedY * sinTheta
  const rotatedY = translatedX * sinTheta + translatedY * cosTheta

  return { x: rotatedX + cx, y: rotatedY + cy } // 再转换回左上角为原点的坐标系
}

function rotateRect(rect: Rect, points: Point[]): ResultRect {
  const { size, rotation, center } = rect
  const { width, height } = size
  const { x: cx, y: cy } = center

  let newWidth = width
  let newHeight = height
  let offsetX = 0
  let offsetY = 0
  let scale = 1

  points.forEach((point, index) => {
    // 在新的坐标系中进行旋转
    const rotatedPoint = rotatePoint(point.x, point.y, cx, cy, -rotation)

    // 调试输出：检查旋转后点的位置
    // console.log('Rotated Point:', rotatedPoint, 'Center:', center)

    // 检查点是否超出旋转后的矩形边界
    const isOutOfBounds =
      rotatedPoint.x < cx - newWidth / 2 || rotatedPoint.x > cx + newWidth / 2 || rotatedPoint.y < cy - newHeight / 2 || rotatedPoint.y > cy + newHeight / 2

    if (isOutOfBounds) {
      // 调试输出：检查点是否超出边界
      console.log('Point is out of bounds:', rotatedPoint, index)

      // 计算需要调整的比例
      const scaleX = Math.max(Math.abs(rotatedPoint.x - cx) / (newWidth / 2), 1)
      const scaleY = Math.max(Math.abs(rotatedPoint.y - cy) / (newHeight / 2), 1)

      // 按比例调整矩形大小
      scale = Math.max(scaleX, scaleY)
      newWidth = Math.max(initialSize.width, newWidth * scale)
      newHeight = Math.max(initialSize.height, newHeight * scale)

      // // 计算在未旋转坐标系中的偏移量以保持中心点不变
      offsetX = (newWidth - width) / 2
      offsetY = (newHeight - height) / 2
      // 应用逆向变换，计算偏移量
      // const rotatedOffset = rotatePoint(cx - deltaX, cy - deltaY, cx, cy, rotation)
      // offsetX = rotatedOffset.x - cx
      // offsetY = rotatedOffset.y - cy

      // 调试输出：检查应用偏移后的结果
      console.log('Offset:', { offsetX, offsetY })
    }
  })

  return {
    size: { width: newWidth, height: newHeight },
    translate: { x: offsetX, y: offsetY },
    scale: scale
  }
}

@Component({
  selector: 'ace-image-cropper',
  standalone: true,
  imports: [CommonModule, ImageComponent],
  templateUrl: './image-cropper.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageCropperComponent implements AfterViewInit, OnDestroy {
  uiStore = inject(StageUiStore)
  workspaceService = inject(WorkspaceService)

  id = input.required<string>()
  moveable!: Moveable

  isMoving = signal(false)

  fullImageMove = signal<IPosition>({ x: 0, y: 0 })
  fullImageRotation = signal(0)
  fullImageScale = signal(1)
  fullImageSize = signal({ width: 0, height: 0 })
  fullImageRotateMove = signal({ x: 0, y: 0 })

  element = computed(() => this.workspaceService.element() as PageElementTreeNode<ElementTypeEnum.Image>)
  pageSize = computed(() => this.uiStore.pageSize())
  pageZoom = computed(() => this.uiStore.zoom())
  elementPosition = computed(() => this.element()?.position || { x: 0, y: 0 })
  elementSize = computed(() => this.element()?.size || { width: 0, height: 0 })
  elementScale = computed(() => this.element()?.scale || 1)
  elementRotation = computed(() => this.element()?.rotation || 1)

  imageTranslate = computed(() => this.setting()?.transform?.translate || { x: 0, y: 0 })
  imageContainer = computed(() => this.setting()?.size)

  setting = computed(() => {
    const shadowSetting = this.uiStore.interacting.shadowData.setting()
    if (isEmpty(shadowSetting)) {
      return this.element()?.setting as IImageSetting
    } else {
      return shadowSetting as IImageSetting
    }
  })

  imageSrc = computed(() => {
    return this.setting()?.src
  })

  fullImageStyle = computed(() => {
    const element = this.element()
    const size = this.fullImageSize()
    const fullImageMove = this.fullImageMove()
    return {
      width: size.width + 'px',
      height: size.height + 'px',
      transform: `translate(${element.position.x + fullImageMove.x * element.scale}px, ${element.position.y + fullImageMove.y * element.scale}px) rotate(${this.fullImageRotation()}deg) scale(${this.fullImageScale() * this.elementScale()})`
    }
  })

  imageFlipScale = computed(() => getFlipScale(this.setting()?.flip || 'none'))

  cropperImageStyle = computed(() => {
    const element = this.element()
    if (element) {
      const size = element.size
      return {
        left: element.position.x + 'px',
        top: element.position.y + 'px',
        width: size.width + 'px',
        height: size.height + 'px',
        scale: this.elementScale()
      }
    }
    return {}
  })

  pageStyle = computed(() => {
    const pageSize = this.uiStore.pageSize()
    return {
      width: pageSize.width + 'px',
      height: pageSize.height + 'px',
      scale: this.pageZoom()
    }
  })

  fullImageContainerRef = viewChild.required<ElementRef<HTMLDivElement>>('fullImageContainerRef')
  cropImageContainerRef = viewChild.required<ElementRef<HTMLDivElement>>('cropImageContainerRef')
  rootContainerRef = viewChild.required<ElementRef<HTMLDivElement>>('rootContainerRef')
  rotateHandlerRef = viewChild.required<ElementRef<HTMLDivElement>>('rotateHandlerRef')
  hostRef = inject(ElementRef)

  points = computed(() => {
    const { nw, ne, sw, se } = this.workspaceService.baseElementVertices()
    return [nw, ne, sw, se]
  })

  center = computed(() => {
    const translate = this.setting().transform.translate
    const size = this.elementSize()
    const position = this.elementPosition()
    return { x: position.x + size.width / 2 + translate.x / 2, y: position.y + size.height / 2 + translate.y / 2 }
  })

  constructor() {
    toObservable(this.fullImageMove)
      .pipe(takeUntilDestroyed())
      .subscribe(({ x, y }) => {
        this.uiStore.setSettingElement(
          this.id(),
          produce(this.setting(), draft => {
            if (draft) {
              draft.transform.translate.x = x
              draft.transform.translate.y = y
            }
          })
        )
      })

    toObservable(this.fullImageSize)
      .pipe(takeUntilDestroyed())
      .subscribe(({ width, height }) => {
        this.uiStore.setSettingElement(
          this.id(),
          produce(this.setting(), draft => {
            if (draft) {
              draft.size.width = width
              draft.size.height = height
            }
          })
        )
      })

    toObservable(this.fullImageRotation)
      .pipe(takeUntilDestroyed())
      .subscribe(rotation => {
        this.uiStore.setSettingElement(
          this.id(),
          produce(this.setting(), draft => {
            if (draft) {
              draft.transform.rotation = rotation
            }
          })
        )
      })

    toObservable(this.pageZoom)
      .pipe(delay(0))
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        if (this.moveable) {
          this.moveable.updateRect()
        }
      })
    // afterRender({
    //   read: () => {
    //     if (this.moveable) {
    //       console.log('update')
    //       this.moveable.updateRect()
    //     }
    //   }
    // })
  }

  ngAfterViewInit() {
    this.fullImageMove.set({ ...this.setting().transform.translate })
    this.fullImageSize.set({ ...this.setting().size })
    this.fullImageRotation.set(this.setting().transform?.rotation || 0)
    requestAnimationFrame(() => {
      this.moveable = new Moveable(document.body, {
        renderDirections: ['nw', 'ne', 'sw', 'se'],
        target: this.fullImageContainerRef().nativeElement,
        rotatable: true,
        scalable: true,
        draggable: true,
        origin: false,
        snappable: false,
        keepRatio: true,
        rotationPosition: 'bottom',
        throttleDrag: 0,
        className: 'ace-moveable',
        rootContainer: this.rootContainerRef().nativeElement,
        useResizeObserver: true,
        useMutationObserver: true
      })

      // let cropDomRect!: DOMRect
      // let fullDomRect!: DOMRect
      //
      // let cropRect!: ReturnType<typeof boundingRect>
      // let fullRect!: ReturnType<typeof boundingRect>
      //
      // let fullCenter!: IPosition
      // let cropCenter!: IPosition

      const startX = 0,
        startY = 0,
        startMove = this.fullImageMove()

      // let points: Point[]
      this.moveable
        .on('rotateStart', () => {
          // fullDomRect = this.fullImageContainerRef().nativeElement.getBoundingClientRect()
          // fullCenter = { x: fullDomRect.left + fullDomRect.width / 2, y: fullDomRect.top + fullDomRect.height / 2 }
          // 初始化旋转开始的大小，旋转过程中如果放大后触发缩小时，初始化大小就是缩小的极限
          initialSize = cloneDeep(this.fullImageSize())
          // const vertices = this.workspaceService.baseElementVertices()
          // points = [vertices.nw, vertices.ne, vertices.se, vertices.sw]
          // fullCenter = { x: this.fullImageSize().width / 2 - this.fullImageMove().x, y: this.fullImageSize().height / 2 - this.fullImageMove().y }
          // cropDomRect = this.cropImageContainerRef().nativeElement.getBoundingClientRect()
          // points = [
          //   { x: cropDomRect.left, y: cropDomRect.top },
          //   { x: cropDomRect.left, y: cropDomRect.top + cropDomRect.height },
          //   { x: cropDomRect.left + cropDomRect.width, y: cropDomRect.top },
          //   { x: cropDomRect.left + cropDomRect.width, y: cropDomRect.top + cropDomRect.height }
          // ]
          console.log('onRotateStart', this.center(), this.points())
        })
        .on('rotate', ({ dist }) => {
          // fullDomRect = this.fullImageContainerRef().nativeElement.getBoundingClientRect()
          // fullCenter = { x: fullDomRect.left + fullDomRect.width / 2, y: fullDomRect.top + fullDomRect.height / 2 }
          const rect: Rect = {
            size: this.fullImageSize(),
            rotation: dist, // 当前旋转角度,
            center: this.center()
          }

          const result = rotateRect(rect, this.points())

          // this.fullImageSize.set(produce(result.size, draft => {
          //   draft.width = draft.width / this.pageZoom()
          //   draft.height = draft.height / this.pageZoom()
          // }))
          this.fullImageSize.set(result.size)
          this.fullImageMove.update(move => {
            const { x, y } = result.translate
            const rotatedOffset = rotatePoint(-x, -y, 0, 0, -dist + this.fullImageRotation())
            return {
              x: move.x + rotatedOffset.x,
              y: move.y + rotatedOffset.y
            }
          })
          this.fullImageRotation.set(dist)
          console.log('onRotate-----------------------------------------------------------', dist, result)
        })
        .on('rotateEnd', ({ target, isDrag }) => {
          console.log('onRotateEnd', target, isDrag)
        })

      /* scalable */
      this.moveable
        .on('scaleStart', ({ target, clientX, clientY }) => {
          console.log('onScaleStart', target)
        })
        .on('scale', ({ target, scale, dist, delta, transform, clientX, clientY }: OnScale) => {
          console.log('onScale scale', scale)
          this.fullImageScale.set(scale[0])
        })
        .on('scaleEnd', ({ target, isDrag, clientX, clientY }) => {
          console.log('onScaleEnd', target, isDrag)
        })
      /* draggable */

      //   this.moveable
      //     .on('dragStart', ({ target, clientX, clientY }) => {
      //       startX = clientX
      //       startY = clientY
      //       cropDomRect = this.cropImageContainerRef().nativeElement.getBoundingClientRect()
      //       fullDomRect = this.fullImageContainerRef().nativeElement.getBoundingClientRect()
      //       startMove = this.fullImageMove()
      //       console.log('onDragStart', target, cropDomRect, fullDomRect)
      //       this.isMoving.set(true)
      //     })
      //     .on('drag', ({ clientX, clientY }) => {
      //       console.log('drag', clientX, clientY)
      //       let offsetX = (clientX - startX) * this.elementScale() * this.pageZoom()
      //       let offsetY = (clientY - startY) * this.elementScale() * this.pageZoom()
      //       offsetX =
      //         fullDomRect.x + offsetX > cropDomRect.x
      //           ? cropDomRect.x - fullDomRect.x
      //           : fullDomRect.x + offsetX + fullDomRect.width < cropDomRect.x + cropDomRect.width
      //             ? cropDomRect.width + cropDomRect.x - (fullDomRect.x + fullDomRect.width)
      //             : offsetX
      //       offsetY =
      //         fullDomRect.y + offsetY > cropDomRect.y
      //           ? cropDomRect.y - fullDomRect.y
      //           : fullDomRect.y + offsetY + fullDomRect.height < cropDomRect.y + cropDomRect.height
      //             ? cropDomRect.height + cropDomRect.y - (fullDomRect.y + fullDomRect.height)
      //             : offsetY
      //       this.fullImageMove.set({
      //         x: startMove.x + offsetX / this.elementScale() / this.pageZoom(),
      //         y: startMove.y + offsetY / this.elementScale() / this.pageZoom()
      //       })
      //     })
      //     .on('dragEnd', ({ target, isDrag, clientX, clientY }) => {
      //       console.log('onDragEnd', target, isDrag)
      //       this.isMoving.set(false)
      //     })
    })
  }

  ngOnDestroy() {
    this.moveable.destroy()
    this.uiStore.resetInteractingElement()
  }

  handleFullImageMousedown($event: MouseEvent) {
    // const { clientX: startX, clientY: startY } = $event
    // const { x: startOffsetX, y: startOffsetY } = this.fullImageMove()
    // documentMouseMoveListener({
    //   start: event => {
    //     event.stopPropagation()
    //     console.log('move start', $event)
    //   },
    //   move: event => {
    //     event.stopPropagation()
    //     console.log('moving...')
    //   }
    // })
  }

  setRotateCursor(rotate: number) {
    const cursorRotation = Math.floor((rotate + 180) / 10) * 10
    const rotateSvg = ROTATION_CURSOR_SVG.replace('$rotation', (cursorRotation + 45).toString())
    const value = `url("data:image/svg+xml,${rotateSvg}") 16 16, pointer`
    const moveableDom = document.getElementsByClassName('ace-moveable')[0] as HTMLDivElement
    if (moveableDom) {
      moveableDom.style.setProperty('--cursor', value)
    }
  }
}
