<div
  class="flex h-[182px] w-full flex-col items-center justify-center gap-4 rounded-lg px-3 py-4"
  [class]="dragging() ? 'bg-opacity-1' : 'border-primary-200 dark:border-tertiary-600 border border-dashed bg-black bg-opacity-5'"
>
  <div class="flex h-[62px] w-[84px] flex-col items-center justify-center gap-2">
    <mat-icon class="icon-size-10" [svgIcon]="'custom:drag-upload'"> </mat-icon>
    <p class="text-sm leading-3" [class]="dragging() ? 'text-neutral-50' : 'text-tertiary-900 dark:text-neutral-50'">拖拽文件并上传</p>
  </div>
  <div
    class="flex flex-col items-center justify-center gap-3 text-sm leading-3"
    [class]="dragging() ? 'text-neutral-50' : 'text-tertiary-600 dark:text-tertiary-300'"
  >
    <div class="flex flex-col items-center justify-center gap-1">
      <p>图片</p>
      <p>(JPG，PNG，WEBP，GIF)</p>
    </div>
    <div class="flex flex-col items-center justify-center gap-1">
      <p>文件</p>
      <p>(XLS，XLSX，CSV)</p>
    </div>
  </div>
</div>
