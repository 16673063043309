import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  inject,
  Injector,
  OnInit,
  output,
  signal,
  TemplateRef,
  viewChild,
  ViewContainerRef
} from '@angular/core'
import { MatButton, MatIconButton } from '@angular/material/button'
import { MatIcon } from '@angular/material/icon'

import { fromEvent, Subscription, take } from 'rxjs'

import { DataPanelComponent } from '#modules/workspace/components/data-panel'
import { ClearFn, Menu } from '#modules/workspace/components/sidebar/index'
import { TemplateComponent } from '#modules/workspace/components/template/template.component'

import { LayerListComponent } from '../layer-list/layer-list.component'
import { PageListComponent } from '../page-list/page-list.component'
import { UploadComponent } from '../upload/upload.component'

@Component({
  selector: 'ace-sidebar',
  standalone: true,
  imports: [CommonModule, MatIcon, MatButton, CdkPortalOutlet, MatIconButton],
  templateUrl: './sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent implements OnInit {
  showMask = signal(false)
  /**
   * 侧边栏激活事件
   */
  aceActiveChange = output<Menu | undefined>()

  /**
   * 全部菜单列表
   */
  menus: Menu[] = []

  /**
   * 当前激活的菜单索引
   */
  activeIndex = signal(-1)

  /**
   * 动态创建组件销毁的函数
   */
  clearFn: ClearFn | null = null

  isDynamicEmpty = computed(() => this._currentComponentRef() === undefined)

  /**
   * 数据面板右上角角标
   */
  aiRef = viewChild.required<TemplateRef<HTMLDivElement>>('ai')

  private _currentComponentRef = signal<ComponentRef<any> | undefined>(undefined)

  private _overlay = inject(Overlay)
  private sidebarMenuRef = viewChild.required<ElementRef<HTMLElement>>('sidebarMenu')
  private resetSubscription: Subscription | undefined
  private _dataPanelOverlayRef!: OverlayRef
  private _dataPanelPortal: ComponentPortal<DataPanelComponent> | undefined

  private _dynamicContainer = viewChild.required('dynamicContainer', { read: ViewContainerRef })
  private injector = inject(Injector)

  ngOnInit() {
    // 初始化dataPanelOverlayRef
    this._dataPanelOverlayRef = this._overlay.create({
      disposeOnNavigation: true,
      // backdropClass: ['bg-black', 'bg-opacity-85', 'fixed', 'inset-0'],
      // hasBackdrop: true,
      panelClass: ['overflow-hidden', 'w-full', 'h-full']
    })

    this.menus = [
      {
        label: '模版',
        icon: 'editorup:template',
        createFn: this.handleTemplate.bind(this)
      },
      {
        label: '素材',
        icon: 'editorup:three-hexagons'
      },
      {
        label: '页面',
        icon: 'editorup:card-two',
        createFn: this.handlePage.bind(this)
      },
      {
        label: '图层',
        icon: 'editorup:layers',
        createFn: this.handleLayer.bind(this)
      },
      {
        label: '数据',
        icon: 'editorup:table-file',
        createFn: this.handleDataPanel.bind(this),
        outlet: this.aiRef()
      },
      {
        label: '上传',
        icon: 'editorup:upload-one',
        createFn: this.handleUpload.bind(this)
      }
    ]
  }

  /**
   * 处理菜单点击时间
   * @param index
   */
  handleMenuClick(index: number) {
    const active = this.activeIndex()
    if (this.clearFn) {
      this.clearFn()
      this.clearFn = null
    }
    if (active === index) {
      this.resetActiveIndex()
    } else {
      const fn = this.menus[index].createFn
      if (fn) {
        this.clearFn = fn()
      }
      this.activeIndex.set(index)
    }
    this.aceActiveChange.emit(this.menus[this.activeIndex()])
  }

  /**
   * 显示数据面板
   * 通过lazy load加载组件 减少sidebar大小
   * 需要计算开始样式和结束样式，这样会在弹出时有一个过度动画
   * 过度动画的期望效果是从点击的位置逐渐放大到指定位置并展开
   */
  handleDataPanel() {
    // 清除上一个backdrop 消失时的transitionend事件，避免重复触发导致sidebar层级消失
    this.resetSubscription?.unsubscribe()

    // let ref: ComponentRef<DataPanelComponent>
    if (!this._dataPanelPortal) {
      // ref = this.createDataPanel(this._dataPanelPortal)
      this._dataPanelPortal = new ComponentPortal(DataPanelComponent, null, this.injector)
    }
    // 通过lazy load加载组件 减少sidebar大小
    // import('../data-panel').then(({ DataPanelComponent: component }) => {
    // })
    const ref = this.createDataPanel(this._dataPanelPortal)
    return () => {
      ref?.instance.close()
    }
  }

  handleUpload(params?: { [key: string]: any }) {
    const ref = this._dynamicContainer().createComponent(UploadComponent)
    this._currentComponentRef.set(ref)
    // ref.setInput('defaultTab', params?.['tab'])
    return () => {
      this._currentComponentRef.set(undefined)
      this._dynamicContainer().clear()
    }
  }

  handlePage() {
    const ref = this._dynamicContainer().createComponent(PageListComponent)
    this._currentComponentRef.set(ref)
    return () => {
      this._currentComponentRef.set(undefined)
      this._dynamicContainer().clear()
    }
  }

  handleLayer() {
    const ref = this._dynamicContainer().createComponent(LayerListComponent)
    this._currentComponentRef.set(ref)
    return () => {
      this._currentComponentRef.set(undefined)
      this._dynamicContainer().clear()
    }
  }

  /**
   * 重置激活的菜单索引
   */
  resetActiveIndex() {
    this.activeIndex.set(-1)
  }

  /**
   * 创建数据面板
   * @param portal
   * @private
   */
  private createDataPanel(portal: ComponentPortal<DataPanelComponent>) {
    // 获取当前激活的元素的位置信息
    const { left, top, width, height } = (document.activeElement as HTMLElement).getBoundingClientRect()

    // 获取overlay的panel
    const panelEl = this._dataPanelOverlayRef.hostElement
    // 当前组件的宿主元素
    const hostEl = this.sidebarMenuRef().nativeElement

    const { width: hostWidth, left: hostLeft, top: hostTop } = hostEl.getBoundingClientRect()
    const panelLeft = hostWidth + hostLeft + 8
    // 最终样式
    const endStyle = {
      width: `calc(100vw - ${panelLeft}px`,
      height: `calc(100vh - ${hostTop + 4}px`,
      top: `${hostTop}px`,
      left: `${panelLeft}px`,
      opacity: '1',
      filter: 'none'
    }
    // 初始样式
    const startStyle = {
      position: 'fixed',
      width: '50vw',
      height: '50vh',
      left: `${left + width / 2}px`,
      top: `${top + height / 2}px`,
      opacity: 0,
      filter: 'blur(3px)',
      transition: 'all 100ms ease-out'
    }

    const ref = this._dataPanelOverlayRef.attach(portal)

    this.showMask.set(true)

    // 初始化 后续会通过动画过渡到endStyle
    Object.assign(panelEl.style, startStyle)

    // 监听数据面板关闭事件 重置sidebar层级
    ref.instance.aceDataPanelClose.subscribe(() => {
      // 取消选中状态
      this.resetActiveIndex()
      this.clearFn = null
      fromEvent(panelEl, 'transitionend')
        .pipe(take(1))
        .subscribe(() => {
          // 隐藏组件
          this._dataPanelOverlayRef.detach()
          this.showMask.set(false)
        })

      // 触发消失动画
      Object.assign(panelEl.style, startStyle)
    })

    // 手动触发变更检测 OnPush会导致组件不会立即渲染
    ref.changeDetectorRef.detectChanges()

    requestAnimationFrame(() => {
      // 触发显示动画
      Object.assign(panelEl.style, endStyle)
    })

    return ref
  }

  private handleTemplate() {
    const ref = this._dynamicContainer().createComponent(TemplateComponent)
    this._currentComponentRef.set(ref)
    // ref.setInput('defaultTab', params?.['tab'])
    return () => {
      this._currentComponentRef.set(undefined)
      this._dynamicContainer().clear()
    }
  }
}
