<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    @if (user()?.avatar) {
      <img [src]="user()?.avatar" class="h-7 w-7 rounded-full" />
    } @else {
      <mat-icon svgIcon="heroicons_outline:user-circle"></mat-icon>
    }
  </span>
</button>

<mat-menu xPosition="before" #userActions="matMenu">
  <button mat-menu-item (click)="updateLanguage()">
    <mat-icon svgIcon="heroicons_outline:language"></mat-icon>
    <span>{{ 'Switch language' | transloco }}</span>
  </button>
  <button mat-menu-item (click)="updateMode()">
    @if (mode() === 'light') {
      <ng-container>
        <mat-icon svgIcon="heroicons_outline:moon"></mat-icon>
        <span>{{ 'Dark mode' | transloco }}</span>
      </ng-container>
    } @else {
      <ng-container>
        <mat-icon svgIcon="heroicons_outline:sun"></mat-icon>
        <span>{{ 'Light mode' | transloco }}</span>
      </ng-container>
    }
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item (click)="signOut()">
    <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
    {{ 'Sign Out' | transloco }}
  </button>
</mat-menu>
