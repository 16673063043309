import { CdkMenuTrigger } from '@angular/cdk/menu'
import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, HostBinding, HostListener, inject, Injector, input, OnDestroy } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatDivider } from '@angular/material/divider'
import { MatIcon } from '@angular/material/icon'

import { ChartDataVisualComponent } from '#modules/workspace/components/chart-data-visual'
import { GroupElementTreeNode, PageElementTreeNode } from '#modules/workspace/models/element-node'
import { ElementService } from '#modules/workspace/services/element.service'
import { StageUiStore } from '#modules/workspace/store/stage-ui.store'
import { ElementTypeEnum } from '#modules/workspace/types/constants'
import { IChartSetting, IGroupElement } from '#modules/workspace/types/element'
import { WorkspaceService } from '#modules/workspace/workspace.service'
import { ContextMenuComponent, IContextMenuItem } from '#shared/components/context-menu/context-menu.component'

export interface ToolBoxAction {
  label: string
  icon: string
  callback?: () => void
  disabled?: boolean
  children?: IContextMenuItem[]
}

@Component({
  selector: 'ace-element-toolbox',
  standalone: true,
  imports: [CommonModule, MatIconButton, MatIcon, MatDivider, CdkMenuTrigger, ContextMenuComponent],
  templateUrl: './element-toolbox.component.html',
  styles: `
    :host {
      position: absolute;
      z-index: 1000;
      display: flex;
      justify-content: center;
      top: -45px;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementToolboxComponent implements OnDestroy {
  uiStore = inject(StageUiStore)
  elementService = inject(ElementService)
  workspaceService = inject(WorkspaceService)

  data = input.required<PageElementTreeNode>()
  boundingBox = input.required<{ left: number; top: number; right: number; width: number }>()
  contextMenu = input<IContextMenuItem[]>([])
  locked = computed(() => this.data().locked || this.uiStore.onStagePage()?.locked)
  actions = computed<ToolBoxAction[][]>(() => {
    if (this.locked()) {
      return [
        [
          {
            label: '解锁',
            disabled: this.uiStore.onStagePage()?.locked,
            icon: 'custom:page-lock',
            callback: () => {
              this.elementService.unlockElements()
            }
          }
        ]
      ]
    } else {
      switch (this.data().category) {
        case 'text':
          return [
            [
              {
                label: 'AI文本',
                icon: 'custom:textAI',
                callback: this.aiActionCallback.bind(this)
              },
              {
                label: '创建副本',
                icon: 'custom:duplicate',
                callback: this.duplicate.bind(this)
              },
              {
                label: '删除',
                icon: 'custom:trash',
                callback: this.deleteElement.bind(this)
              }
            ]
          ]
        case 'image':
          return [
            [
              {
                label: 'AI生图',
                icon: 'custom:imgAI',
                callback: this.aiImage.bind(this)
              },
              {
                label: '裁剪图片',
                icon: 'custom:crop-image',
                callback: this.cropImage.bind(this)
              },
              {
                label: '创建副本',
                icon: 'custom:duplicate',
                callback: this.duplicate.bind(this)
              },
              {
                label: '删除',
                icon: 'custom:trash',
                callback: this.deleteElement.bind(this)
              }
            ]
          ]
        case 'chart':
          return [
            [
              {
                label: 'AI图表',
                icon: 'custom:diagramAI',
                children: [
                  {
                    label: '一键换肤',
                    icon: '',
                    callback: () => {
                      const newSetting = { ...this.data().setting } as IChartSetting
                      this.elementService.updateElementSetting<ElementTypeEnum.Chart>(this.data().id, newSetting)
                    }
                  },
                  {
                    label: '数生文',
                    icon: '',
                    callback: () => {
                      this.elementService.createTextElement(
                        'sggdfgsdggdfhsdfhsdfasdgasdgasdfasgash',
                        { x: this.data().position.x, y: this.data().position.y },
                        { width: 200, height: 100 }
                      )
                    }
                  }
                ]
              }
            ],
            [
              {
                label: '主题',
                icon: 'custom:colors',
                callback: this.chartTheme
              },
              {
                label: '数据',
                icon: 'custom:sheet',
                callback: this.chartData.bind(this)
              },
              {
                label: '配置',
                icon: 'custom:config',
                callback: this.chartConfig
              }
            ],
            [
              {
                label: '创建副本',
                icon: 'custom:duplicate',
                callback: this.duplicate.bind(this)
              },
              {
                label: '删除',
                icon: 'custom:trash',
                callback: this.deleteElement.bind(this)
              }
            ]
          ]
        case 'shape':
          return [
            [
              {
                label: '创建副本',
                icon: 'custom:duplicate',
                callback: this.duplicate.bind(this)
              },
              {
                label: '删除',
                icon: 'custom:trash',
                callback: this.deleteElement.bind(this)
              }
            ]
          ]
        case 'line':
          return [
            [
              {
                label: '创建副本',
                icon: 'custom:duplicate',
                callback: this.duplicate.bind(this)
              },
              {
                label: '删除',
                icon: 'custom:trash',
                callback: this.deleteElement.bind(this)
              }
            ]
          ]
        case 'group':
          if (this.data().id.startsWith('virtual')) {
            return [
              [
                {
                  label: '建组',
                  icon: 'custom:element-group',
                  callback: this.groupElements.bind(this)
                },
                {
                  label: '创建副本',
                  icon: 'custom:duplicate',
                  callback: this.duplicate.bind(this)
                },
                {
                  label: '删除',
                  icon: 'custom:page-delete',
                  callback: this.deleteElement.bind(this)
                }
              ]
            ]
          } else {
            return [
              [
                {
                  label: '解组',
                  icon: 'custom:element-ungroup',
                  callback: this.ungroupElement.bind(this)
                },
                {
                  label: '创建副本',
                  icon: 'custom:duplicate',
                  callback: this.duplicate.bind(this)
                },
                {
                  label: '删除',
                  icon: 'custom:page-delete',
                  callback: this.deleteElement.bind(this)
                }
              ]
            ]
          }
        default:
          return []
      }
    }
  })

  isCropImage = computed(() => !!this.workspaceService.cropImageElementId())
  private _overlay = inject(Overlay)
  private _overlayRef: OverlayRef | undefined
  private _chartVisualPortal: ComponentPortal<ChartDataVisualComponent> | undefined
  private _injector = inject(Injector)

  @HostBinding('style.transform') get transform() {
    return `translate(${this.boundingBox().left * this.uiStore.zoom()}px, ${this.boundingBox().top * this.uiStore.zoom() - 20}px)`
  }

  @HostBinding('style.width.px') get width() {
    return this.boundingBox().width * this.uiStore.zoom()
  }

  // get scale() {
  //   return 1 / this.uiStore.zoom()
  // }

  // get height() {
  //   return this.boundingBox().bottom - this.boundingBox().top
  // }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    event.stopPropagation()
  }

  @HostListener('mouseup', ['$event']) onMouseup($event: MouseEvent) {
    $event.stopPropagation()
  }

  ngOnDestroy() {
    this._overlayRef?.dispose()
    this.workspaceService.cropImageElementId.set(undefined)
  }

  aiActionCallback() {}

  duplicate = () => {
    // this.elementService.duplicateElements(this.uiStore.selectedRootElements())
    this.elementService.duplicateElements()
  }

  deleteElement() {
    this.elementService.deleteElements(...this.uiStore.selectedRootElements().map(el => el.id))
  }

  groupElements() {
    this.elementService.createGroupElement(this.uiStore.selectedRootElements())
  }

  ungroupElement() {
    if (this.data().category === ElementTypeEnum.Group) {
      this.elementService.ungroupElement(this.data() as GroupElementTreeNode)
    }
  }

  aiImage() {}

  cropImage() {
    if (this.data().id === this.workspaceService.cropImageElementId()) {
      this.workspaceService.cropImageElementId.set(undefined)
    } else {
      this.workspaceService.cropImageElementId.set(this.data().id)
    }
  }

  chartTheme() {}

  chartData = () => {
    if (!this._overlayRef || !this._chartVisualPortal) {
      this._overlayRef = this._overlay.create({
        positionStrategy: this._overlay.position().global().bottom('0').top('100px').left('0').right('0'),
        hasBackdrop: true,
        backdropClass: ['bg-black', 'bg-opacity-85'],
        // 减去margin-top的100px 否则data-grid无法滚动到底部
        panelClass: ['w-screen', 'h-[calc(100%_-_100px)]']
      })
      this._chartVisualPortal = new ComponentPortal(ChartDataVisualComponent, null, this._injector)
      this._overlayRef.backdropClick().subscribe(() => {
        this._overlayRef?.detach()
      })
    }
    const componentRef = this._overlayRef.attach(this._chartVisualPortal)
    componentRef.instance.aceClose.subscribe(() => {
      this._overlayRef?.detach()
    })
  }

  chartConfig() {}
}
