import { coerceBooleanProperty } from '@angular/cdk/coercion'
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { MatProgressBarModule } from '@angular/material/progress-bar'

import { Subject, takeUntil } from 'rxjs'

import { LoadingService } from '../../services/loading'

@Component({
  selector: 'ace-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'aceLoadingBar',
  standalone: true,
  imports: [MatProgressBarModule]
})
export class LoadingBarComponent implements OnChanges, OnInit, OnDestroy {
  @Input() autoMode = true
  mode!: 'determinate' | 'indeterminate'
  progress = 0
  show = false
  private _unsubscribeAll: Subject<unknown> = new Subject<unknown>()

  /**
   * Constructor
   */
  constructor(private _loadingService: LoadingService) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // Auto mode
    if ('autoMode' in changes) {
      // Set the auto mode in the service
      this._loadingService.setAutoMode(coerceBooleanProperty(changes['autoMode'].currentValue))
    }
  }

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the service
    this._loadingService.mode$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.mode = value
    })

    this._loadingService.progress$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.progress = value as number
    })

    this._loadingService.show$.pipe(takeUntil(this._unsubscribeAll)).subscribe(value => {
      this.show = value
    })
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null)
    this._unsubscribeAll.complete()
  }
}
