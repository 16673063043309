<div class="relative flex h-full flex-col overflow-hidden rounded-xl">
  <div #listContainer class="relative flex flex-1 flex-col gap-2 p-2" aceScrollbar cdkScrollable>
    <div cdkDropList (cdkDropListDropped)="drop($event)" class="page-list flex flex-col gap-2">
      @for (page of pages(); let i = $index; track page.id) {
        <div
          cdkDrag
          class="page-item w-50 bg-tertiary-50 dark:bg-tertiary-900 relative flex min-h-[112px] cursor-pointer items-center justify-center rounded-lg text-sm leading-3 hover:shadow"
          [class.bordered]="isPageSelected(page.id) || uiStore.onStagePage()?.id === page.id"
          [class.current-page]="uiStore.onStagePage()?.id === page.id"
          [class.selected]="isPageSelected(page.id)"
          [class.not-dragging]="!dragging"
          [class.example-custom-placeholder]="dragging && isPageSelected(page.id)"
          (click)="clickPage($event, i, page)"
          (cdkDragStarted)="dragStarted($event, i, page)"
          (cdkDragMoved)="dragMoved($event)"
          (cdkDragEnded)="dragEnded($event)"
          (cdkDragDropped)="dropped($event)"
        >
          <div
            class="relative h-full w-full"
            (contextmenu)="rightClickPage($event, i, page)"
            [cdkContextMenuTriggerFor]="context_menu"
            (cdkContextMenuClosed)="contextMenuClosed()"
          >
            <div *cdkDragPlaceholder></div>
            <div class="relative flex h-full min-h-[112px] flex-1 items-center justify-between overflow-hidden rounded-lg">
              @if (startLoadPages) {
                <ace-page-view
                  [page]="page"
                  [pageScale]="200 / page.size.width"
                  [style.height.px]="(200 / page.size.width) * page.size.height"
                  class="pointer-events-none w-full"
                  (rendered)="onPageRendered(page.id)"
                ></ace-page-view>
              }
              @if (!renderedPages.includes(page.id)) {
                <ngx-skeleton-loader [theme]="{ width: '200px', height: '112px', borderRadius: '8px', marginBottom: '0' }" />
              }

              @if (page.visible === false) {
                <div class="absolute left-0 top-0 flex h-full w-full items-center justify-center overflow-hidden rounded-lg backdrop-blur-sm">
                  <mat-icon class="icon-size-8 text-tertiary-700 dark:text-tertiary-50" [svgIcon]="'custom:page-hidden'" (click)="showPage(i)"></mat-icon>
                </div>
              }

              <div
                class="page-name text-tertiary-900 absolute bottom-0 left-0 flex w-full items-center whitespace-pre p-2 text-xs leading-3"
                [style]="getNameColor(i)"
              >
                <span class="mr-1">{{ i + 1 }}</span>
                @if (page.locked) {
                  <span class="mr-1 flex h-3 w-3 items-center justify-center rounded-sm bg-black drop-shadow-none">
                    <mat-icon class="h-2 w-2 text-xs text-neutral-50" [svgIcon]="'custom:page-lock'"></mat-icon>
                  </span>
                }
                <span class="flex-1 overflow-hidden text-ellipsis">
                  {{ '- ' + page.name || '' }}
                </span>
              </div>
            </div>
          </div>

          <div
            class="context-menu-button absolute right-2 top-2 h-4 w-7 items-center justify-center overflow-hidden rounded-lg transition-colors"
            [class]="isPageContextMenuOpen(i) ? 'bg-primary flex' : 'hover:bg-primary hidden bg-black bg-opacity-70'"
          >
            <mat-icon
              class="icon-size-5 text-neutral-50"
              [svgIcon]="'custom:more-horizontal'"
              (click)="rightClickPage($event, i, page)"
              (contextmenu)="$event.preventDefault()"
              [cdkMenuTriggerFor]="context_menu"
              (cdkMenuClosed)="contextMenuClosed()"
            ></mat-icon>
          </div>

          <!-- drag mask -->
          @if (dragging && isPageSelected(page.id)) {
            <div class="bg-tertiary-50 dark:bg-tertiary-900 absolute h-full w-full rounded-lg"> </div>
          }
        </div>
      }
    </div>

    <div
      class="page-item w-50 bg-tertiary-50 dark:bg-tertiary-900 group relative flex min-h-[112px] cursor-pointer items-center justify-center rounded-lg text-sm leading-3 hover:shadow"
      (click)="addPage()"
    >
      <div class="absolute hidden h-full min-h-[112px] w-full flex-1 rounded-lg bg-neutral-50 group-hover:block group-hover:bg-opacity-15"> </div>
      <mat-icon class="icon-size-8 text-tertiary dark:text-tertiary-300 z-10" [svgIcon]="'custom:plus'"></mat-icon>
    </div>
  </div>

  <div
    class="text-tertiary-600 dark:text-tertiary-300 flex h-8 w-full cursor-pointer items-center justify-center text-xs leading-3"
    (click)="openPageJumping()"
  >
    @if (!pageJumping) {
      <span class="">页数 {{ currentPageNum() }}</span>
    } @else {
      跳转至
      <input
        matInput
        #paginationInput
        class="border-primary-200 dark:border-tertiary-600 bg-tertiary-50 dark:bg-tertiary-950 text-tertiary-900 mx-1.5 h-6 w-11 rounded-lg border p-2 dark:text-neutral-50"
        type="number"
        (blur)="jumpPage($event)"
        (keyup)="enterPage($event)"
      />
    }
    /{{ pages().length }}
  </div>
</div>

<ng-template #context_menu>
  <div
    class="text-tertiary-600 dark:text-tertiary-300 dark:bg-tertiary-950 flex w-64 flex-col items-start justify-start overflow-hidden rounded-lg bg-neutral-50 text-sm shadow"
    cdkMenu
  >
    <div class="border-tertiary-100 dark:border-tertiary-800 flex h-14 w-full items-center justify-start gap-3 border-b px-3 text-lg leading-tight">
      <div
        class="relative overflow-hidden border-b border-dashed py-1"
        [class]="
          pageNameEditing ? 'border-tertiary-400 dark:border-tertiary-600' : 'hover:border-tertiary-400 dark:hover:border-tertiary-600 border-transparent'
        "
      >
        <input
          matInput
          #nameInput
          class="absolute w-full overflow-ellipsis"
          [class]="
            pageNameEditing
              ? 'text-tertiary-900 dark:text-tertiary-50'
              : 'hover:text-tertiary-900 text-tertiary-600 dark:text-tertiary-300 border-transparent dark:hover:text-neutral-50'
          "
          [value]="pageName"
          (focus)="focusName()"
          [placeholder]="nameInputPlaceholder"
          (input)="inputName($event)"
          (blur)="updateName($event)"
          (keydown)="keydownName($event)"
        />
        <span class="pointer-events-none relative whitespace-pre opacity-0">
          {{ pageName || nameInputPlaceholder }}
        </span>
      </div>
      @if (!pageNameEditing) {
        <mat-icon
          class="icon-size-4 text-tertiary-600 dark:text-tertiary-300 hover:text-tertiary-900 cursor-pointer dark:hover:text-neutral-50"
          [svgIcon]="'custom:name-edit'"
          (click)="editName()"
        ></mat-icon>
      }
    </div>

    <ul class="w-full overflow-hidden p-1">
      <li class="context-menu-item" cdkMenuItem>
        <button class="menu-item-button" (click)="insertPage()">
          <div class="menu-item-left">
            <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-add'"></mat-icon>
            <span class="menu-item-name">新增</span>
          </div>
          <div class="menu-item-right">
            <!-- <span class="menu-item-shortcut">Ctrl+Enter</span> -->
          </div>
        </button>
      </li>
      <li class="context-menu-item" cdkMenuItem>
        <button class="menu-item-button" (click)="duplicateSelections()">
          <div class="menu-item-left">
            <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-duplicate'"></mat-icon>
            <span class="menu-item-name">创建副本</span>
          </div>
          <div class="menu-item-right">
            <span class="menu-item-shortcut">Ctrl+D</span>
          </div>
        </button>
      </li>
      <li class="context-menu-item" cdkMenuItem [cdkMenuItemDisabled]="!canDelete()" [class.disabled]="!canDelete()">
        <button class="menu-item-button" (click)="deleteSelections()">
          <div class="menu-item-left">
            <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-delete'"></mat-icon>
            <span class="menu-item-name">删除</span>
          </div>
          <div class="menu-item-right">
            <!-- <span class="menu-item-shortcut">DELETE</span> -->
          </div>
        </button>
      </li>
      <li class="context-menu-item" cdkMenuItem>
        @if (canHide()) {
          <button class="menu-item-button" (click)="hideSelections()">
            <div class="menu-item-left">
              <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-hidden'"></mat-icon>
              <span class="menu-item-name">隐藏</span>
            </div>
            <div class="menu-item-right">
              <!-- <span class="menu-item-shortcut">Ctrl+H</span> -->
            </div>
          </button>
        } @else {
          <button class="menu-item-button" (click)="showSelections()">
            <div class="menu-item-left">
              <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-show'"></mat-icon>
              <span class="menu-item-name">显示</span>
            </div>
            <div class="menu-item-right">
              <!-- <span class="menu-item-shortcut">Ctrl+H</span> -->
            </div>
          </button>
        }
      </li>
      <li class="context-menu-item" cdkMenuItem>
        @if (canLock()) {
          <button class="menu-item-button" (click)="lockSelections()">
            <div class="menu-item-left">
              <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-lock'"></mat-icon>
              <span class="menu-item-name">锁定</span>
            </div>
            <div class="menu-item-right">
              <!-- <span class="menu-item-shortcut">Alt+Shift+L</span> -->
            </div>
          </button>
        } @else {
          <button class="menu-item-button" (click)="unlockSelections()">
            <div class="menu-item-left">
              <mat-icon class="menu-item-icon" [svgIcon]="'custom:page-unlock'"></mat-icon>
              <span class="menu-item-name">解锁</span>
            </div>
            <div class="menu-item-right">
              <!-- <span class="menu-item-shortcut">Alt+Shift+L</span> -->
            </div>
          </button>
        }
      </li>
    </ul>
  </div>
</ng-template>
