<div class="origin-top-left" [style.width.px]="size().width" [style.height.px]="size().height" [style.scale]="scale()">
  <ace-element-group
    [id]="data().id"
    [size]="size()"
    [children]="[]"
    [preview]="preview()"
    [isVirtual]="true"
    [locked]="locked()"
    (dragStart)="startDrag($event)"
  ></ace-element-group>
</div>
