<ace-header></ace-header>

<div class="flex min-h-0 flex-grow">
  @if (!upgrading()) {
    <div class="relative z-30 m-1 flex">
      <ace-sidebar (aceActiveChange)="handleSidebarChange($event)" #sidebar></ace-sidebar>
    </div>
    <div class="relative z-20 flex-grow">
      <ace-canvas></ace-canvas>
    </div>
    <div class="relative z-10 h-full select-none p-1" ssOrigin>
      <div class="dark:bg-tertiary-950 h-full w-60 overflow-hidden rounded-xl bg-neutral-50">
        <ace-setting-panel></ace-setting-panel>
      </div>
    </div>
  } @else {
    <div>upgrading...</div>
  }
</div>
