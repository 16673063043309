import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, effect, HostListener, input, model, output } from '@angular/core'
import { MatRipple } from '@angular/material/core'
import { MatIcon } from '@angular/material/icon'

@Component({
  selector: 'ace-rule-popup-row',
  standalone: true,
  imports: [CommonModule, MatIcon, MatRipple],
  template: `
    <div class="group cursor-pointer overflow-hidden" mat-ripple [class]="{ active: aceRulePopupRowActive() }">
      <div class="group-[.active]:bg-primary-600 flex h-9 items-center rounded-lg px-3 group-[.active]:bg-opacity-10">
        <mat-icon [svgIcon]="aceRulePopupRowIcon()" class="group-[.active]:text-tertiary-500  mr-3 h-4 min-h-4 w-4 min-w-4"></mat-icon>
        <span class="text-tertiary-600 group-[.active]:text-tertiary-900 flex-grow select-none">{{ aceRulePopupRowText() }}</span>
        @if (aceRulePopupRowArrow()) {
          <mat-icon svgIcon="editorup:Right (右)" class="text-tertiary-600 group-[.active]:text-tertiary-900 h-4 min-h-4 w-4 min-w-4"></mat-icon>
        }
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RulePopupRowComponent {
  /**
   * 是否忽略鼠标事件
   */
  aceRulePopupRowIgnore = model(false)

  /**
   * 是否激活
   */
  aceRulePopupRowActive = model(false)

  /**
   * 是否显示箭头
   */
  aceRulePopupRowArrow = input(false)

  /**
   * 显示文本
   */
  aceRulePopupRowText = input('')

  /**
   * 图标
   */
  aceRulePopupRowIcon = input('')

  /**
   * 激活状态变更事件
   */
  aceRulePopupRowChange = output<boolean>()

  /**
   * 点击事件
   */
  aceRulePopupRowClick = output<void>()

  /**
   * 鼠标进入事件 模拟hover
   */
  @HostListener('mouseenter', ['$event'])
  onMouseEnter() {
    if (this.aceRulePopupRowIgnore()) return
    this.setState(true)
  }

  /**
   * 鼠标离开事件 模拟hover
   */
  @HostListener('mouseleave', ['$event'])
  onMouseLeave() {
    if (this.aceRulePopupRowIgnore()) return
    this.setState(false)
  }

  /**
   * 点击事件
   */
  @HostListener('click', ['$event'])
  onClick() {
    this.aceRulePopupRowClick.emit()
  }

  /**
   * 设置激活状态
   * @param active
   */
  setState(active: boolean) {
    // 设置激活状态并触发事件
    this.aceRulePopupRowActive.set(active)
    this.aceRulePopupRowChange.emit(active)

    // 如果当前未激活且忽略鼠标事件，重置ignore
    if (!active && this.aceRulePopupRowIgnore()) {
      this.aceRulePopupRowIgnore.set(false)
    }
  }
}
