<div class="dark:bg-tertiary-950 mx-1 flex h-11 w-auto shrink-0 select-none items-center justify-between rounded-bl-lg rounded-br-lg bg-neutral-50 p-2">
  <div class="flex flex-1 items-center gap-6">
    <button
      mat-button
      class="text-tertiary dark:text-tertiary-300 hover:text-tertiary-700 group flex cursor-pointer items-center gap-2 p-3 dark:hover:text-neutral-50"
      (click)="back()"
    >
      <mat-icon
        class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
        [svgIcon]="'custom:back'"
      ></mat-icon>
      返回
    </button>

    <button
      mat-button
      class="text-tertiary dark:text-tertiary-300 hover:text-tertiary-700 group flex cursor-pointer items-center gap-2 p-3 dark:hover:text-neutral-50"
    >
      <mat-icon
        class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
        [svgIcon]="'custom:saved'"
      ></mat-icon>
      保存
    </button>

    <div class="flex cursor-pointer items-center gap-1">
      <button mat-button class="group flex min-w-9 items-center px-2 py-1.5" [disabled]="!canUndo()" (click)="undo()">
        <mat-icon
          class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
          [svgIcon]="'custom:undo'"
        ></mat-icon>
      </button>
      <button mat-button class="group flex min-w-9 items-center px-2 py-1.5" [disabled]="!canRedo()" (click)="redo()">
        <mat-icon
          class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
          [svgIcon]="'custom:redo'"
        ></mat-icon>
      </button>
    </div>

    <div
      class="text-tertiary dark:text-tertiary-300 hover:text-tertiary-700 max-w-50 group inline-flex min-w-10 cursor-pointer items-center gap-4 p-2 dark:hover:text-neutral-50"
      (dblclick)="editTitle()"
    >
      @if (!editingTitle) {
        <span class="inline-block overflow-hidden text-ellipsis text-nowrap border border-transparent p-2">
          {{ title }}
        </span>
      } @else {
        <input
          #input
          class="bg-tertiary-50 focus:border-primary focus:dark:border-primary focus:text-tertiary-900 focus:dark:text-tertiary-50 h-8 min-w-10 flex-1 rounded-lg border px-2 text-base font-normal leading-5 dark:bg-neutral-950"
          matInput
          type="text"
          [value]="title"
          (keyup)="onKeyup($event)"
          (blur)="onBlur()"
        />
      }
    </div>
  </div>

  <ace-meta-bar class="flex flex-1 items-center justify-center"></ace-meta-bar>

  <div class="flex flex-1 items-center justify-end gap-4">
    <div class="flex gap-1">
      <div class="text-tertiary-700 flex cursor-pointer items-center gap-4 p-3 dark:text-neutral-50"> 智能转换 </div>

      <div class="text-tertiary dark:text-tertiary-300 flex items-center gap-2 p-2">
        <span>W</span>
        <span>{{ page()?.size?.width ?? 0 }}</span>
      </div>

      <div class="text-tertiary dark:text-tertiary-300 flex items-center gap-2 p-2">
        <span>H</span>
        <span>{{ page()?.size?.height ?? 0 }}</span>
      </div>
    </div>

    <div class="flex cursor-pointer items-center gap-4">
      <button mat-button class="group flex min-w-9 items-center p-0">
        <mat-icon
          class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
          [svgIcon]="'custom:player'"
        ></mat-icon>
      </button>
    </div>

    <div class="flex cursor-pointer items-center gap-4">
      <button mat-button class="group flex min-w-9 items-center p-0">
        <mat-icon
          class="icon-size-5 text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50"
          [svgIcon]="'custom:share'"
        ></mat-icon>
      </button>
    </div>

    <div class="flex cursor-pointer items-center gap-4">
      <button
        mat-button
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
        class="group flex h-8 min-h-8 w-8 min-w-8 items-center justify-center rounded-lg p-0"
        [class]="
          active === 'download'
            ? 'bg-primary-600 bg-opacity-100'
            : 'hover:bg-primary-600 bg-opacity-0 hover:bg-opacity-10 dark:hover:bg-neutral-50 dark:hover:bg-opacity-5'
        "
        (click)="openDownloadModal()"
      >
        <mat-icon
          class="icon-size-5"
          [class]="
            active === 'download'
              ? 'text-neutral-50 dark:text-neutral-50'
              : 'text-tertiary dark:text-tertiary-300 group-hover:text-tertiary-700 dark:group-hover:text-neutral-50'
          "
          [svgIcon]="'custom:project-download'"
        ></mat-icon>
      </button>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="downloadModalOpen"
        (overlayOutsideClick)="closeDownloadModal($event)"
        [cdkConnectedOverlayPositions]="[
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 16,
            offsetY: 12
          }
        ]"
      >
        <ace-download-modal (handleClose)="closeDownloadModal()"> </ace-download-modal>
      </ng-template>
    </div>
  </div>
</div>
