import { ChangeDetectionStrategy, Component, effect, inject } from '@angular/core'
import { MatIconButton } from '@angular/material/button'
import { MatDivider } from '@angular/material/divider'
import { MatIcon } from '@angular/material/icon'
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu'
import { Router } from '@angular/router'

import { TranslocoPipe } from '@ngneat/transloco'

import { AUTH_SERVICE_TOKEN, ITokenService } from '@libs/ng-shared/auth'

import { ApiService } from '#core/services/api.service'
import { AppStore } from '#core/store/app.store'

@Component({
  selector: 'ace-user',
  standalone: true,
  imports: [MatIconButton, MatMenuTrigger, MatMenu, MatMenuItem, TranslocoPipe, MatIcon, MatDivider],
  templateUrl: './user.component.html',
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserComponent {
  router = inject(Router)
  appStore = inject(AppStore)
  apiService = inject(ApiService)
  tokenService = inject<ITokenService>(AUTH_SERVICE_TOKEN)

  user = this.appStore.user
  mode = this.appStore.styles.mode
  lang = this.appStore.language

  constructor() {}

  signOut() {
    this.appStore.logout()
    this.appStore.cleanUser()
    this.apiService.logout().subscribe({
      complete: async () => {
        this.tokenService.clear()
        await this.router.navigateByUrl(this.tokenService.loginUrl as string)
      }
    })
  }

  updateLanguage() {
    const lang = this.lang() === 'en' ? 'zh' : 'en'
    this.appStore.switchLang(lang)
  }

  updateMode() {
    const mode = this.mode() === 'light' ? 'dark' : 'light'
    this.appStore.switchMode(mode)
  }
}
