<div class="w-52 rounded-lg bg-white p-1">
  @for (shape of shapeList; track $index) {
    <div class="hover:bg-primary-600 group relative flex h-10 select-none items-center rounded-lg px-3 hover:bg-opacity-10" (click)="addShape(shape.path)">
      <mat-icon [svgIcon]="shape.icon" class="icon-size-4 text-tertiary mr-3"></mat-icon>
      <span class="text-tertiary-900 text-base">{{ shape.name }}</span>
      <div class="text-tertiary-600 absolute right-3 top-1/2 flex h-5 w-5 -translate-y-1/2 items-center justify-center rounded bg-white">{{
        shape.keyMap
      }}</div>
    </div>
  }
</div>
