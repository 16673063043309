export * from './data-explore.component'
export interface DataExploreHot {
  id: string
  name: string
  description: string
}

export interface DataExploreTopic {
  id: string
  name: string
  image: string
}

export enum DataExploreMode {
  INIT = 'init',
  SEARCH = 'search',
  TOPIC = 'topic'
}

export interface FatherTag {
  id: string
  label: string
  selectedCount: number
  pinned: boolean
  children: ChildTag[]
}

export interface ChildTag {
  id: string
  selected: boolean
  label: string
}
