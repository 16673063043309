<div class="h-full w-full" #rootContainerRef>
  <div class="absolute origin-top-left" [style]="pageStyle()">
    <div class="absolute origin-center" (mousedown)="handleFullImageMousedown($event)" [style]="fullImageStyle()">
      <div class="absolute inset-0 origin-center" #fullImageContainerRef>
        <img [src]="imageSrc()" alt="image cropper" class="pointer-events-none h-full w-full cursor-move opacity-50" [style.transform]="imageFlipScale()" />
      </div>
    </div>
    <!--    cropper image-->
    <div class="pointer-events-none absolute z-10 origin-top-left" [style]="cropperImageStyle()" #cropImageContainerRef>
      <div class="relative z-10">
        @if (element(); as element) {
          <div class="absolute left-0 top-0 origin-top-left" [style.width.px]="element.size.width" [style.height.px]="element.size.height">
            <ace-image [size]="element.size" [scale]="element.scale" [selected]="false" [elementId]="element.id" [setting]="setting()">
              @if (isMoving()) {
                <div class="absolute inset-0 z-20">
                  <div class="absolute inset-0 flex h-full w-full items-center justify-evenly">
                    <div class="h-full w-[1px] bg-white bg-opacity-50"></div>
                    <div class="h-full w-[1px] bg-white bg-opacity-50"></div>
                  </div>
                  <div class="insert-0 absolute flex h-full w-full flex-col items-center justify-evenly">
                    <div class="h-[1px] w-full bg-white bg-opacity-50"></div>
                    <div class="h-[1px] w-full bg-white bg-opacity-50"></div>
                  </div>
                </div>
              }
            </ace-image>
          </div>
        }
      </div>
    </div>
  </div>
</div>
